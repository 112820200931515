import { VALID_USER_ROLES } from '../config/platforms.config';
import {
  ACCESS_RIGHTS_ADMIN,
  ACCESS_RIGHTS_SALE,
  ACCESS_RIGHTS_TEACHER,
} from '../config/roleAccess.config';

const getUserRolePermission = (role) => {
  if (role === VALID_USER_ROLES.ADMIN) {
    return ACCESS_RIGHTS_ADMIN;
  }
  if (role === VALID_USER_ROLES.TEACHER) {
    return ACCESS_RIGHTS_TEACHER;
  }
  if (role === VALID_USER_ROLES.SALES) {
    return ACCESS_RIGHTS_SALE;
  }
  return null;
};

export default getUserRolePermission;
