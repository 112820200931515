import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, CONTENT_ALIAS } from '../const.config';

// Videos module configurations
export const LISTING_COLUMNS = [
  {
    id: 'poster',
    label: 'Image Preview',
    width: '5%',
    align: 'center',
    dataKey: 'posterUrl',
    sortable: false,
    type: CELL_DATA_TYPES.IMAGE,
  },
  {
    id: 'transmissionOrgan',
    label: 'Transmission Organ',
    width: '15%',
    align: 'left',
    dataKey: 'transmissionOrgan',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'elementType',
    label: 'Element Type',
    width: '15%',
    align: 'left',
    dataKey: 'elementType',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'programName',
    label: `${CONTENT_ALIAS.TAO_SOUL_PROGRAM_SINGULAR}`,
    width: '20%',
    align: 'left',
    dataKey: 'programName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.UPLOAD_POSTER.value,
    label: ACTIONS.UPLOAD_POSTER.label,
  },
  {
    action: ACTIONS.TRANSLATE.value,
    label: ACTIONS.TRANSLATE.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.MANAGE_TEACHERS.value,
    label: ACTIONS.MANAGE_TEACHERS.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'name',
    label: 'Transmission Organ',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'poster',
    label: 'Poster URL',
    dataKey: 'posterUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'elementType',
    label: 'Element Type',
    dataKey: 'elementType',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'programName',
    label: `${CONTENT_ALIAS.TAO_SOUL_PROGRAM_SINGULAR}`,
    dataKey: 'categoryName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
