import React, { useState, useEffect, useContext } from 'react';
import { Card, Stack, Button } from '@mui/material';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/transmission-program-request.confing';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import { getListTransmissionProgramRequest } from '../../services/content.service';
import { ACTIONS } from '../../config/const.config';
import SnackbarInfo from '../common/snackbar-info.component';
import ViewTransmissionProgramRequest from './view-transmission-program-request.component';
import useFilteredActions from '../../hooks/filtered-action.hook';
import { AccessContext } from '../../context/access.context';

const ListTransmissionProgramRequest = () => {
  const { accessData } = useContext(AccessContext);
  const columns = LISTING_COLUMNS;
  const actions = useFilteredActions(
    accessData.role,
    'tao_soul_program_requests',
    LISTING_ACTIONS
  );

  const renderActions = [];
  actions.forEach((item) => {
    renderActions.push(item);
  });

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const getVideosList = () => {
    let uri = '';
    uri += `?page=${options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      uri += `&q=${options.q}`;
    }

    setOptions({ ...options, loading: true, searchValue: '' });

    getListTransmissionProgramRequest(uri)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  };

  useEffect(() => {
    getVideosList();
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div> </div>
        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={renderActions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
        />
      </Card>

      {(doAction.action === ACTIONS.VIEW.value ||
        doAction.action === ACTIONS.APPROVAL.value) && (
        <ViewTransmissionProgramRequest
          title={`Transmission Program Request Details - ${doAction.data.transmissionOrgan}`}
          dataId={doAction.data.id}
          status={doAction.data.status}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListTransmissionProgramRequest;
