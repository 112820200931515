// import { useContext } from 'react';
// import { AccessContext } from '../context/access.context';
import getUserRolePermission from '../utils/rolePermission.util';

const useAccess = () => {
  // const { accessData } = useContext(AccessContext);

  const hasAccess = (role, section) => {
    const accessRights = getUserRolePermission(role);
    return (
      accessRights &&
      accessRights[section] &&
      Object.keys(accessRights[section]).length
    );
  };

  const hasOperationAccess = (role, section, key) => {
    const accessRights = getUserRolePermission(role);
    return (
      accessRights &&
      accessRights[section] &&
      Object.keys(accessRights[section]).length &&
      accessRights[section][key]
    );
  };

  return { hasAccess, hasOperationAccess };
};

export default useAccess;
