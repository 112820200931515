/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { getCurrentTimestamp } from '../../utils/datetime.util';

const RecurringStatus = ({ recurringTimestamp, duration }) => {
  const currentTime = getCurrentTimestamp();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <Box
        sx={{
          marginX: 2,
          fontSize: 'small',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {recurringTimestamp <= currentTime &&
        recurringTimestamp + duration * 60 >= currentTime ? (
          <Box
            sx={{
              width: 'fit-content',
              textAlign: 'center',
              borderRadius: '25px',
              background: '#ffc7d1',
              color: '#ff3939',
              paddingX: 2,
            }}
          >
            Live
          </Box>
        ) : recurringTimestamp < currentTime ? (
          <Box
            sx={{
              width: 'fit-content',
              textAlign: 'center',
              borderRadius: '25px',
              background: '#d1d1d1',
              color: '#413f3f',
              paddingX: 2,
            }}
          >
            Past
          </Box>
        ) : (
          <Box
            sx={{
              width: 'fit-content',
              textAlign: 'center',
              borderRadius: '25px',
              background: '#b0f1b0',
              color: '#006300',
              paddingX: 2,
            }}
          >
            Upcoming
          </Box>
        )}
      </Box>
    </>
  );
};

RecurringStatus.propTypes = {
  recurringTimestamp: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
};

export default RecurringStatus;
