import React, { useEffect, useState, forwardRef, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  Divider,
  Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import JoditEditor from 'jodit-react';
import { maxLengthValidation } from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateVideoById, viewVideoById } from '../../services/content.service';
import { CONTENT_ALIAS, LANGUAGES } from '../../config/const.config';
import ImageUpload from './image-upload.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const TranslateVideos = ({
  title,
  dataId,
  onCancel,
  onSuccess,
  isLiveEvent,
  languageCode = '',
  languageLabel = '',
}) => {
  const defaultValues = {};

  for (let i = 0; i < LANGUAGES.length; i += 1) {
    defaultValues[`title_${LANGUAGES[i].value}`] = '';
    defaultValues[`description_${LANGUAGES[i].value}`] = '';
    defaultValues[`thumbnail_${LANGUAGES[i].value}`] = '';
    if (isLiveEvent) {
      defaultValues[`ctaText_${LANGUAGES[i].value}`] = '';
    }
  }
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [videoData, setVideoData] = useState([]);
  // const [renderImage, setRenderImage] = useState(false);
  const editor = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [content, setContent] = useState('');
  const options = [
    'bold',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'font',
    'fontsize',
    '|',
    'outdent',
    'indent',
    'align',
    '|',
    'hr',
    '|',
    'fullsize',
    'brush',
    '|',
    'table',
    'link',
    'image',
    '|',
    'undo',
    'redo',
    '|',
    'source',
  ];
  const config = useMemo(
    () => ({
      defaultMode: 1,
      readonly: false,
      tabIndex: -1,
      toolbar: true,
      placeholder: 'Start typing...',
      defaultActionOnPaste: 'insert_as_html',
      defaultLineHeight: 1,
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      minHeight: 350,
      useNativeTooltip: false,
    }),
    []
  );

  const showUpdatedImage = () => {
    // if (rerender) {
    //   setLoading(true);
    // }
    // setRenderImage(rerender);
  };
  const setImageUrlCallBack = (v) => {
    setVideoData((prevState) => ({
      ...prevState,
      [v.languageCode]: {
        ...prevState[v.languageCode],
        thumbnail: v.imageUrl,
      },
    }));
  };

  const showToastMsg = (msgType, message) => {
    if (msgType === 'error') {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
    }

    if (msgType === 'success') {
      setSnackbarInfo({
        show: true,
        type: 'success',
        message,
      });
    }
  };

  const updateExistingData = (payload) => {
    updateVideoById(dataId, payload, isLiveEvent, 'lang')
      .then(() => {
        onSuccess(
          `${CONTENT_ALIAS.VIDEO_SINGULAR} details updated successfully.`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {};

    if (isLiveEvent) {
      payload[`title_${languageCode}`] = data[`title_${languageCode}`];
      payload[`description_${languageCode}`] =
        data[`description_${languageCode}`];
      payload[`ctaText_${languageCode}`] = data[`ctaText_${languageCode}`];
      payload[`longDescription_${languageCode}`] = content;
    } else {
      for (let i = 0; i < LANGUAGES.length; i += 1) {
        payload[`title_${LANGUAGES[i].value}`] =
          data[`title_${LANGUAGES[i].value}`];
        payload[`description_${LANGUAGES[i].value}`] =
          data[`description_${LANGUAGES[i].value}`];
        if (isLiveEvent) {
          payload[`ctaText_${LANGUAGES[i].value}`] =
            data[`ctaText_${LANGUAGES[i].value}`];
        }
      }
    }

    if (dataId) {
      updateExistingData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewVideoById(dataId, 'lang')
      .then((res) => {
        // Fill form values
        setVideoData(res.data);
        for (let i = 0; i < LANGUAGES.length; i += 1) {
          if (res.data[`${LANGUAGES[i].value}`]) {
            setValue(
              `title_${LANGUAGES[i].value}`,
              res.data[`${LANGUAGES[i].value}`].title || ''
            );
            setValue(
              `description_${LANGUAGES[i].value}`,
              res.data[`${LANGUAGES[i].value}`].description || ''
            );
            setValue(
              `thumbnail_${LANGUAGES[i].value}`,
              res.data[`${LANGUAGES[i].value}`].thumbnail || ''
            );
            setValue(
              `ctaText_${LANGUAGES[i].value}`,
              res.data[`${LANGUAGES[i].value}`].ctaText || ''
            );
            setValue(
              `longDescription_${LANGUAGES[i].value}`,
              res.data[`${LANGUAGES[i].value}`].longDescription || ''
            );
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="upload-assets-form" onSubmit={handleSubmit(onFormSubmit)}>
            {(isLiveEvent
              ? [{ value: languageCode, label: languageLabel }]
              : LANGUAGES
            ).map((item, index) => (
              <Grid container spacing={2} key={`title${item.value}`}>
                <Grid item xs={12} sm={12} md={12}>
                  <Divider
                    textAlign="center"
                    variant="fullWidth"
                    sx={{
                      fontWeight: 'bold',
                      mt: index === 0 ? 0 : 2,
                    }}
                  >
                    {item.label} Translation
                  </Divider>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name={`title_${item.value}`}
                    control={control}
                    rules={{
                      ...{ ...maxLengthValidation(255) },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={`${CONTENT_ALIAS.VIDEO_SINGULAR} Title (${item.value})`}
                        type="text"
                        error={!!errors[`title_${item.value}`]}
                        helperText={
                          errors[`title_${item.value}`]?.message || null
                        }
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name={`description_${item.value}`}
                    control={control}
                    rules={{
                      ...maxLengthValidation(2500),
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={`Description (${item.value})`}
                        type="text"
                        error={!!errors[`description_${item.value}`]}
                        helperText={
                          errors[`description_${item.value}`]?.message || null
                        }
                        fullWidth
                        inputProps={{ maxLength: 2500 }}
                      />
                    )}
                  />
                </Grid>

                {isLiveEvent && (
                  <>
                    <Grid item xs={12} sm={6} md={6}>
                      <Controller
                        name={`ctaText_${item.value}`}
                        control={control}
                        rules={{
                          ...{ ...maxLengthValidation(255) },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={`CTA Button Text (${item.value})`}
                            type="text"
                            error={!!errors[`ctaText_${item.value}`]}
                            helperText={
                              errors[`ctaText_${item.value}`]?.message || null
                            }
                            fullWidth
                            inputProps={{ maxLength: 255 }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Controller
                        name={`longDescription_${item.value}`}
                        control={control}
                        render={({ field }) => (
                          <JoditEditor
                            {...field}
                            label="Content"
                            ref={editor}
                            config={config}
                            tabIndex={-1}
                            onBlur={(newContent) => setContent(newContent)}
                            onChange={(newContent) => setContent(newContent)}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}

                <Box
                  component="section"
                  sx={{
                    p: 3,
                    ml: 2,
                    border: '1px solid lightgrey',
                    borderRadius: '5px',
                    width: '100%',
                    marginTop: 3,
                    padding: '10px',
                  }}
                >
                  <ImageUpload
                    title="Thumbnail"
                    dataId={dataId}
                    showToastMsg={showToastMsg}
                    setSnackbarInfo={setSnackbarInfo}
                    videoData={
                      videoData[item.value] || {
                        title: '',
                        thumbnail: '',
                        languageCode: item.value,
                      }
                    }
                    loading={loading}
                    showUpdatedImage={showUpdatedImage}
                    setImageUrlCallBack={setImageUrlCallBack}
                    isTranslation
                  />
                </Box>
              </Grid>
            ))}
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="upload-assets-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

TranslateVideos.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isLiveEvent: PropTypes.bool,
  languageCode: PropTypes.string,
  languageLabel: PropTypes.string,
};

TranslateVideos.defaultProps = {
  isLiveEvent: false,
  languageCode: '',
  languageLabel: '',
};

export default TranslateVideos;
