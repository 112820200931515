import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

export const LISTING_LINK_SECTIONS_ITEM_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    width: '10%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'linkSectionName',
    label: 'Link Section',
    width: '10%',
    align: 'left',
    dataKey: 'listSectionName',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'left',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const VIEW_LINK_SECTIONS_ITEM_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'linkSectionName',
    label: 'Link Section',
    dataKey: 'listSectionName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'url',
    label: 'URL',
    dataKey: 'Url',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_LINK_SECTIONS_ITEM_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },

  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },

  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];
