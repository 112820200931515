import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  FormControl,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';

import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  updateRecordedMessageById,
  viewRecordedMessageById,
} from '../../services/content.service';
import { CONTENT_ALIAS } from '../../config/const.config';
import Datetimepicker from '../form-elements/datetimepicker.component';
import Status from '../form-elements/status.component';
import CommunityGroups from '../form-elements/community-groups.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
const AddEditResources = ({ title, dataId, onCancel, onSuccess, type }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      bannerUrl: null,
    },
  });
  let publishedDateTimeGMT = 0;
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const updateExistingData = (payload) => {
    updateRecordedMessageById(dataId, payload, type)
      .then(() => {
        onSuccess(
          `${CONTENT_ALIAS.RECORDED_MESSAGE} details updated successfully.`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    publishedDateTimeGMT =
      Date.parse(data.publishDate?.$d.toISOString()) / 1000;
    const payload = {
      title: data.title || '',
      status: data.status || '',
      posterUrl: data.posterUrl || '',
      url: data.url || null,
      type: data.type || 0,
      publishDate: publishedDateTimeGMT || 0,
      duration: data.duration || 0,
      feedID: data.feedId || [],
    };

    updateExistingData(payload);
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewRecordedMessageById(dataId)
      .then((res) => {
        // Fill form values
        setValue('title', res.data?.title || '');
        setValue('status', res.data?.status || '');
        setValue('posterUrl', res.data?.posterUrl || null);
        setValue('url', res.data?.Url || '');
        setValue('type', res.data?.type || '');
        setValue('duration', res.data?.duration || 0);
        setValue('feedId', res.data?.communities || null);
        if (res.data.publishDate) {
          setValue('publishDate', dayjs.unix(res.data?.publishDate) || null);
        }

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Title"
                      type="text"
                      error={!!errors.title}
                      helperText={errors?.title?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="type"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Recorded Message Type"
                      type="text"
                      error={!!errors.type}
                      helperText={errors?.type?.message || null}
                      fullWidth
                      disabled={!!dataId}
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="publishDate"
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <Datetimepicker
                      id="publishDate"
                      name="publishDate"
                      label="Publish Date"
                      value={value}
                      defaultValue={!dataId ? null : value}
                      onChange={onChange}
                      error={errors?.eventExpiryDate?.message || ''}
                      disablePast
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="feedId"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <CommunityGroups
                      id="feedId"
                      name="feedId"
                      label="Select Community Groups"
                      multiple
                      allOption
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%', my: 1 }}
                      error={errors?.feedId?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="status"
                      name="status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[3, 4]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="duration"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...validationRules.REQUIRED_NUMBER,
                    ...maxLengthValidation(3),
                  }}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <TextField
                        {...field}
                        id="duration"
                        name="duration"
                        label="Duration (in minutes)"
                        type="number"
                        error={!!errors.duration}
                        helperText={errors?.duration?.message || null}
                        fullWidth
                        inputProps={{ min: 1 }}
                        disabled={!!dataId}
                      />
                    </FormControl>
                  )}
                />
              </Grid>

              {type === 'VIDEO' && (
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name="posterUrl"
                    control={control}
                    rules={{
                      ...validationRules.OPTIONAL_URL,
                      ...{ ...maxLengthValidation(250) },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Poster Url"
                        type="text"
                        error={!!errors.posterUrl}
                        helperText={errors?.posterUrl?.message || null}
                        fullWidth
                        inputProps={{ maxLength: 250 }}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="url"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(250) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Url"
                      type="url"
                      error={!!errors.url}
                      helperText={errors?.url?.message || null}
                      fullWidth
                      disabled={!!dataId}
                      inputProps={{ maxLength: 250 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditResources.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default AddEditResources;
