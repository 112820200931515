import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import SnackbarInfo from '../common/snackbar-info.component';
import { getRecurringEventById } from '../../services/content.service';

import ImageUpload from './image-upload.component';
import { CONTENT_ALIAS } from '../../config/const.config';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const UploadRecurringEventAssets = ({ title, dataId, dataTitle, onCancel }) => {
  const isRecurring = true;
  const [loading, setLoading] = useState(true);
  const [recuringEvent, setRecurringEvent] = useState({
    thumbnail: '',
  });

  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [renderImage, setRenderImage] = useState(false);

  const showUpdatedImage = (rerender) => {
    if (rerender) {
      setLoading(true);
    }
    setRenderImage(rerender);
  };

  const showToastMsg = (msgType, message) => {
    if (msgType === 'error') {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
    }

    if (msgType === 'success') {
      setSnackbarInfo({
        show: true,
        type: 'success',
        message,
      });
    }
  };

  useEffect(() => {
    getRecurringEventById(dataId)
      .then((res) => {
        setRecurringEvent({
          thumbnail: res.data[0].posterUrl,
        });

        setLoading(false);
      })
      .catch(() => {
        setRecurringEvent({
          ...recuringEvent,
          thumbnail: '',
        });
        setLoading(false);
      });
  }, [renderImage]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">
        {`${CONTENT_ALIAS.VIDEO_PLURAL} - ${title}`}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        <form id="upload-assets-form">
          <Box
            component="section"
            sx={{
              p: 3,
              border: '1px solid lightgrey',
              borderRadius: '5px',
              width: '100%',
              marginTop: 3,
            }}
          >
            <ImageUpload
              title="Recurring Poster"
              dataId={dataId}
              dataTitle={dataTitle}
              showToastMsg={showToastMsg}
              setSnackbarInfo={setSnackbarInfo}
              videoData={recuringEvent}
              loading={loading}
              showUpdatedImage={showUpdatedImage}
              isRecurring={isRecurring}
            />
          </Box>
        </form>
      </DialogContent>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

UploadRecurringEventAssets.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  dataTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default UploadRecurringEventAssets;
