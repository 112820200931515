import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ImageUpload from './image-upload.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const PosterUpload = ({
  title,
  dataId,
  onCancel,
  imageUrl,
  category,
  isCalendarEventPoster,
  isArticleContent,
  communityGroup,
  isMSVoice,
  hasMultipleImages,
}) => {
  const [loading, setLoading] = useState(true);
  const [videoData, setVideoData] = useState({
    thumbnail: '',
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const showUpdatedImage = () => {};

  const setImageUrlCallBack = (v, from) => {
    if (hasMultipleImages) {
      setVideoData({
        ...videoData,
        [from]: v.imageUrl,
      });
    } else {
      setVideoData({
        thumbnail: v.imageUrl,
      });
    }
  };

  const showToastMsg = (msgType, message) => {
    if (msgType === 'error') {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
    }

    if (msgType === 'success') {
      setSnackbarInfo({
        show: true,
        type: 'success',
        message,
      });
    }
  };

  useEffect(() => {
    if (hasMultipleImages) {
      setVideoData({
        ...videoData,
        poster: imageUrl.poster || '',
        banner: imageUrl.banner || '',
      });
    } else {
      setVideoData({
        ...videoData,
        thumbnail: imageUrl || '',
      });
    }
    setLoading(false);
  }, []);

  return (
    <Dialog
      open
      onClose={() => onCancel()}
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{`${title}`}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        <form id="upload-assets-form">
          <Box
            component="section"
            sx={{
              p: 3,
              border: '1px solid lightgrey',
              borderRadius: '5px',
              width: '100%',
              marginTop: 3,
            }}
          >
            <ImageUpload
              title="Poster"
              dataId={dataId}
              showToastMsg={showToastMsg}
              setSnackbarInfo={setSnackbarInfo}
              videoData={{
                thumbnail: hasMultipleImages
                  ? videoData.poster
                  : videoData.thumbnail,
              }}
              loading={loading}
              showUpdatedImage={showUpdatedImage}
              setImageUrlCallBack={(v) => setImageUrlCallBack(v, 'poster')}
              category={category}
              isCalendarEventPoster={isCalendarEventPoster}
              isArticleContent={isArticleContent}
              communityGroup={communityGroup}
              isMSVoice={isMSVoice}
            />

            {hasMultipleImages && (
              <ImageUpload
                title="Banner"
                dataId={dataId}
                showToastMsg={showToastMsg}
                setSnackbarInfo={setSnackbarInfo}
                videoData={{ thumbnail: videoData.banner }}
                loading={loading}
                showUpdatedImage={showUpdatedImage}
                setImageUrlCallBack={(v) => setImageUrlCallBack(v, 'banner')}
                category={category}
                isCalendarEventPoster={isCalendarEventPoster}
                isArticleContent={isArticleContent}
                communityGroup={communityGroup}
              />
            )}
          </Box>
        </form>
      </DialogContent>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

PosterUpload.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  category: PropTypes.bool,
  isCalendarEventPoster: PropTypes.bool,
  isArticleContent: PropTypes.bool,
  communityGroup: PropTypes.bool,
  isMSVoice: PropTypes.bool,
  hasMultipleImages: PropTypes.bool,
};

PosterUpload.defaultProps = {
  category: false,
  isCalendarEventPoster: false,
  isArticleContent: false,
  communityGroup: false,
  isMSVoice: false,
  hasMultipleImages: false,
};

export default PosterUpload;
