// Define User Roles
export const SUBSCRIBER_ROLE = {
  COMMUNITY_MODERATOR: {
    value: 'COMMUNITY_MODERATOR',
    label: 'Community Moderator',
  },
  NORMAL_USER: {
    value: 'NORMAL_USER',
    label: 'Normal User',
  },
  TEACHER: {
    value: 'TEACHER',
    label: 'Teacher',
  },
  MASTER_SHA: {
    value: 'MASTER_SHA',
    label: 'Master Sha',
  },
};

export const getRoleLabel = (val) => {
  let label = '';

  Object.values(SUBSCRIBER_ROLE).forEach((p) => {
    if (label === '' && p.value === val) {
      label = p.label;
    }
  });

  return label;
};
