import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

export const LISTING_RECORDED_MESSAGE_COLUMNS = [
  {
    id: 'thumbnail',
    label: 'Image Preview',
    width: '6%',
    align: 'center',
    dataKey: 'posterUrl',
    sortable: false,
    type: CELL_DATA_TYPES.IMAGE,
    conditional: (val) => val.type === 'VIDEO',
  },
  {
    id: 'title',
    label: 'Title',
    width: '10%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'preview',
    label: '',
    width: '2%',
    align: 'right',
    dataKey: 'recordedPreview',
    sortable: false,
    type: CELL_DATA_TYPES.RECORDED_PREVIEW,
  },
  {
    id: 'status',
    label: 'Status',
    width: '15%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'publishDate',
    label: 'Publish Date',
    width: '15%',
    align: 'center',
    dataKey: 'publishDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'center',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const VIEW_RECORDED_MESSAGE_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'type',
    label: 'Type',
    dataKey: 'type',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'feed',
    label: 'Community',
    dataKey: 'communityNames',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: `Status`,
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'publish_date',
    label: 'Publish Date',
    dataKey: 'publishDate',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'poster_url',
    label: 'Poster Url',
    dataKey: 'posterUrl',
    type: CELL_DATA_TYPES.TEXT,
    conditional: (val) => val.type === 'VIDEO',
  },
  {
    id: 'url',
    label: 'URL',
    dataKey: 'Url',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'duration',
    label: 'Duration',
    dataKey: 'duration',
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_RECORDED_MESSAGE_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  //   {
  //     action: ACTIONS.TRANSLATE.value,
  //     label: ACTIONS.TRANSLATE.label,
  //   },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.UPLOAD_POSTER.value,
    label: ACTIONS.UPLOAD_POSTER.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];
