import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  getSubscriberNotesList,
  createSubscriberNotes,
  deleteSubscriberNote,
  viewNotesDataById,
  updateNotesDataById,
} from '../../services/subscribers.service';
import {
  NOTE_LISTING_ACTIONS,
  VIEW_NOTES_COLUMNS,
} from '../../config/module-configs/subscribers.config';
import { getCurrentTimestamp } from '../../utils/datetime.util';
import ListData from '../table-elements/list-data.component';
import { ACTIONS, CONTENT_ALIAS } from '../../config/const.config';
import ConfirmPopup from '../common/confirm-popup.component';
import {
  createSubscriptionsNotes,
  deleteSubscriptionsNote,
  getSubscriptionsNotesList,
  updateSubscriptionsNotesById,
  viewSubscriptionsNotesById,
} from '../../services/subscriptions.service';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ManageNotes = ({ title, dataId, onCancel, isSubscription = false }) => {
  const {
    control,
    formState: { errors },
    getValues,
    reset,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      subject: '',
      notesBy: '',
      notes: '',
    },
  });

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
  });

  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });

  const actions = NOTE_LISTING_ACTIONS;
  const columns = VIEW_NOTES_COLUMNS;
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [notesList, setNotesList] = useState([]);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const [isContentVisible, setIsContentVisible] = useState(true);

  const toggleContentVisibility = () => {
    setIsContentVisible((prev) => !prev);
  };

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    if (selectedAction === ACTIONS.EDIT.value) {
      setIsContentVisible(true);
      if (isSubscription) {
        viewSubscriptionsNotesById(selectedData.id)
          .then((res) => {
            reset();
            setValue('subject', res.data?.subject || '');
            setValue('notesBy', res.data?.notesBy || '');
            setValue('notes', res.data?.notes || '');
            setLoading(false);
          })
          .catch((err) => {
            const message =
              err.response?.data?.message ||
              'Something went wrong, please try again.';

            setSnackbarInfo({
              ...snackbarInfo,
              show: true,
              type: 'error',
              message,
            });
            setLoading(false);
          });
      } else {
        viewNotesDataById(selectedData.id)
          .then((res) => {
            reset();
            setValue('subject', res.data?.subject || '');
            setValue('notesBy', res.data?.notesBy || '');
            setValue('notes', res.data?.notes || '');
            setLoading(false);
          })
          .catch((err) => {
            const message =
              err.response?.data?.message ||
              'Something went wrong, please try again.';

            setSnackbarInfo({
              ...snackbarInfo,
              show: true,
              type: 'error',
              message,
            });
            setLoading(false);
          });
      }
    }

    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };

  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
    });
    reset();
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    if (isSubscription) {
      deleteSubscriptionsNote(doAction.data.id)
        .then(() => {
          handleActionSuccess('Subscription Note deleted successfully.');
          handleActionCancel();
        })
        .catch((e) => {
          const message =
            e.response?.data?.message ||
            'Something went wrong, please try again.';

          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
          handleActionCancel();
        });
    } else {
      deleteSubscriberNote(doAction.data.id)
        .then(() => {
          handleActionSuccess('Subscriber Note deleted successfully.');
          handleActionCancel();
        })
        .catch((e) => {
          const message =
            e.response?.data?.message ||
            'Something went wrong, please try again.';

          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
          handleActionCancel();
        });
    }
  };

  const updateExistingData = (payload) => {
    updateNotesDataById(doAction.data.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Subscriber Notes detail updated successfully.',
        });
        handleRefreshData();
        reset();
        setFormSubmitted(false);
        setDoAction({
          data: null,
          action: null,
        });
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateSubscriptionNotes = (payload) => {
    updateSubscriptionsNotesById(doAction.data.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Subscription Notes detail updated successfully.',
        });
        handleRefreshData();
        reset();
        setFormSubmitted(false);
        setDoAction({
          data: null,
          action: null,
        });
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const saveSubscriptionNotes = (payload) => {
    createSubscriptionsNotes(dataId, payload)
      .then((res) => {
        if (res.data.success) {
          setNotesList([
            {
              subject: getValues('subject'),
              notes: getValues('notes'),
              notesBy: getValues('notesBy'),
              createdAt: getCurrentTimestamp(),
            },
            ...notesList,
          ]);
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: 'New note created successfully.',
          });
          handleRefreshData();
          reset();
          setFormSubmitted(false);
        }
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const saveNewData = (payload) => {
    createSubscriberNotes(dataId, payload)
      .then((res) => {
        if (res.data.success) {
          setNotesList([
            {
              subject: getValues('subject'),
              notes: getValues('notes'),
              notesBy: getValues('notesBy'),
              createdAt: getCurrentTimestamp(),
            },
            ...notesList,
          ]);
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: 'New note created successfully.',
          });
          handleRefreshData();
          reset();
          setFormSubmitted(false);
        }
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      subject: data.subject,
      notesBy: data.notesBy,
      notes: data.notes,
    };

    if (doAction?.data?.id) {
      if (isSubscription) {
        updateSubscriptionNotes(payload);
      } else {
        updateExistingData(payload);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (isSubscription) {
        saveSubscriptionNotes(payload);
      } else {
        saveNewData(payload);
      }
    }
  };

  useEffect(() => {
    let params = `?page=${options.page + 1}`;
    params += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      params += `&sortBy=${options.sortBy}`;
      params += `&sortOrder=${options.sortOrder}`;
    }
    if (isSubscription) {
      getSubscriptionsNotesList(dataId, params)
        .then((res) => {
          setOptions({
            ...options,
            loading: false,
            totalRows: res?.data?.totalRows || 0,
            rows: res?.data?.rows || [],
            error: false,
          });
          setNotesList(res.data.rows);
          setLoading(false);
        })
        .catch((err) => {
          setOptions({
            ...options,
            loading: false,
            page: 0,
            totalRows: 0,
            rows: [],
            error: true,
          });
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';

          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
          setLoading(false);
        });
    } else {
      getSubscriberNotesList(dataId, params)
        .then((res) => {
          setOptions({
            ...options,
            loading: false,
            totalRows: res?.data?.totalRows || 0,
            rows: res?.data?.rows || [],
            error: false,
          });
          setNotesList(res.data.rows);
          setLoading(false);
        })
        .catch((err) => {
          setOptions({
            ...options,
            loading: false,
            page: 0,
            totalRows: 0,
            rows: [],
            error: true,
          });
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';

          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
          setLoading(false);
        });
    }
  }, [dataId, options.reloadCounter]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      onClose={() => onCancel()}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        id="view-dialog-title"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>
      {isContentVisible && doAction?.data?.id && (
        <Box
          sx={{
            padding: '0 24px',
            fontSize: '14px',
            color: '#666',
            fontWeight: 'bold',
            marginBottom: '8px',
          }}
        >
          {isSubscription ? 'Edit Subscription Notes' : 'Edit Subscriber Notes'}
        </Box>
      )}

      <DialogContent
        dividers
        id="add-dialog-description"
        sx={{ overflow: 'visible' }}
      >
        {loading && <Loading />}

        {!loading && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={toggleContentVisibility}>
                {isContentVisible ? 'Close Form' : 'Open Form'}
              </Button>
            </Box>
            {isContentVisible && (
              <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="subject"
                      control={control}
                      rules={{
                        ...validationRules.REQUIRED,
                        ...maxLengthValidation(80),
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Subject"
                          type="text"
                          error={!!errors.subject}
                          helperText={errors?.subject?.message || null}
                          fullWidth
                          inputProps={{ maxLength: 80 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        fontWeight: 'bold',
                        textAlign: 'left',
                        fontSize: 12,
                      }}
                    >
                      Note:
                    </Box>
                    <Box sx={{ textAlign: 'left', fontSize: 12 }}>
                      Please use the following format for adding your name to
                      notes:
                      <br />
                      Department initials, first name, last initial.
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="notesBy"
                      control={control}
                      rules={{
                        ...validationRules.REQUIRED,
                        ...maxLengthValidation(100),
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Notes By"
                          type="text"
                          error={!!errors.notesBy}
                          helperText={errors?.notesBy?.message || null}
                          fullWidth
                          inputProps={{ maxLength: 100 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ textAlign: 'left', fontSize: 12 }}>
                      <strong>Example:</strong>
                      <br />
                      M/Anastasia (M = Marketing)
                      <br />
                      GCS/Tatyana (GCS = Global Customer Service)
                      <br />
                      ES/Gabriela (ES = Ecom Support)
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="notes"
                      control={control}
                      rules={{
                        ...validationRules.REQUIRED,
                        ...{ ...maxLengthValidation(1500) },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Notes"
                          type="text"
                          multiline
                          minRows={3}
                          maxRows={3}
                          error={!!errors.notes}
                          helperText={errors?.notes?.message || null}
                          fullWidth
                          inputProps={{ maxLength: 1500 }}
                          sx={{
                            '& .MuiInputBase-root': {
                              overflowY: 'auto',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </>
        )}
      </DialogContent>
      {isContentVisible && (
        <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
          >
            {doAction?.data?.id ? 'Save Changes' : 'Save'}
          </Button>
          {doAction?.data?.id && (
            <Button
              color="secondary"
              variant="contained"
              onClick={handleCancel}
              disabled={formSubmitted}
            >
              Cancel
            </Button>
          )}
        </DialogActions>
        // </>
      )}

      <DialogContent dividers>
        <Box fontWeight="bold">Notes List</Box>

        {!loading && (
          <ListData
            columns={columns}
            rows={options.rows}
            page={options.page}
            rowsPerPage={options.rowsPerPage}
            totalRows={options.totalRows}
            loading={options.loading}
            actions={actions}
            error={options.error}
            sortBy={options.sortBy}
            sortOrder={options.sortOrder}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSortChange={handleSortingChange}
            onAction={handleActionSelect}
          />
        )}
        {doAction.action === ACTIONS.DELETE.value && (
          <ConfirmPopup
            title={
              isSubscription
                ? `Delete ${CONTENT_ALIAS.SUBSCRIPTION_NOTES}`
                : `Delete ${CONTENT_ALIAS.SUBSCRIBER_NOTES}`
            }
            message="Do you want to delete this note? You can not undo this action!"
            onCancel={handleActionCancel}
            onSuccess={handleDeleteData}
          />
        )}
      </DialogContent>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

ManageNotes.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubscription: PropTypes.bool.isRequired,
};

export default ManageNotes;
