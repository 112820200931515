import PropTypes from 'prop-types';
import React, { createContext, useState, useMemo } from 'react';

// Create a Context for access
export const AccessContext = createContext();

export const AccessProvider = ({ children }) => {
  const [accessData, setAccessData] = useState({
    role: '',
    // rights: {},
  });

  const handleSetAccessData = (data) => {
    setAccessData(data);
  };

  const value = useMemo(
    () => ({ accessData, handleSetAccessData }),
    [accessData]
  );

  return (
    <AccessContext.Provider value={value}>{children}</AccessContext.Provider>
  );
};

AccessProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
