import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Card,
  Stack,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/transmission-organs.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import { getDataList, toggleStatus } from '../../services/content.service';
import { ACTIONS, MULTIPLE_CAT_PER_VIDEO } from '../../config/const.config';
import SnackbarInfo from '../common/snackbar-info.component';
import AddEditTransmissionOrgan from './add-edit-transmission-organ.component';
import ViewTransmissionOrgan from './view-transmission-organ.component';
import TranslateTransmissionOrgan from './translate-transmission-organ.component';
import UploadTransmissionOrganPoster from './upload-transmission-organ-poster.component';
import ManageTeachers from './manage-teachers.component';
import useFilteredActions from '../../hooks/filtered-action.hook';
import { AccessContext } from '../../context/access.context';
import useAccess from '../../hooks/access.hook';

const ListTransmissionOrgans = () => {
  const { accessData } = useContext(AccessContext);
  const { hasOperationAccess } = useAccess();
  const columns = LISTING_COLUMNS;
  const actions = useFilteredActions(
    accessData.role,
    'transmission_organs',
    LISTING_ACTIONS
  );

  const renderActions = [];
  actions.forEach((item) => {
    if (MULTIPLE_CAT_PER_VIDEO && item.action === 'assign_books') {
      renderActions.push(item);
    } else if (item.action !== 'assign_books') {
      renderActions.push(item);
    }
  });

  const searchVideosRef = useRef(null);
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleToggleStatus = (r, value) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      id: r.id,
      status: value ? 0 : 1,
      type: 'video',
    };

    toggleStatus(payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Status updated.',
        });
      })
      .catch((e) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: e.response?.data?.message,
        });
      });
  };

  const handleSearchChange = (e) => {
    setOptions({
      ...options,
      searchValue: e.target.value || '',
    });
  };

  const handleSearchVideos = () => {
    setOptions({
      ...options,
      q: searchVideosRef.current.value,
      reloadCounter: options.reloadCounter + 1,
      page: 0,
    });
  };

  const dataListAPICall = () => {
    let uri = 'transmission-organs';
    uri += `?page=${options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      uri += `&q=${options.q}`;
    }

    setOptions({ ...options, loading: true, searchValue: '' });

    getDataList(uri)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  };

  useEffect(() => {
    dataListAPICall(options);
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>
          {hasOperationAccess(
            accessData.role,
            'transmission_organs',
            'create'
          ) && (
            <Button
              color="primary"
              variant="contained"
              startIcon={<Iconify icon="ic:round-add" />}
              onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
            >
              Create New
            </Button>
          )}

          <TextField
            label="Search Transmission Organ"
            type="text"
            inputRef={searchVideosRef}
            onChange={handleSearchChange}
            sx={{
              width: '260px',
              marginLeft: '5px',
              '.MuiInputLabel-root.MuiInputLabel-shrink.MuiFormLabel-filled': {
                top: '0px',
              },
              '.MuiInputLabel-root': {
                top: '-8px',
              },
            }}
            InputProps={{
              style: {
                height: '2.25em',
              },
              endAdornment: (
                <InputAdornment position="end" onClick={handleSearchVideos}>
                  <IconButton edge="end" color="primary">
                    <Iconify icon="fe:search" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: !(
                searchVideosRef.current?.value === '' ||
                searchVideosRef.current === null
              ),
            }}
          />
        </div>
        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={renderActions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
          handleToggleStatus={handleToggleStatus}
        />
      </Card>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditTransmissionOrgan
          title="Add New Transmission Organ"
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditTransmissionOrgan
          title={`Edit Transmission Organ Details - ${doAction.data.transmissionOrgan}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewTransmissionOrgan
          title={`Transmission Organ Details - ${doAction.data.transmissionOrgan}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}
      {doAction.action === ACTIONS.UPLOAD_POSTER.value && (
        <UploadTransmissionOrganPoster
          title={`Upload Assets - ${doAction.data.transmissionOrgan}`}
          dataId={doAction.data.id}
          dataTitle={doAction.data.title}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.TRANSLATE.value && (
        <TranslateTransmissionOrgan
          title={`Translate Transmission Organ Details - ${doAction.data.transmissionOrgan}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.MANAGE_TEACHERS.value && (
        <ManageTeachers
          title={`Manage Teachers - ${doAction.data.transmissionOrgan} - ${doAction.data.elementType}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          categoryId={doAction.data.categoryID}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListTransmissionOrgans;
