import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  Box,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListSubheader,
  Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ROUTE_PATH from '../../../config/routes.config';
import Iconify from '../../common/iconify.component';
import {
  CONTENT_ALIAS,
  FOCUS_PALETTE_BGCOLOR,
  SHOW_ADMIN_USERS,
  SHOW_CONTENT_SECTION,
  SHOW_CONTENT_CATEGORIES,
  SHOW_CONTENT_SEQUENCE,
  SHOW_CONTENT_VIDEOS,
  SHOW_CONTENT_TRANSMISSION_ORGANS,
  SHOW_NOTIFICATIONS,
  SHOW_OVERVIEW_DASHBOARD,
  SHOW_REALTIME_DASHBOARD,
  SHOW_REPORTS_LIVE_USERS,
  SHOW_REPORTS_OVERVIEW_VIDEOS,
  SHOW_REPORTS_SUBSCRIPTIONS,
  SHOW_REPORTS_WATCH_SESSIONS,
  SHOW_SALUTATION,
  SHOW_SUBSCRIBERS,
  SHOW_SUBSCRIPTIONS,
  SHOW_VIDEO_COMMENTS,
  SHOW_GROUPS,
  SHOW_CONTENT_COMMUNITY_GATHERINGS,
  SHOW_PACKAGES,
  SHOW_CONTENT_POPULAR,
  SHOW_CONTENT_TRANSMISSION_PROGRAM_REQUEST,
  SHOW_REPORTS_LIVE_EVENT,
  SHOW_REPORTS_PROGRAMS,
  SHOW_CONTENT_RESOURCES,
  SHOW_TESTIMONIALS,
  SHOW_ARTICLE,
  SHOW_RECORD_MESSAGE,
} from '../../../config/const.config';
import palette from '../../../theme/palette';
import useAccess from '../../../hooks/access.hook';
import { AccessContext } from '../../../context/access.context';

const StyledSubHeader = styled((props) => <ListSubheader {...props} />)(
  ({ theme }) => ({
    background: 'transparent',
    color: theme.palette.grey[200],
  })
);

const StyledNavItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const StyledNavItemIcon = styled(ListItemIcon)({
  width: 24,
  height: 24,
  color: palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 24,
});

const NavItem = ({ title, path, icon, subNav }) => (
  <StyledNavItem
    component={RouterLink}
    to={path}
    sx={{
      '&.active': {
        color: palette.common.white,
        bgcolor: FOCUS_PALETTE_BGCOLOR,
        fontWeight: 'fontWeightBold',
      },
      height: subNav ? 36 : 48,
    }}
  >
    {!subNav && <StyledNavItemIcon>{icon}</StyledNavItemIcon>}
    <ListItemText
      disableTypography
      primary={title}
      sx={{
        color: palette.common.white,
        pl: subNav ? 6 : 0,
        overflowX: 'clip',
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
        paddingRight: 2,
      }}
    />
  </StyledNavItem>
);
NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.element,
  subNav: PropTypes.bool,
};
NavItem.defaultProps = {
  icon: <div />,
  subNav: false,
};

const NavGroup = ({ title, routePath, icon, navGroup }) => {
  const urlParts = window.location.href.split('/');
  const routePathParts = routePath.split('/');

  const initOpen =
    urlParts[urlParts.length - 2] === routePathParts[routePathParts.length - 1];

  const [open, setOpen] = useState(initOpen);
  const handleClick = () => setOpen(!open);

  return (
    <>
      <StyledNavItem onClick={handleClick}>
        <StyledNavItemIcon>{icon}</StyledNavItemIcon>
        <ListItemText
          disableTypography
          primary={title}
          sx={{
            color: palette.common.white,
            overflowX: 'clip',
            textWrap: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        />
        <StyledNavItemIcon>
          <Iconify icon={open ? 'mdi:menu-up' : 'mdi:menu-down'} />
        </StyledNavItemIcon>
      </StyledNavItem>

      <Collapse in={open} timeout="auto">
        <List disablePadding sx={{ p: 1 }}>
          {navGroup.map(
            (nav) =>
              nav.status === 1 && (
                <NavItem
                  key={nav.title}
                  title={nav.title}
                  path={`${routePath}/${nav.slug}`}
                  icon={<div />}
                  subNav
                />
              )
          )}
        </List>
      </Collapse>
    </>
  );
};
NavGroup.propTypes = {
  title: PropTypes.string.isRequired,
  routePath: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  navGroup: PropTypes.array.isRequired,
};

const NavItems = () => {
  const { hasAccess } = useAccess();
  const { accessData } = useContext(AccessContext);

  return (
    <Box>
      <List disablePadding sx={{ p: 1 }}>
        {SHOW_OVERVIEW_DASHBOARD &&
          !!hasAccess(accessData.role, 'dashboard') && (
            <NavItem
              title="Overview Dashboard"
              path={ROUTE_PATH.DASHBOARD}
              icon={<Iconify icon="ic:round-dashboard" />}
            />
          )}
        {SHOW_REALTIME_DASHBOARD && (
          <NavItem
            title="Live Users (Per Platform)"
            path={ROUTE_PATH.REALTIME_DASHBOARD}
            icon={<Iconify icon="tabler:activity-heartbeat" />}
          />
        )}
        {SHOW_SUBSCRIBERS && !!hasAccess(accessData.role, 'subscribers') && (
          <NavItem
            title="Subscribers"
            path={ROUTE_PATH.SUBSCRIBERS}
            icon={<Iconify icon="ci:users" />}
          />
        )}
        {SHOW_SUBSCRIPTIONS &&
          !!hasAccess(accessData.role, 'subscriptions') && (
            <NavItem
              title="Subscriptions"
              path={ROUTE_PATH.SUBSCRIPTIONS}
              icon={<Iconify icon="streamline:subscription-cashflow" />}
            />
          )}
        {SHOW_GROUPS && !!hasAccess(accessData.role, 'community_groups') && (
          <NavItem
            title="Community Groups"
            path={ROUTE_PATH.GROUPS}
            icon={<Iconify icon="grommet-icons:group" />}
          />
        )}
        {SHOW_PACKAGES && !!hasAccess(accessData.role, 'packages') && (
          <NavItem
            title="Packages"
            path={ROUTE_PATH.PACKAGES}
            icon={<Iconify icon="solar:tag-price-bold" />}
          />
        )}
        {SHOW_ADMIN_USERS && !!hasAccess(accessData.role, 'users') && (
          <NavItem
            title="Users"
            path={ROUTE_PATH.ADMIN_USERS}
            icon={<Iconify icon="mdi:users-check" />}
          />
        )}
        <StyledSubHeader component="div">Content</StyledSubHeader>
        {SHOW_CONTENT_SECTION &&
          !!hasAccess(accessData.role, 'program_course_group') && (
            <NavItem
              title={`${CONTENT_ALIAS.SECTION_PLURAL}`}
              path={ROUTE_PATH.CONTENT_SECTIONS}
              icon={<Iconify icon="iconamoon:category" />}
            />
          )}
        {SHOW_CONTENT_CATEGORIES &&
          !!hasAccess(accessData.role, 'program_courses') && (
            <NavItem
              title={`${CONTENT_ALIAS.CATEGORY_PLURAL}`}
              path={ROUTE_PATH.CONTENT_CATEGORIES}
              icon={<Iconify icon="hugeicons:course" />}
            />
          )}
        {SHOW_CONTENT_VIDEOS && !!hasAccess(accessData.role, 'videos') && (
          <NavItem
            title={`${CONTENT_ALIAS.VIDEO_PLURAL}`}
            path={ROUTE_PATH.CONTENT_VIDEOS}
            icon={<Iconify icon="octicon:video-16" />}
          />
        )}
        {SHOW_CONTENT_TRANSMISSION_ORGANS &&
          !!hasAccess(accessData.role, 'transmission_organs') && (
            <NavItem
              title={`${CONTENT_ALIAS.TRANSMISSION_ORGAN_PLURAL}`}
              path={ROUTE_PATH.CONTENT_TRANSMISSION_ORGANS}
              icon={
                <Iconify icon="covid:transmission-virus-mobile-application" />
              }
            />
          )}
        {SHOW_CONTENT_TRANSMISSION_PROGRAM_REQUEST &&
          !!hasAccess(accessData.role, 'tao_soul_program_requests') && (
            <NavItem
              title={`${CONTENT_ALIAS.TRANSMISSION_PROGRAM_REQUEST_PLURAL}`}
              path={ROUTE_PATH.CONTENT_TRANSMISSION_PROGRAM_REQUEST}
              icon={<Iconify icon="fluent:clipboard-task-add-20-regular" />}
            />
          )}
        {SHOW_CONTENT_POPULAR &&
          !!hasAccess(accessData.role, 'popular_content') && (
            <NavItem
              title={`${CONTENT_ALIAS.POPULAR_CONTENT_SINGULAR}`}
              path={ROUTE_PATH.CONTENT_POPULAR}
              icon={<Iconify icon="tabler:chart-bar-popular" />}
            />
          )}
        {SHOW_CONTENT_RESOURCES &&
          !!hasAccess(accessData.role, 'resources') && (
            <NavItem
              title={`${CONTENT_ALIAS.RESOURCES_PLURAL}`}
              path={ROUTE_PATH.CONTENT_RESOURCES}
              icon={<Iconify icon="ci:files" />}
            />
          )}
        {SHOW_CONTENT_COMMUNITY_GATHERINGS &&
          !!hasAccess(accessData.role, 'community_gatherings_zoom') && (
            <NavItem
              title="Comm. Gatherings (Zoom meetings)"
              path={ROUTE_PATH.CONTENT_COMMUNITY_GATHERINGS}
              icon={<Iconify icon="healthicons:community-meeting" />}
            />
          )}

        {SHOW_RECORD_MESSAGE &&
          (!!hasAccess(accessData.role, 'ms_voice_recorded_videos') ||
            !!hasAccess(accessData.role, 'ms_voice_recorded_audios') ||
            !!hasAccess(accessData.role, 'ms_voice_link_sections') ||
            !!hasAccess(accessData.role, 'ms_voice_link_section_items')) && (
            <NavGroup
              title="MS Voice"
              routePath={ROUTE_PATH.CONTENT_RECORDED_MESSAGE}
              icon={<Iconify icon="hugeicons:mail-voice-01" />}
              navGroup={[
                {
                  title: 'Recorded Videos',
                  slug: 'videos',
                  status: hasAccess(accessData.role, 'ms_voice_recorded_videos')
                    ? 1
                    : 0,
                },
                {
                  title: 'Recorded Audios',
                  slug: 'audios',
                  status: hasAccess(accessData.role, 'ms_voice_recorded_audios')
                    ? 1
                    : 0,
                },
                {
                  title: 'Link Sections',
                  slug: 'link-sections',
                  status: hasAccess(accessData.role, 'ms_voice_link_sections')
                    ? 1
                    : 0,
                },
                {
                  title: 'Link Section Items',
                  slug: 'link-section-items',
                  status: hasAccess(
                    accessData.role,
                    'ms_voice_link_section_items'
                  )
                    ? 1
                    : 0,
                },
              ]}
            />
          )}

        {SHOW_CONTENT_SEQUENCE &&
          (!!hasAccess(accessData.role, 'sequence_courses') ||
            !!hasAccess(accessData.role, 'sequence_programs') ||
            !!hasAccess(
              accessData.role,
              'sequence_tao_calligraphy_meditations'
            ) ||
            !!hasAccess(accessData.role, 'sequence_blessings') ||
            !!hasAccess(accessData.role, 'sequence_love_peace_harmonys') ||
            !!hasAccess(accessData.role, 'sequence_article_contents') ||
            !!hasAccess(accessData.role, 'sequence_link_section')) && (
            <NavGroup
              title="Sequence"
              routePath={ROUTE_PATH.CONTENT_SEQUENCE}
              icon={<Iconify icon="fluent-mdl2:number-sequence" />}
              navGroup={[
                {
                  title: `${CONTENT_ALIAS.COURSES}`,
                  slug: 'courses',
                  status: hasAccess(accessData.role, 'sequence_courses')
                    ? 1
                    : 0,
                },
                {
                  title: `${CONTENT_ALIAS.PROGRAMS_PLURAL}`,
                  slug: 'programs',
                  status: hasAccess(accessData.role, 'sequence_programs')
                    ? 1
                    : 0,
                },
                {
                  title: `${CONTENT_ALIAS.TAOSONG_PLURAL}`,
                  slug: 'tao-songs',
                  status: hasAccess(
                    accessData.role,
                    'sequence_tao_calligraphy_meditations'
                  )
                    ? 1
                    : 0,
                },
                {
                  title: `${CONTENT_ALIAS.BLESSING_PLURAL}`,
                  slug: 'blessings',
                  status: hasAccess(accessData.role, 'sequence_blessings')
                    ? 1
                    : 0,
                },
                {
                  title: `${CONTENT_ALIAS.MEDITATION_PLURAL}`,
                  slug: 'meditation',
                  status: hasAccess(
                    accessData.role,
                    'sequence_love_peace_harmonys'
                  )
                    ? 1
                    : 0,
                },
                {
                  title: `${CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL}`,
                  slug: 'article-contents',
                  status: hasAccess(
                    accessData.role,
                    'sequence_article_contents'
                  )
                    ? 1
                    : 0,
                },
                {
                  title: `${CONTENT_ALIAS.LINK_SECTION}`,
                  slug: 'link-sections',
                  status: hasAccess(accessData.role, 'sequence_link_section')
                    ? 1
                    : 0,
                },
              ]}
            />
          )}
        {SHOW_TESTIMONIALS &&
          (!!hasAccess(accessData.role, 'testimonials_tabs') ||
            !!hasAccess(accessData.role, 'testimonials_categories') ||
            !!hasAccess(accessData.role, 'testimonials_text_testimonials') ||
            !!hasAccess(
              accessData.role,
              'testimonials_user_text_testimonials'
            ) ||
            !!hasAccess(
              accessData.role,
              'testimonials_user_video_testimonials'
            )) && (
            <NavGroup
              title="Testimonials"
              routePath={ROUTE_PATH.CONTENT_TESTIMONIALS}
              icon={<Iconify icon="fluent-mdl2:office-chat" />}
              navGroup={[
                {
                  title: 'Tabs',
                  slug: 'tabs',
                  status: hasAccess(accessData.role, 'testimonials_tabs')
                    ? 1
                    : 0,
                },
                {
                  title: 'Categories',
                  slug: 'categories',
                  status: hasAccess(accessData.role, 'testimonials_categories')
                    ? 1
                    : 0,
                },
                {
                  title: 'Text Testimonials',
                  slug: 'text-testimonials',
                  status: hasAccess(
                    accessData.role,
                    'testimonials_text_testimonials'
                  )
                    ? 1
                    : 0,
                },
                {
                  title: 'User Text Testimonials',
                  slug: 'user-text-testimonials',
                  status: hasAccess(
                    accessData.role,
                    'testimonials_user_text_testimonials'
                  )
                    ? 1
                    : 0,
                },
                {
                  title: 'User Video Testimonials',
                  slug: 'user-video-testimonials',
                  status: hasAccess(
                    accessData.role,
                    'testimonials_user_video_testimonials'
                  )
                    ? 1
                    : 0,
                },
              ]}
            />
          )}
        {SHOW_ARTICLE &&
          (!!hasAccess(accessData.role, 'article_categories') ||
            !!hasAccess(accessData.role, 'article_contents')) && (
            <NavGroup
              title="Articles"
              routePath={ROUTE_PATH.CONTENT_ARTICLE}
              icon={<Iconify icon="fluent:document-ribbon-24-regular" />}
              navGroup={[
                {
                  title: 'Article Categories',
                  slug: 'categories',
                  status: hasAccess(accessData.role, 'article_categories')
                    ? 1
                    : 0,
                },
                {
                  title: 'Article Contents',
                  slug: 'content',
                  status: hasAccess(accessData.role, 'article_contents')
                    ? 1
                    : 0,
                },
              ]}
            />
          )}
        {SHOW_VIDEO_COMMENTS && !!hasAccess(accessData.role, 'comments') && (
          <NavItem
            title="Comments"
            path={ROUTE_PATH.CONTENT_VIDEO_COMMENTS}
            icon={<Iconify icon="tabler:message-circle-cog" />}
          />
        )}
        {SHOW_NOTIFICATIONS &&
          !!hasAccess(accessData.role, 'notifications') && (
            <NavItem
              title="Notifications"
              path={ROUTE_PATH.NOTIFICATIONS}
              icon={
                <Iconify icon="material-symbols:notification-add-outline-rounded" />
              }
            />
          )}
        {SHOW_SALUTATION && (
          <NavItem
            title="Salutation"
            path={ROUTE_PATH.SALUTATION}
            icon={<Iconify icon="mdi:human-greeting-variant" />}
          />
        )}
        {(SHOW_REPORTS_OVERVIEW_VIDEOS ||
          SHOW_REPORTS_LIVE_USERS ||
          SHOW_REPORTS_WATCH_SESSIONS) &&
          (!!hasAccess(accessData.role, 'video_watch_overview') ||
            !!hasAccess(accessData.role, 'video_watch_sessions') ||
            !!hasAccess(accessData.role, 'live_users')) && (
            <StyledSubHeader component="div">Analytics Reports</StyledSubHeader>
          )}
        {SHOW_REPORTS_OVERVIEW_VIDEOS &&
          !!hasAccess(accessData.role, 'video_watch_overview') && (
            <NavItem
              title="Video Watch Overview"
              path={ROUTE_PATH.REPORTS_OVERVIEW_VIDEOS}
              icon={<Iconify icon="ic:outline-analytics" />}
            />
          )}
        {SHOW_REPORTS_WATCH_SESSIONS &&
          !!hasAccess(accessData.role, 'video_watch_sessions') && (
            <NavItem
              title="Video Watch Sessions"
              path={ROUTE_PATH.REPORTS_SESSIONS}
              icon={<Iconify icon="ic:outline-list-alt" />}
            />
          )}
        {SHOW_REPORTS_LIVE_USERS &&
          !!hasAccess(accessData.role, 'live_users') && (
            <NavItem
              title="Live Users"
              path={ROUTE_PATH.REPORTS_LIVE_USERS}
              icon={<Iconify icon="ic:round-live-tv" />}
            />
          )}
        {SHOW_REPORTS_SUBSCRIPTIONS &&
          !!hasAccess(accessData.role, 'subscriptions_summary') && (
            <NavItem
              title="Subscriptions Summary"
              path={ROUTE_PATH.REPORTS_SUBSCRIPTIONS}
              icon={<Iconify icon="wpf:banknotes" />}
            />
          )}
        {SHOW_REPORTS_LIVE_EVENT &&
          !!hasAccess(accessData.role, 'live_event_report') && (
            <NavItem
              title="Live Event Report"
              path={ROUTE_PATH.REPORTS_LIVE_EVENT}
              icon={<Iconify icon="fluent-mdl2:calendar-reply" />}
            />
          )}
        {SHOW_REPORTS_PROGRAMS &&
          !!hasAccess(accessData.role, 'purchase_report') && (
            <NavItem
              title="Purchase Report"
              path={ROUTE_PATH.REPORTS_PROGRAMS}
              icon={<Iconify icon="mdi:report-areaspline" />}
            />
          )}
        {SHOW_REPORTS_PROGRAMS && (
          <NavItem
            title="Course Report"
            path={ROUTE_PATH.REPORTS_COURSE}
            icon={<Iconify icon="ic:outline-analytics" />}
          />
        )}
      </List>
    </Box>
  );
};

NavItems.propTypes = {};

export default NavItems;
