/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { getSearchableListContent } from '../../services/content.service';
import { CATEGORY_TYPE } from '../../config/const.config';

const Category = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  sx,
  error,
  type,
  disabled,
}) => {
  const [contentData, setContentData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const promises = type.map((types) => getSearchableListContent(types));
      const responses = await Promise.all(promises);
      const combinedData = responses.flatMap((res) => res.data);
      setContentData(combinedData);
    };

    fetchData();
  }, [type]);

  return (
    <FormControl fullWidth error={error !== ''}>
      <InputLabel id={`field-${id}-select-label`}>{label}</InputLabel>
      <Select
        labelId={`field-${id}-select-label`}
        id={id}
        name={name}
        value={defaultValue}
        label={label}
        onChange={onChange}
        sx={sx}
        renderValue={(selected) => (selected ? `${selected?.name}` : '')}
        disabled={disabled}
      >
        {contentData.map((k) => (
          <MenuItem
            key={`${id}-v-${k.id}`}
            value={{ id: k.id, name: k.name, isPaid: k.isPaid, type: k.type }}
          >
            {k.type === CATEGORY_TYPE[2].id
              ? `${k.name} (${CATEGORY_TYPE[2].label})`
              : `${k.name}`}
          </MenuItem>
        ))}
      </Select>
      {error !== '' && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

Category.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  type: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
};

Category.defaultProps = {
  sx: {},
  error: '',
  disabled: false,
};

export default Category;
