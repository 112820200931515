import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getListData = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/subscribers/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createNewData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/subscribers/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/subscribers/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/subscribers/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/subscribers/${id}/delete`,
    'DELETE',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updatePasswordById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/subscribers/${id}/update-password`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchListData = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/subscribers/search-list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const autoLoginSubscriber = async (id, params) => {
  const options = prepareRequestOptions(
    `/admin/subscriber/${id}/auto-login?os=${params.os}&model=${params.model}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getSubscriberNotesList = async (id, params) => {
  const options = prepareRequestOptions(
    `/admin/subscriber/${id}/notes/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createSubscriberNotes = async (id, data) => {
  const options = prepareRequestOptions(
    `/admin/subscriber/${id}/notes/create`,
    'POST',
    data,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteSubscriberNote = async (id) => {
  const options = prepareRequestOptions(
    `/admin//subscribers/${id}/notes/delete`,
    'DELETE',
    null,
    true
  );
  const response = await axios(options);
  return response.data;
};

export const viewNotesDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/subscribers/${id}/notes/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateNotesDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/subscribers/${id}/notes/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};
