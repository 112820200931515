import axios from 'axios';
import { getDeviceId } from '../utils/device-info.util';
import { getLocalValue } from '../utils/local-cache.util';
import { LS_ADMIN_USER_AUTH_TOKEN } from '../config/const.config';

const prepareRequestOptions = (url, method, data = null) => {
  const baseAPIUrl = process.env.REACT_APP_CMS_API_ENDPOINT;
  const uuid = getDeviceId();
  const adminAuth = getLocalValue(LS_ADMIN_USER_AUTH_TOKEN);

  const options = {
    url: `${baseAPIUrl}${url}`,
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-Req-Device': uuid,
      'X-Admin-Auth-Token': adminAuth,
    },
    data: JSON.stringify(data || {}),
  };

  return options;
};

export const getCounters = async () => {
  const options = prepareRequestOptions('/admin/reports/counters', 'GET');

  const response = await axios(options);
  return response.data;
};

export const getRealtimeDashboard = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/reports/realtime-dashboard',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getVideosReportData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/reports/export/videos',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getSubscriptionsReportData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/reports/subscriptions-purchased',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getLiveEventReportData = async (payload) => {
  const options = prepareRequestOptions('/admin/logs/events', 'POST', payload);
  const response = await axios(options);
  return response.data;
};

export const getProgramReportData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/report/programs',
    'POST',
    payload
  );
  const response = await axios(options);
  return response.data;
};

export const getCourseRegisterReportData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/report/course-registration',
    'POST',
    payload
  );
  const response = await axios(options);
  return response.data;
};

export const getVideoWatchSessionsReport = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/reports/watched-sessions',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getDataList = async (type) => {
  const options = prepareRequestOptions(`/admin/reports/list/${type}`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const viewSessionById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/reports/session/${id}/view`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const getVideos = async () => {
  const options = prepareRequestOptions('/admin/reports/list/videos', 'GET');

  const response = await axios(options);
  return response.data;
};
