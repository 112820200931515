import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Button,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ViewData from '../table-elements/view-data.component';
import ConfirmPopup from '../common/confirm-popup.component';
import {
  updateTransmissionRequestById,
  viewTransmissionProgramRequestById,
} from '../../services/content.service';
import { VIEW_COLUMNS } from '../../config/module-configs/transmission-program-request.confing';
import { ACTIONS, STATUS_VALUE } from '../../config/const.config';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ViewTransmissionProgramRequest = ({
  title,
  dataId,
  status,
  onCancel,
  onSuccess,
}) => {
  const columns = VIEW_COLUMNS;
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState(null);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });

  const handleActionSelect = (selectedAction) => {
    setDoAction({
      action: selectedAction,
      data: rowData,
    });
  };

  const handleRefreshData = async () => {
    setLoading(true);
    try {
      const res = await viewTransmissionProgramRequestById(dataId);
      setRowData(res.data);
      setLoading(false);
    } catch (err) {
      const message =
        err.response?.data?.message ||
        'Something went wrong, please try again.';
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
      setLoading(false);
    }
  };

  const handleActionCancel = () => {
    setDoAction({
      data: null,
      action: null,
    });
  };

  const updateExistingData = async (payload) => {
    const data = { status: payload };
    try {
      const res = await updateTransmissionRequestById(dataId, data);

      if (res.data === 1) {
        if (payload === STATUS_VALUE[1].value) {
          onSuccess('Request approved successfully.');
        } else if (payload === STATUS_VALUE[2].value) {
          onSuccess('Request denied successfully.');
        }
      }
    } catch (err) {
      const message =
        err.response?.data?.message ||
        'Something went wrong, please try again.';
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
    }
  };

  useEffect(() => {
    handleRefreshData();
  }, []);

  return (
    <Dialog
      open
      onClose={onCancel}
      aria-labelledby="view-dialog-title"
      aria-describedby="view-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="view-dialog-description">
        {loading && <Loading />}
        {!loading && <ViewData columns={columns} row={rowData} />}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        {(status === STATUS_VALUE[2].value ||
          status === STATUS_VALUE[0].value) && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleActionSelect(ACTIONS.ALLOW.value)}
          >
            Allow
          </Button>
        )}
        {(status === STATUS_VALUE[1].value ||
          status === STATUS_VALUE[0].value) && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleActionSelect(ACTIONS.DENY.value)}
          >
            Deny
          </Button>
        )}
        <Button color="secondary" variant="contained" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>

      {doAction.action === ACTIONS.ALLOW.value && (
        <ConfirmPopup
          title="Are you sure you want to approve the request?"
          message=""
          onCancel={handleActionCancel}
          onSuccess={() => {
            updateExistingData(STATUS_VALUE[1].value);
          }}
        />
      )}
      {doAction.action === ACTIONS.DENY.value && (
        <ConfirmPopup
          title="Are you sure you want to deny this request?"
          message=""
          onCancel={handleActionCancel}
          onSuccess={() => {
            updateExistingData(STATUS_VALUE[2].value);
          }}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo
          type={snackbarInfo.type}
          message={snackbarInfo.message}
          onClose={() =>
            setSnackbarInfo({
              show: false,
              type: '',
              message: '',
            })
          }
        />
      )}
    </Dialog>
  );
};

ViewTransmissionProgramRequest.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ViewTransmissionProgramRequest;
