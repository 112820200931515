import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Grid,
  Box,
  Stack,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ListData from '../table-elements/list-data.component';
import { ACTIONS } from '../../config/const.config';
import ConfirmPopup from '../common/confirm-popup.component';
import {
  TEACHER_LISTING_ACTIONS,
  VIEW_TEACHERS_COLUMNS,
} from '../../config/module-configs/categories.config';
import Subscribers from '../form-elements/subscribers.component';
import { SUBSCRIBER_ROLE } from '../../config/roles.config';
import {
  createNewAssignedTeacher,
  deleteAssignedTeacherById,
  getListAssignedTeacherById,
} from '../../services/content.service';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ManageTeachers = ({ title, dataId, onCancel, categoryId }) => {
  const {
    control,
    formState: { errors },
    unregister,
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: null,
    },
  });

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
  });

  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });

  const actions = TEACHER_LISTING_ACTIONS;
  const columns = VIEW_TEACHERS_COLUMNS;
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };

  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    deleteAssignedTeacherById(doAction.data.id)
      .then(() => {
        setLoading(true);
        unregister('email');
        handleActionSuccess('Assigned Teacher deleted successfully.');
      })
      .catch((e) => {
        const message =
          e.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const saveNewData = (payload) => {
    createNewAssignedTeacher(payload)
      .then((res) => {
        if (res.data.success) {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: 'Teacher assigned successfully.',
          });
          setLoading(true);
          handleRefreshData();
          unregister('email');
          setDoAction({
            data: null,
            action: null,
          });
          setFormSubmitted(false);
        }
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const payload = {
      categoryId: !categoryId ? dataId : categoryId,
      teacherId: data.email.id,
      transmissionOrganId: categoryId ? dataId : null,
    };
    saveNewData(payload);
  };

  useEffect(() => {
    let params = `?page=${options.page + 1}`;
    params += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      params += `&sortBy=${options.sortBy}`;
      params += `&sortOrder=${options.sortOrder}`;
    }
    if (categoryId) {
      params += `&transmissionOrganId=${dataId}`;
    }

    getListAssignedTeacherById(!categoryId ? dataId : categoryId, params)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
        setLoading(false);
      })
      .catch((err) => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId, options.reloadCounter, categoryId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      onClose={() => onCancel()}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        id="view-dialog-title"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <Stack direction="row" sx={{ py: 2, display: 'flex' }}>
        <DialogContent id="manage-teachers-dialog" sx={{ overflow: 'visible' }}>
          {loading && <Loading />}

          {!loading && (
            <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6}>
                  <Controller
                    name="email"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Subscribers
                        id="email"
                        name="email"
                        label="Select Teacher"
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.email?.message || ''}
                        moderator
                        role={SUBSCRIBER_ROLE.TEACHER.value}
                        categoryId={!categoryId ? dataId : categoryId}
                        transmissionOrganId={categoryId ? dataId : null}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'start', paddingRight: 2 }}>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="ic:round-add" />}
            disabled={loading || formSubmitted}
          >
            Assign
          </Button>
        </DialogActions>
      </Stack>

      <DialogContent dividers>
        <Box fontWeight="bold">Teachers List</Box>

        {!loading && (
          <ListData
            columns={columns}
            rows={options.rows}
            page={options.page}
            rowsPerPage={options.rowsPerPage}
            totalRows={options.totalRows}
            loading={options.loading}
            actions={actions}
            error={options.error}
            sortBy={options.sortBy}
            sortOrder={options.sortOrder}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSortChange={handleSortingChange}
            onAction={handleActionSelect}
          />
        )}
        {doAction.action === ACTIONS.DELETE.value && (
          <ConfirmPopup
            title="Delete Assigned Teacher"
            message="Do you want to delete this? You can not undo this action!"
            onCancel={handleActionCancel}
            onSuccess={handleDeleteData}
          />
        )}
      </DialogContent>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

ManageTeachers.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  categoryId: PropTypes.number,
};

ManageTeachers.defaultProps = {
  categoryId: null,
};

export default ManageTeachers;
