/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
  RadioGroup,
  Radio,
} from '@mui/material';
import { OTHER_PROGRAM } from '../../config/const.config';

const OtherProgramType = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  validOptions,
  selectedOtherProgram,
  handleOtherProgramChange,
  sx,
  error,
  disabled,
}) => {
  const handleCheckboxChange = (value) => {
    if (selectedOtherProgram.includes(value)) {
      handleOtherProgramChange([]);
    } else {
      handleOtherProgramChange([value]);
    }
  };
  return (
    <FormControl fullWidth error={error !== ''}>
      <Typography>{label}:</Typography>
      <div style={{ display: 'flex' }}>
        <RadioGroup sx={{ flexDirection: 'row' }}>
          {validOptions.map((k) => (
            <FormControlLabel
              key={k}
              control={
                <Radio
                  checked={selectedOtherProgram.includes(k)}
                  onChange={() => handleOtherProgramChange(k)}
                />
              }
              label={OTHER_PROGRAM[k]}
            />
          ))}
        </RadioGroup>
      </div>

      {error !== '' && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

OtherProgramType.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  validOptions: PropTypes.array.isRequired,
  selectedOtherProgram: PropTypes.array,
  handleOtherProgramChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  disabled: PropTypes.number,
};
OtherProgramType.defaultProps = {
  selectedOtherProgram: [],

  sx: {},
  error: '',
  disabled: null,
};

export default OtherProgramType;
