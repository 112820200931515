import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Subscriptions module configurations
export const LISTING_COLUMNS = [
  {
    id: 'profilePic',
    label: '',
    width: '2%',
    align: 'center',
    dataKey: 'profilePic',
    sortable: false,
    type: CELL_DATA_TYPES.PROFILE,
  },
  {
    id: 'email',
    label: 'Subscriber Email',
    width: '12%',
    align: 'left',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'giftedBy',
    label: 'Gifted By',
    width: '15%',
    align: 'left',
    dataKey: 'giftedBy',
    sortable: false,
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'contentType',
    label: 'Content Type',
    width: '12%',
    align: 'left',
    dataKey: 'contentType',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'contentTitle',
    label: 'Content Title',
    width: '15%',
    align: 'left',
    dataKey: 'contentTitle',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'type',
    label: 'Type',
    width: '5%',
    align: 'left',
    dataKey: 'type',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'subscriptionStartDate',
    label: 'Start Date',
    width: '15%',
    align: 'left',
    dataKey: 'subscriptionStartDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'subscriptionEndDate',
    label: 'End Date',
    width: '15%',
    align: 'left',
    dataKey: 'subscriptionEndDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'subscriptionAmount',
    label: 'Amount',
    width: '5%',
    align: 'right',
    dataKey: 'subscriptionAmount',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'paymentType',
    label: 'Payment Type',
    width: '7%',
    align: 'center',
    dataKey: 'paymentType',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '5%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'playProgress',
    label: 'Play Progress %',
    width: '5%',
    align: 'center',
    dataKey: 'playProgress',
    sortable: false,
    type: CELL_DATA_TYPES.PROGRESS,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.VIEW_TRANSACTION.value,
    label: ACTIONS.VIEW_TRANSACTION.label,
  },
  {
    action: ACTIONS.MANAGE_NOTES.value,
    label: ACTIONS.MANAGE_NOTES.label,
  },
];

export const VIEW_SUBSCRIPTION_COLUMNS = [
  {
    id: 'purchaseFor',
    label: 'Purchase For (Profile Name)',
    dataKey: 'purchaseFor',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'contentTitle',
    label: 'Content Title',
    dataKey: 'contentTitle',
    type: CELL_DATA_TYPES.TEXT,
    conditional: (val) => val.type === 'TVOD',
  },
  {
    id: 'contentType',
    label: 'Content Type',
    dataKey: 'contentType',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'type',
    label: 'Type',
    dataKey: 'type',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'platform',
    label: 'Platform',
    dataKey: 'platform',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'subscriptionStartDate',
    label: 'Start Date',
    dataKey: 'subscriptionStartDate',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'subscriptionEndDate',
    label: 'End Date',
    dataKey: 'subscriptionEndDate',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'giftedBy',
    label: 'Gifted By',
    dataKey: 'giftedBy',
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'totalAmount',
    label: 'Total Amount',
    dataKey: 'totalAmount',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'tax',
    label: 'Tax',
    dataKey: 'tax',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'subscriptionAmount',
    label: 'Subscription Amount',
    dataKey: 'subscriptionAmount',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'paymentType',
    label: 'Payment Type',
    dataKey: 'paymentType',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'playProgress',
    label: 'Play Progress %',
    dataKey: 'playProgress',
    type: CELL_DATA_TYPES.PROGRESS,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'notes',
    label: 'Notes',
    dataKey: 'notes',
    type: CELL_DATA_TYPES.TEXT,
  },
];

export const VIEW_SUBSCRIPTION_PAYMENT_COLUMNS = [
  {
    id: 'totalPaymentAmount',
    label: 'Total Payment Amount',
    dataKey: 'totalPaymentAmount',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'couponCode',
    label: 'Coupon Code',
    dataKey: 'couponCode',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'discountAmount',
    label: 'Discount',
    dataKey: 'discountAmount',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'totalTax',
    label: 'Total Tax',
    dataKey: 'totalTax',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'amountPaid',
    label: 'Amount Paid',
    dataKey: 'amountPaid',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'invoiceId',
    label: 'Invoice Id',
    dataKey: 'invoiceId',
    type: CELL_DATA_TYPES.TEXT,
  },
];
