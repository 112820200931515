import CELL_DATA_TYPES from '../cell-types.config';

const LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Title',
    width: '20%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'Username',
    label: 'Subscribers Name',
    width: '20%',
    align: 'left',
    dataKey: 'User Name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'type',
    label: 'Type',
    width: '15%',
    sortable: true,
    dataKey: 'subscriptionType',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'Amount',
    label: 'Amount',
    width: '15%',
    align: 'left',
    dataKey: 'amount',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'startDate',
    label: 'Start Date',
    width: '15%',
    align: 'left',
    dataKey: 'startDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'endDate',
    label: 'End Date',
    width: '15%',
    align: 'left',
    dataKey: 'endDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export default LISTING_COLUMNS;
