import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListTransmissionProgramRequest from '../../components/content/list-transmission-program-request.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentTransmissionProgramRequestView = () => (
  <AppLayout
    pageTitle={PAGE_TITLE_LIST.SHOW_CONTENT_TRANSMISSION_PROGRAM_REQUEST}
  >
    <ListTransmissionProgramRequest />
  </AppLayout>
);

ContentTransmissionProgramRequestView.propTypes = {};

export default ContentTransmissionProgramRequestView;
