/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const AudioPlayer = ({ audioUrl, autoplay }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (autoplay) {
      audio.play();
    }

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [audioUrl, autoplay]);

  return (
    <audio ref={audioRef} controls style={{ width: '100%' }}>
      <source src={audioUrl} type="audio/mp3" />
      Your browser does not support the audio element.
    </audio>
  );
};

AudioPlayer.propTypes = {
  audioUrl: PropTypes.string.isRequired,
  autoplay: PropTypes.bool.isRequired,
};

export default AudioPlayer;
