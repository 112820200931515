/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const NoData = ({ isLiveEventChart }) =>
  isLiveEventChart ? (
    <Typography
      variant="body2"
      sx={{
        my: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'full',
      }}
    >
      No registration available
    </Typography>
  ) : (
    <Typography variant="body2" sx={{ my: 20 }}>
      No data found
    </Typography>
  );
NoData.propTypes = {
  isLiveEventChart: PropTypes.bool,
};
export default NoData;
