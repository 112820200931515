/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
  RadioGroup,
  Radio,
} from '@mui/material';
import { PROGRAM } from '../../config/const.config';

const programType = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  validOptions,
  selectedProgram,
  handleProgramChange,
  sx,
  error,
  disabled,
}) => {
  const handleCheckboxChange = (value) => {
    if (selectedProgram.includes(value)) {
      handleProgramChange([]);
    } else {
      handleProgramChange([value]);
    }
  };

  return (
    <FormControl fullWidth error={error !== ''}>
      <Typography>{label}:</Typography>
      <div style={{ display: 'flex' }}>
        <RadioGroup sx={{ flexDirection: 'row' }}>
          {validOptions.map((k) => (
            <FormControlLabel
              key={k}
              control={
                <Radio
                  checked={selectedProgram.includes(k)}
                  onChange={() => handleProgramChange(k)}
                />
              }
              label={PROGRAM[k]}
            />
          ))}
        </RadioGroup>
      </div>

      {error !== '' && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
programType.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  validOptions: PropTypes.array.isRequired,
  selectedWeeks: PropTypes.array,
  handleWeekChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  disabled: PropTypes.number,
};
programType.defaultProps = {
  selectedWeeks: [],

  sx: {},
  error: '',
  disabled: null,
};

export default programType;
