import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { VALID_USER_ROLES } from '../../config/platforms.config';
// import { EVENT_TYPE } from '../../config/const.config';

const UserRoleType = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  sx,
  error,
  disabled,
}) => (
  <FormControl fullWidth error={error !== ''}>
    <InputLabel id={`field-${id}-select-label`}>{label}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id={id}
      name={name}
      value={defaultValue}
      label={label}
      onChange={onChange}
      sx={sx}
      disabled={disabled}
    >
      {Object.entries(VALID_USER_ROLES)
        .slice(1)
        .map((k) => (
          <MenuItem key={`${id}-v-${k[1]}`} value={k[1]}>
            {k[1]}
          </MenuItem>
        ))}
    </Select>
    {error !== '' && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

UserRoleType.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  disabled: PropTypes.number,
};
UserRoleType.defaultProps = {
  sx: {},
  error: '',
  disabled: null,
};

export default UserRoleType;
