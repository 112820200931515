import React from 'react';
import PropTypes from 'prop-types';
import AppLayout from '../../layout/app.layout';
import ListSequence from '../../components/content/list-sequence.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import { CATEGORY_TYPE, CONTENT_ALIAS } from '../../config/const.config';

const ContentSequenceView = ({ type }) => {
  let pageTitle;

  if (type === CATEGORY_TYPE[0].id) {
    pageTitle = PAGE_TITLE_LIST.CONTENT_SEQUENCE_COURSE;
  } else if (type === CATEGORY_TYPE[1].id) {
    pageTitle = PAGE_TITLE_LIST.CONTENT_SEQUENCE_PROGRAM;
  } else if (type === CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL) {
    pageTitle = PAGE_TITLE_LIST.CONTENT_SEQUENCE_ARTICLE_CONTENT;
  } else if (type === CONTENT_ALIAS.LINK_SECTION) {
    pageTitle = PAGE_TITLE_LIST.CONTENT_LINK_SECTIONS;
  }
  return (
    <AppLayout pageTitle={pageTitle}>
      <ListSequence type={type} />
    </AppLayout>
  );
};

ContentSequenceView.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ContentSequenceView;
