/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Grid, Card, CardHeader, Box, IconButton } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getDate,
  formatDate,
  formatTimestamp,
} from '../../utils/datetime.util';
import { getLiveEventReportData } from '../../services/reports.service';
import Datepicker from '../form-elements/datepicker.component';
import Iconify from '../common/iconify.component';
import ListData from '../table-elements/list-data.component';
import LISTING_COLUMNS from '../../config/module-configs/live-event-report-data.config';
import ROUTE_PATH from '../../config/routes.config';
import NoData from '../table-elements/no-data.component';

const LiveEventReportChart = ({ isDashboard }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getInitialDates = () => {
    if (isDashboard) {
      return {
        startDate: getDate(-7),
        endDate: getDate(7),
      };
    }
    const params = new URLSearchParams(location.search);
    const urlStartDate = params.get('startDate');
    const urlEndDate = params.get('endDate');

    return {
      startDate: urlStartDate ? new Date(urlStartDate) : getDate(-7),
      endDate: urlEndDate ? new Date(urlEndDate) : getDate(0),
    };
  };

  const [filters, setFilters] = useState({
    ...getInitialDates(),
    reloadCounter: 0,
  });

  const updateURLParams = (startDate, endDate) => {
    if (isDashboard) return;
    const params = new URLSearchParams(location.search);
    params.set('startDate', formatDate(startDate, 'YYYY-MM-DD'));
    params.set('endDate', formatDate(endDate, 'YYYY-MM-DD'));
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  const columns = LISTING_COLUMNS;
  const actions = [];

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    unfilterRows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });

  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
          tools: {
            zoom: false,
            zoomin: false,
            zoomout: false,
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: { position: 'bottom', offsetX: -10, offsetY: 0 },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          borderRadiusApplication: 'end',
          borderRadiusWhenStacked: 'last',
          dataLabels: {
            total: {
              enabled: true,
              style: { fontSize: '13px', fontWeight: 900 },
            },
          },
        },
      },
      xaxis: {
        type: 'category',
        categories: [],
      },
      legend: {
        position: 'right',
        offsetY: 40,
      },
      fill: {
        opacity: 0.8,
      },
      tooltip: {
        y: {
          formatter: (value, { dataPointIndex, w }) => {
            const event = w.config.chartData[dataPointIndex];
            if (!event) return value;
            const { eventName } = event;
            return `${value} - ${eventName}`;
          },
        },
      },
    },
  });
  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
    });
  };

  const handleSortingChange = (sortBy, sortOrder) => {
    if (!sortBy || !sortOrder) {
      setOptions((prev) => ({
        ...prev,
        rows: options.unfilterRows,
        sortBy,
        sortOrder,
      }));
      return;
    }

    const order = sortOrder || 'asc';

    const { page, rowsPerPage, rows } = options;
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const currentPageRows = rows.slice(startIndex, endIndex);

    const sortedPageRows = [...currentPageRows].sort((a, b) => {
      // eslint-disable-next-line prefer-const
      let aValue = a[sortBy];
      // eslint-disable-next-line prefer-const
      let bValue = b[sortBy];

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return order === 'asc' ? bValue - aValue : aValue - bValue;
      }

      if (!isNaN(Date.parse(aValue)) && !isNaN(Date.parse(bValue))) {
        return order === 'asc'
          ? new Date(aValue) - new Date(bValue)
          : new Date(bValue) - new Date(aValue);
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return order === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return 0;
    });

    const updatedRows = [...rows];
    updatedRows.splice(startIndex, sortedPageRows.length, ...sortedPageRows);

    setOptions((prev) => ({
      ...prev,
      rows: updatedRows,
      sortBy,
      sortOrder,
    }));
  };

  const handleClickEvent = (data) => {
    if (isDashboard) {
      navigate(ROUTE_PATH.REPORTS_LIVE_EVENT);
      return;
    }
    const dynamicPath = ROUTE_PATH.CONTENT_VIDEOS_EVENT_REGISTRATIONS.replace(
      ':id',
      data.id
    );
    const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
    const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');
    const dynamicPathWithDate = `${dynamicPath}?startDate=${startDate}&endDate=${endDate}`;
    navigate(dynamicPathWithDate);
  };

  const handleStartDateChange = (v) => {
    setFilters({
      ...filters,
      startDate: v,
      reloadCounter: filters.reloadCounter + 1,
    });
  };
  const handleEndDateChange = (v) => {
    setFilters({
      ...filters,
      endDate: v,
      reloadCounter: filters.reloadCounter + 1,
    });
  };
  const handleRefreshData = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      reloadCounter: prevFilters.reloadCounter + 1,
    }));
    setOptions((prevOptions) => ({
      ...prevOptions,
      loading: true,
    }));
  };

  useEffect(() => {
    updateURLParams(filters.startDate, filters.endDate);
    const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
    const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');
    const eventPayload = {
      startDate,
      endDate,
      page: options.page + 1,
      perPage: options.rowsPerPage,
    };

    if (options.sortBy && options.sortOrder) {
      eventPayload.sortBy = options.sortBy;
      eventPayload.sortOrder = options.sortOrder;
    }

    getLiveEventReportData(eventPayload)
      .then((res) => {
        if (res) {
          setOptions((prev) => ({
            ...prev,
            loading: false,
            totalRows: res?.data?.totalRows || 0,
            rows: res?.data?.rows || [],
            unfilterRows: res?.data?.rows || [],
            error: false,
          }));

          const filteredRows = res.data.rows.filter(
            (row) => row.totalRegistrations > 0 || row.totalJoined > 0
          );

          const categories = filteredRows.map((row) =>
            formatTimestamp(row.eventDate, 'DD-MM-YYYY hh:mm A')
          );

          const attendedData = filteredRows.map((row) => row.totalJoined);
          const notAttendedData = filteredRows.map(
            (row) => row.totalRegistrations - row.totalJoined
          );

          setChartData((prev) => ({
            ...prev,
            series: [
              { name: 'Attended', data: attendedData },
              { name: 'Not Attended', data: notAttendedData },
            ],
            options: {
              ...prev.options,
              xaxis: { ...prev.options.xaxis, categories },
              chartData: filteredRows,
              tooltip: {
                x: {
                  formatter: (value, { dataPointIndex, w }) => {
                    const event = w.config.chartData[dataPointIndex];
                    if (!event) return value;

                    const tempSpan = document.createElement('span');
                    tempSpan.style.fontSize = '15px';
                    tempSpan.style.visibility = 'hidden';
                    tempSpan.style.whiteSpace = 'nowrap';
                    tempSpan.innerText = event.eventName;
                    document.body.appendChild(tempSpan);
                    const textWidth = tempSpan.offsetWidth;
                    document.body.removeChild(tempSpan);

                    const marqueeTag =
                      textWidth > 200
                        ? `<marquee behavior="scroll" direction="left" scrollamount="5" style="width: 200px; white-space: nowrap; overflow: hidden; display: block;">
                            <strong style="font-size: 15px; text-align: center; display: inline-block;">${event.eventName}</strong>
                         </marquee>`
                        : `<strong style="font-size: 15px; text-align: center; display: inline-block;">${event.eventName}</strong>`;

                    return `
                      <div style="text-align: center; font-size: 14px">
                        ${formatTimestamp(
                          event.eventDate,
                          'DD-MM-YYYY hh:mm A'
                        )}
                      </div>
                      ${marqueeTag}
                    `;
                  },
                },
                y: {
                  formatter: (value) => `${value}`,
                },
              },
            },
          }));
        }
      })
      .catch(() => {
        setOptions((prev) => ({
          ...prev,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          unfilterRows: [],
          error: true,
        }));
      });
  }, [filters.reloadCounter, options.reloadCounter]);

  const isDataEmpty = (series) =>
    series.every(
      (s) => s.data.length === 0 || s.data.every((val) => val === 0)
    );

  return (
    <Card>
      {isDashboard && (
        <Grid
          sx={{
            mt: 1,
            mr: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            width: 'full',
            cursor: 'pointer',
            color: 'primary.main',
            '&:hover': {
              color: 'primary.dark',
            },
          }}
          onClick={handleClickEvent}
        >
          <Grid sx={{ borderBottom: '1px solid' }}>View Details</Grid>
        </Grid>
      )}
      <CardHeader
        title="Live Event Analytics"
        subheader=""
        action={
          <IconButton title="Refresh" onClick={handleRefreshData}>
            <Iconify icon="ic:twotone-refresh" />
          </IconButton>
        }
      />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="right" spacing={2}>
              <Datepicker
                id="dr_start_date"
                name="dr_start_date"
                label="Start Date"
                defaultValue={filters.startDate}
                onChange={handleStartDateChange}
                sx={{ width: 200 }}
              />
              <Datepicker
                id="dr_end_date"
                name="dr_end_date"
                label="End Date"
                defaultValue={filters.endDate}
                onChange={handleEndDateChange}
                sx={{ width: 200 }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            {options.loading && (
              <div style={{ textAlign: 'center' }}>Loading...</div>
            )}

            {!options.loading &&
              (!chartData.series.length || isDataEmpty(chartData.series)) && (
                <NoData isLiveEventChart />
              )}

            {!options.loading &&
              chartData.series.length &&
              !isDataEmpty(chartData.series) && (
                <ReactApexChart
                  options={chartData.options}
                  series={chartData.series}
                  type="bar"
                  height={350}
                />
              )}
          </Grid>
        </Grid>
      </Box>
      <Card>
        {!options.loading && !isDashboard && (
          <ListData
            columns={columns}
            rows={
              options.rows.slice(
                options.page * options.rowsPerPage,
                (options.page + 1) * options.rowsPerPage
              ) || []
            }
            page={options.page}
            rowsPerPage={options.rowsPerPage}
            totalRows={options.totalRows}
            loading={options.loading}
            actions={actions}
            error={options.error}
            sortBy={options.sortBy}
            sortOrder={options.sortOrder}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSortChange={handleSortingChange}
            onCustomClick={handleClickEvent}
          />
        )}
      </Card>
    </Card>
  );
};
LiveEventReportChart.propTypes = {
  isDashboard: PropTypes.bool.isRequired,
};
export default LiveEventReportChart;
