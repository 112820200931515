/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Container,
  Paper,
  Stack,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  getListSequence,
  updateChannelCategoryVideosSequence,
  updateCategorySequence,
  updateArticleContentSequence,
  updateLinkSectionSequence,
  updateListLinkSectionItemSequence,
} from '../../services/content.service';
import Iconify from '../common/iconify.component';
import {
  ARTICLE_TYPE,
  CATEGORY_TYPE,
  CONTENT_ALIAS,
} from '../../config/const.config';
import ListSequenceVideos from './list-sequence-videos.component';
import Loading from '../table-elements/loading.component';
import { AccessContext } from '../../context/access.context';
import useAccess from '../../hooks/access.hook';

const ListSequence = ({ type }) => {
  const { accessData } = useContext(AccessContext);
  const { hasOperationAccess } = useAccess();
  const [categoryList, setCategoryList] = useState([]);
  const [categoryVideosList, setCategoryVideosList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    id: null,
    categoryName: '',
  });
  const [catOptions, setCatOptions] = useState({
    error: false,
    loading: true,
  });
  const [vidOptions, setVidOptions] = useState({
    error: false,
    loading: true,
  });
  const [action, setAction] = useState({
    show: false,
    data: '',
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const dropDisabled = true;
  const handleOnDragEndCategories = (result) => {
    if (result.destination) {
      const newCategories = Array.from(categoryList);
      const [draggedItem] = newCategories.splice(result.source.index, 1);

      newCategories.splice(result.destination.index, 0, draggedItem);
      setCategoryList(newCategories);
    }
  };

  const handleOnDragEndVideos = (result) => {
    if (result.destination) {
      const newVideos = Array.from(categoryVideosList);
      const [draggedItem] = newVideos.splice(result.source.index, 1);

      newVideos.splice(result.destination.index, 0, draggedItem);
      setCategoryVideosList(newVideos);
    }
  };

  const handleCloseDialog = () => {
    setAction({ ...action, show: false, data: '' });
    setVidOptions({ ...vidOptions, loading: true });
  };

  const handleUpdateCategorySequence = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const updatedCategorySequence = categoryList.map((category) => category.id);
    const payload = {
      newSequence: updatedCategorySequence,
    };
    if (type === 'Link Section') {
      updateLinkSectionSequence(payload)
        .then((res) => {
          if (res?.data?.success) {
            setSnackbarInfo({
              ...snackbarInfo,
              show: true,
              type: 'success',
              message: `Link Sections sequence updated successfully.`,
            });
          }
        })
        .catch(() => {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message: 'Something went wrong.',
          });
        });
    } else {
      updateCategorySequence(payload)
        .then((res) => {
          if (res?.data?.success) {
            setSnackbarInfo({
              ...snackbarInfo,
              show: true,
              type: 'success',
              message: `${CONTENT_ALIAS.CATEGORY_PLURAL} sequence updated successfully.`,
            });
          }
        })
        .catch(() => {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message: 'Something went wrong.',
          });
        });
    }
  };
  const handleUpdateVideosSequence = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    let updatedVideosCategorySequence;
    let payload;

    if (type === CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL) {
      updatedVideosCategorySequence = categoryVideosList.map(
        (video) => video.id
      );
    } else if (type === 'Link Section') {
      updatedVideosCategorySequence = categoryVideosList.map(
        (video) => video.id
      );
    } else {
      updatedVideosCategorySequence = categoryVideosList.map(
        (video) => video.video_id
      );
    }

    if (type === CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL) {
      payload = {
        type:
          selectedCategory.categoryName === ARTICLE_TYPE[0].label
            ? ARTICLE_TYPE[0].value
            : ARTICLE_TYPE[1].value,
        newSequence: updatedVideosCategorySequence,
      };
    } else if (type === 'Link Section') {
      payload = {
        sectionID: selectedCategory.id,
        newSequence: updatedVideosCategorySequence,
      };
    } else {
      payload = {
        channelID: categoryVideosList[0].channel_id,
        categoryID: selectedCategory.id,
        newSequence: updatedVideosCategorySequence,
      };
    }
    if (type === CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL) {
      updateArticleContentSequence(payload)
        .then((res) => {
          if (res?.data?.success) {
            setSnackbarInfo({
              ...snackbarInfo,
              show: true,
              type: 'success',
              message: `${CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL} sequence updated successfully.`,
            });
            handleCloseDialog();
          }
        })
        .catch(() => {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message: 'Something went wrong.',
          });
        });
    } else if (type === 'Link Section') {
      updateListLinkSectionItemSequence(payload)
        .then((res) => {
          if (res?.data?.success) {
            setSnackbarInfo({
              ...snackbarInfo,
              show: true,
              type: 'success',
              message: `link section items sequence updated successfully.`,
            });
            handleCloseDialog();
          }
        })
        .catch(() => {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message: 'Something went wrong.',
          });
        });
    } else {
      updateChannelCategoryVideosSequence(payload)
        .then((res) => {
          if (res?.data?.success) {
            setSnackbarInfo({
              ...snackbarInfo,
              show: true,
              type: 'success',
              message: `${
                type === CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL
                  ? CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL
                  : CONTENT_ALIAS.VIDEO_PLURAL
              } sequence updated successfully.`,
            });
            handleCloseDialog();
          }
        })
        .catch(() => {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message: 'Something went wrong.',
          });
        });
    }
  };

  const handleVideoReordering = (category) => {
    setAction({ ...action, show: true, data: category.id });
    setSelectedCategory({
      ...selectedCategory,
      id: category.id,
      categoryName: category.name,
    });
    let uri;

    if (category.name === ARTICLE_TYPE[0].label) {
      uri = 'article-content';
      uri += `?type=${ARTICLE_TYPE[0].value}`;
    } else if (category.name === ARTICLE_TYPE[1].label) {
      uri = 'article-content';
      uri += `?type=${ARTICLE_TYPE[1].value}`;
    } else if (type === 'Link Section') {
      uri = 'link-section-item';
      uri += `?sectionID=${category.id}`;
    } else {
      uri = 'video-category';
      uri += `?categoryID=${category.id}`;
    }

    getListSequence(uri)
      .then((res) => {
        setVidOptions({
          ...vidOptions,
          loading: false,
          error: false,
        });
        setCategoryVideosList(res.data);
      })
      .catch(() =>
        setVidOptions({
          ...vidOptions,
          loading: false,
          error: true,
        })
      );
  };

  const getSequenceData = () => {
    let uri = '';
    if (type === 'PROGRAM' || type === 'COURSE') {
      uri = 'category';
    } else if (type === 'Link Section') {
      uri = 'link-section';
    } else {
      uri = 'video-category';
    }
    uri += `?type=${type}`;

    getListSequence(uri)
      .then((res) => {
        setCatOptions({
          ...catOptions,
          loading: false,
          error: false,
        });
        setCategoryList(res.data);
      })
      .catch(() => {
        setCatOptions({
          ...catOptions,
          loading: false,
          error: true,
        });
        setCategoryList([]);
      });
  };

  const handleDropDisable = () => {
    if (type === CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL) {
      return dropDisabled;
    }
    if (
      type === CATEGORY_TYPE[0].id &&
      !hasOperationAccess(accessData.role, 'sequence_courses', 'edit')
    ) {
      return dropDisabled;
    }
    if (
      type === CATEGORY_TYPE[1].id &&
      !hasOperationAccess(accessData.role, 'sequence_programs', 'edit')
    ) {
      return dropDisabled;
    }
    if (
      type === CONTENT_ALIAS.LINK_SECTION &&
      !hasOperationAccess(accessData.role, 'sequence_link_section', 'edit')
    ) {
      return dropDisabled;
    }
    return !dropDisabled;
  };

  useEffect(() => {
    if (type === CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL) {
      setCategoryList([
        {
          id: 1,
          name: ARTICLE_TYPE[0].label,
        },
        {
          id: 2,
          name: ARTICLE_TYPE[1].label,
        },
      ]);
      setCatOptions({
        ...catOptions,
        loading: false,
        error: false,
      });
    } else {
      getSequenceData();
    }
  }, [type]);

  return (
    <>
      {catOptions.loading && <Loading />}

      {catOptions.channelIDError && <Paper>Something went wrong.</Paper>}
      {!catOptions.channelIDError && !catOptions.loading && (
        <>
          {!handleDropDisable() && (
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ px: 3, paddingBottom: 2 }}
            >
              <div>&nbsp;</div>
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<Iconify icon="prime:save" />}
                  onClick={() => handleUpdateCategorySequence()}
                  disabled={
                    categoryList.length === 0 || categoryList.length === 1
                  }
                >
                  Save
                </Button>
              </div>
            </Stack>
          )}

          <Container
            maxWidth="false"
            sx={{ maxHeight: '74vh', overflowY: 'auto', paddingX: 0 }}
          >
            <DragDropContext onDragEnd={handleOnDragEndCategories}>
              <Droppable
                droppableId="categories"
                isDropDisabled={handleDropDisable()}
              >
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {!catOptions.loading &&
                      categoryList.length > 0 &&
                      categoryList.map((category, index) =>
                        !handleDropDisable() ? (
                          <Draggable
                            key={`category-${category.id}`}
                            draggableId={category.id.toString()}
                            index={index}
                          >
                            {(provide) => (
                              <Card
                                sx={{
                                  marginBottom: '5px',
                                }}
                                ref={provide.innerRef}
                                {...provide.draggableProps}
                                {...provide.dragHandleProps}
                              >
                                <CardContent
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingY: '12px',
                                    '&:last-child': {
                                      paddingBottom: '12px',
                                    },
                                  }}
                                >
                                  <div style={{ width: '75%' }}>
                                    {category.name}
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Button
                                      style={{
                                        marginLeft: 10,
                                        marginRight: 10,
                                      }}
                                      color="inherit"
                                      onClick={() =>
                                        handleVideoReordering(category)
                                      }
                                    >
                                      {type === 'Link Section'
                                        ? 'Reorder Link Section Items Sequence'
                                        : `Reorder ${CONTENT_ALIAS.VIDEO_PLURAL} Sequence`}
                                    </Button>
                                    <Iconify icon="ic:round-table-rows" />
                                  </div>
                                </CardContent>
                              </Card>
                            )}
                          </Draggable>
                        ) : (
                          <Card
                            key={`category-${category.id}`}
                            sx={{
                              marginBottom: '5px',
                            }}
                          >
                            <CardContent
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingY: '12px',
                                '&:last-child': {
                                  paddingBottom: '12px',
                                },
                              }}
                            >
                              <div style={{ width: '75%' }}>
                                {category.name}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Button
                                  style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                  }}
                                  color="inherit"
                                  onClick={() =>
                                    handleVideoReordering(category)
                                  }
                                >
                                  {`Reorder ${CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL} Sequence`}
                                </Button>
                                <Iconify icon="ic:round-table-rows" />
                              </div>
                            </CardContent>
                          </Card>
                        )
                      )}

                    {!catOptions.loading && categoryList.length === 0 && (
                      <Paper
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '10vh',
                        }}
                      >
                        No data available.
                      </Paper>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              {action.show && (
                <ListSequenceVideos
                  handleOnDragEndVideos={handleOnDragEndVideos}
                  handleUpdateVideosSequence={handleUpdateVideosSequence}
                  action={action}
                  selectedCategory={selectedCategory}
                  categoryVideosList={categoryVideosList}
                  vidOptions={vidOptions}
                  handleCloseDialog={handleCloseDialog}
                  type={type}
                />
              )}
            </DragDropContext>
          </Container>
        </>
      )}
      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ListSequence.propTypes = { type: PropTypes.string.isRequired };

export default ListSequence;
