import React from 'react';

import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListSectionItems from '../../components/content/list-link-section-items.component';

const ListSectionItemsView = () => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_LINK_SECTION_ITEMS}`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListSectionItems />
    </AppLayout>
  );
};

export default ListSectionItemsView;
