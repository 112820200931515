import axios from 'axios';
import { getDeviceId } from '../utils/device-info.util';
import { getLocalValue } from '../utils/local-cache.util';
import { LS_ADMIN_USER_AUTH_TOKEN } from '../config/const.config';

const prepareRequestOptions = (
  url,
  method,
  data = null,
  contentType = false
) => {
  const baseAPIUrl = process.env.REACT_APP_CMS_API_ENDPOINT;
  const uuid = getDeviceId();
  const adminAuth = getLocalValue(LS_ADMIN_USER_AUTH_TOKEN);

  const options = {
    url: `${baseAPIUrl}${url}`,
    method,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    headers: {
      'Content-Type': !contentType ? 'application/json' : 'multipart/form-data',
      'X-Req-Device': uuid,
      'X-Admin-Auth-Token': adminAuth,
    },
    data: !contentType ? JSON.stringify(data || {}) : data,
  };

  return options;
};

export const getDataList = async (type) => {
  const options = prepareRequestOptions(`/admin/list/${type}`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const getEventRegistrationByVideoId = async (type) => {
  const options = prepareRequestOptions(`/admin/${type}`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const getSearchListChannels = async () => {
  const options = prepareRequestOptions('/admin/channels/search-list', 'GET');

  const response = await axios(options);
  return response.data;
};

export const createNewSection = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/section/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewSectionById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/section/${id}/view/${type}`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const updateSectionById = async (id, payload, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/section/${id}/update/${type}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchListSections = async (type = '') => {
  const options = prepareRequestOptions(
    `/admin/section/search-list${type ? `?type=${type}` : ''}`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const getSearchListSectionsById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/section/${id}/search-list`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const deleteSectionById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/section/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

export const createNewCategory = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/category/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewCategoryById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/category/${id}/view/${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updateCategoryById = async (id, payload, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/category/${id}/update/${type}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchListCategories = async (type = '', categoryType = '') => {
  const options = prepareRequestOptions(
    `/admin/categories/search-list${type ? `?type=${type}` : ''}${
      categoryType ? `?categoryType=${categoryType}` : ''
    }`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const getSubscribedCategories = async (id) => {
  const options = prepareRequestOptions(
    `/admin/categories/${id}/subscribed`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchListTestimonialCategories = async () => {
  const options = prepareRequestOptions(
    '/admin/testimonials/category/search-list',
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchListTestimonialTabs = async () => {
  const options = prepareRequestOptions(
    '/admin/testimonials/tab/search-list',
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const createNewVideo = async (payload) => {
  const options = prepareRequestOptions('/admin/video/create', 'POST', payload);

  const response = await axios(options);
  return response.data;
};

export const viewVideoById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/view/${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const viewVideoAssetById = async (id, language) => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/assets/${language}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updateVideoById = async (id, payload, flag, type = 'orig') => {
  let url = `/admin/video/${id}/update/${type}`;
  if (flag) {
    url += '?videoType=LIVE_EVENT';
  }
  const options = prepareRequestOptions(url, 'POST', payload);

  const response = await axios(options);
  return response.data;
};

export const deleteVideoById = async (id) => {
  const options = prepareRequestOptions(`/admin/video/${id}/delete`, 'DELETE');

  const response = await axios(options);
  return response.data;
};

export const assignCategoryToVideo = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/category/assign`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchListVideos = async () => {
  const options = prepareRequestOptions('/admin/videos/search-list', 'GET');

  const response = await axios(options);
  return response.data;
};

export const syncVideoCDNStatus = async (params = '') => {
  let url = `/admin/video/videos/cdn/sync`;
  if (params !== '') {
    url += `?type=${params}`;
  }
  const options = prepareRequestOptions(url, 'GET');

  const response = await axios(options);
  return response.data;
};

export const getListVideosByType = async (videoType, params) => {
  const options = prepareRequestOptions(
    `/admin/video/${videoType}/list${params}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updateVideosSequence = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/videos/sequence/update`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getListSequence = async (query) => {
  const options = prepareRequestOptions(`/admin/sequence/list/${query}`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const updateChannelCategoryVideosSequence = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/sequence/video-category/update',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};
export const updateListLinkSectionItemSequence = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/sequence/link-section-item/update',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const updateArticleContentSequence = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/sequence/article-content/update',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const updateTabsSequence = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/sequence/tabs/update',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const createNewTab = async (payload) => {
  const options = prepareRequestOptions('/admin/tab/create', 'POST', payload);

  const response = await axios(options);
  return response.data;
};

export const viewTabById = async (id) => {
  const options = prepareRequestOptions(`/admin/tab/${id}/view`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const updateTabById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/tab/${id}/update`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteTabById = async (id) => {
  const options = prepareRequestOptions(`/admin/tab/${id}/delete`, 'DELETE');

  const response = await axios(options);
  return response.data;
};

export const getPlayableStream = async (uri) => {
  const options = prepareRequestOptions(
    `/admin/get/playable-stream?${uri}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const uploadAssetFile = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/upload/file/cdn`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const uploadVideoToBunnyCDN = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/upload/video/cdn`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const uploadImageToBunnyCDN = async (payload, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/upload/image/cdn/${type}`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const uploadSrtToBunnyCDN = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/upload/srt/cdn`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteSrtToBunnyCDN = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/delete/srt/cdn`,
    'DELETE',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewCaptionsById = async (videoId) => {
  const options = prepareRequestOptions(
    `/admin/list/captions/${videoId}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const toggleStatus = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/${payload.type}/status/toggle`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const createNewTestimonialTab = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/testimonials/tab/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewTestimonialTabById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/testimonials/tab/${id}/view/${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updateTestimonialTabById = async (id, payload, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/testimonials/tab/${id}/update/${type}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteTestimonialTabById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/testimonials/tab/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

// testimonial category
export const createNewTestimonialCategory = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/testimonials/category/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewTestimonialCategoryById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/testimonials/category/${id}/view/${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updateTestimonialCategoryById = async (
  id,
  payload,
  type = 'orig'
) => {
  const options = prepareRequestOptions(
    `/admin/testimonials/category/${id}/update/${type}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteTestimonialCategoryById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/testimonials/category/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

// text testimonial
export const createNewTestimonialText = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/testimonials/text/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewTestimonialTextById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/testimonials/text/${id}/view/${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const viewUserTestimonialTextById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/user/testimonials/text/${id}/view`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const viewUserTestimonialVideoById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/user/testimonials/video/${id}/view`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updateTestimonialTextById = async (id, payload, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/testimonials/text/${id}/update/${type}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const updateUserTestimonialTextById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/user/testimonials/text/${id}/update`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const updateUserTestimonialVideoById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/user/testimonials/video/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteTestimonialTextById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/testimonials/text/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

export const deleteUserTestimonialTextById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/user/testimonials/text/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

export const deleteUserTestimonialVideoById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/user/testimonials/video/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

export const createNewCommunityGathering = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/community-gathering/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewCommunityGatheringById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/community-gathering/${id}/view/${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updateCommunityGatheringById = async (
  id,
  payload,
  type = 'orig'
) => {
  const options = prepareRequestOptions(
    `/admin/community-gathering/${id}/update/${type}`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteCommunityGatheringById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/community-gathering/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

export const getCommentsByVideoId = async (params) => {
  const options = prepareRequestOptions(
    `/admin/videos/comments${params}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const checkNewCommentsByVideoId = async (videoId, commentId) => {
  const options = prepareRequestOptions(
    `/admin/videos/check-new-comments?videoId=${videoId}&commentId=${commentId}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const postCommentByVideoId = async (payload, params) => {
  const options = prepareRequestOptions(
    `/admin/videos/post-comment${params}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteCommentByVideoId = async (id, params) => {
  const options = prepareRequestOptions(
    `/admin/videos/delete-comment/${id}${params}`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

export const toggleFeatured = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/category/featured/toggle`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const updateCategorySequence = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/category/sequence/update`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};
export const updateLinkSectionSequence = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/sequence/link-section/update`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};
export const deleteCategoryById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/category/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

export const createNewGroup = async (payload) => {
  const options = prepareRequestOptions('/admin/group/create', 'POST', payload);

  const response = await axios(options);
  return response.data;
};

export const viewGroupById = async (id) => {
  const options = prepareRequestOptions(`/admin/group/${id}/view`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const updateGroupById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/group/${id}/update`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteGroupById = async (id) => {
  const options = prepareRequestOptions(`/admin/group/${id}/delete`, 'DELETE');

  const response = await axios(options);
  return response.data;
};

export const getSearchListGroups = async (asPerSubscription) => {
  const options = prepareRequestOptions(
    `/admin/group/search-list${
      asPerSubscription ? '?asPerSubscription=1' : ''
    }`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const getCountryList = async () => {
  const options = prepareRequestOptions(`/admin/country/search-list`, 'GET');
  const response = await axios(options);
  return response.data;
};

export const createNewGroupMember = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/group-member/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewGroupMemberById = async (conversationId, id) => {
  const options = prepareRequestOptions(
    `/admin/group-member/${conversationId}/view/${id}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updateGroupMemberById = async (conversationId, id, payload) => {
  const options = prepareRequestOptions(
    `/admin/group-member/${conversationId}/update/${id}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteGroupMemberById = async (conversationId, id) => {
  const options = prepareRequestOptions(
    `/admin/group-member/${conversationId}/delete/${id}`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

export const inviteNewGroupMember = async (conversationId, payload) => {
  const options = prepareRequestOptions(
    `/admin/group-member/${conversationId}/invite`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getPopularContentDataList = async (params) => {
  const options = prepareRequestOptions(
    `/admin/list/popularcontent/${params}`,
    'GET'
  );
  const response = await axios(options);

  return response.data;
};

export const createNewPopularContent = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/popularcontent/create',
    'POST',
    payload
  );
  const response = await axios(options);
  return response.data;
};

export const createNewPackage = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/package/create',
    'POST',
    payload
  );
  const response = await axios(options);

  return response.data;
};

export const viewPopularContentById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/popularcontent/${id}/view/${type}`,
    'GET'
  );

  const response = await axios(options);

  return response.data;
};

export const updatePopularContentById = async (id, payload, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/popularcontent/${id}/update/${type}`,
    'POST',
    payload
  );
  const response = await axios(options);
  return response.data;
};

export const deletePopularContentById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/popularcontent/${id}/delete`,
    'DELETE'
  );
  const response = await axios(options);
  return response.data;
};

export const getSearchableListContent = async (type, isPaid = null) => {
  const options = prepareRequestOptions(
    `/admin/content/search-list?type=${type}&isPaid=${isPaid}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const getSubscribedContentList = async (subscirberEmail) => {
  const options = prepareRequestOptions(
    `/admin/content/subscribed/list/${subscirberEmail}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updatePackageById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/package/${id}/update`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewPackageById = async (id) => {
  const options = prepareRequestOptions(`/admin/package/${id}/view`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const getSearchListPackages = async () => {
  const options = prepareRequestOptions('/admin/package/search-list', 'GET');

  const response = await axios(options);
  return response.data;
};

export const getPackageByContent = async (type, id, transmissionOrganId) => {
  const options = prepareRequestOptions(
    `/admin/package/content/${type}/${id}${
      transmissionOrganId ? `?transmissionOrgan=${transmissionOrganId}` : ''
    }`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const createNewArticleCategory = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/article-category/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteArticleCategoryById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/article-category/${id}/delete`,
    'DELETE'
  );
  const response = await axios(options);
  return response.data;
};

export const updateArticleCategoryById = async (id, payload, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/article-category/${id}/update/${type}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewArticleCategoryById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/article-category/${id}/view/${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const createNewArticleContent = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/article-content/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const updateArticleContentById = async (id, payload, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/article-content/${id}/update/${type}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewArticleContentById = async (
  id,
  languageCode = 'en',
  type = 'orig'
) => {
  const options = prepareRequestOptions(
    `/admin/article-content/${id}/view/${type}/${languageCode}`,
    'GET',
    languageCode
  );

  const response = await axios(options);
  return response.data;
};

export const deleteArticleContentById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/article-content/${id}/delete`,
    'DELETE'
  );
  const response = await axios(options);
  return response.data;
};

export const getArticleCategory = async (type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/article-category/article-category-list/${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};
export const getVimeoEventDetail = async (id) => {
  const options = prepareRequestOptions(
    `/admin/vimeo/event-details/${id}`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const getSVODPackagesList = async (contentType) => {
  const options = prepareRequestOptions(
    `/admin/svod-packages/list${
      contentType ? `?contentType=${contentType}` : ''
    }`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const viewGroupLangById = async (id) => {
  const options = prepareRequestOptions(`/admin/group/lang/${id}/view`, 'GET');
  const response = await axios(options);
  return response.data;
};

export const updateGroupLangById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/group/lang/${id}/update`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchListRecurringDatesByVideoId = async (id) => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/event/recurring-dates-list`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const getRecurringEventsList = async (type) => {
  const options = prepareRequestOptions(`/admin/${type}`, 'GET');
  const response = await axios(options);
  return response.data;
};
export const viewRecurringEventLangById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/recurring-event/${id}/view/lang`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const getRecurringEventById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/video/recurring-event/${id}`,
    'GET'
  );
  const response = await axios(options);

  return response.data;
};

export const deleteRecurringEventById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/video/recurring-event/${id}/delete`,
    'DELETE'
  );
  const response = await axios(options);
  return response.data;
};
export const createNewTransmissionOrgan = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/transmission-organ/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewTransmissionOrganById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/transmission-organ/${id}/view/${type}`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const updateTransmissionOrganById = async (
  id,
  payload,
  type = 'orig'
) => {
  const options = prepareRequestOptions(
    `/admin/transmission-organ/${id}/update/${type}`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getListTransmissionOrgans = async () => {
  const options = prepareRequestOptions(
    `/admin/list/transmission-organs`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const getListTransmissionProgramRequest = async (uri) => {
  const options = prepareRequestOptions(
    `/admin/list/transmission/program/request${uri}`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const viewTransmissionProgramRequestById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/transmission-request/${id}`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const updateTransmissionRequestById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/transmission-request/${id}/update`,
    'POST',
    payload
  );
  const response = await axios(options);
  return response.data;
};

export const getListTransmissionOrganByCategory = async (id) => {
  const options = prepareRequestOptions(
    `/admin/tramsmission-organs/${id}/category`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const createNewAssignedTeacher = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/assigned-teacher/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getListAssignedTeacherById = async (id, params) => {
  const options = prepareRequestOptions(
    `/admin/assigned-teachers/list/${id}${params}`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const deleteAssignedTeacherById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/assigned-teacher/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

export const createNewResource = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/resources/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getListResorce = async () => {
  const options = prepareRequestOptions(`/admin/list/resources`, 'GET');
  const response = await axios(options);

  return response.data;
};

export const viewResourcesContentById = async (id, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/resources/${id}/view/${type}`,
    'GET'
  );
  const response = await axios(options);

  return response.data;
};

export const updateResourceById = async (id, payload, type = 'orig') => {
  const options = prepareRequestOptions(
    `/admin/resources/${id}/update/${type}`,
    'POST',
    payload
  );
  const response = await axios(options);
  return response.data;
};

export const deleteDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/resources/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

export const viewRecordedMessageById = async (id, type) => {
  let url = `/admin/record-message/${id}`;
  if (type) {
    url += `?type=${type}`;
  }
  const options = prepareRequestOptions(url, 'GET');
  const response = await axios(options);

  return response.data;
};

export const deleteRecordedMessageById = async (id, type) => {
  let url = `/admin/record-message/${id}/delete`;
  if (type) {
    url += `?type=${type}`;
  }
  const options = prepareRequestOptions(url, 'DELETE');

  const response = await axios(options);
  return response.data;
};

export const updateRecordedMessageById = async (id, payload, type) => {
  let url = `/admin/record-message/${id}/update`;
  if (type) {
    url += `?type=${type}`;
  }
  const options = prepareRequestOptions(url, 'POST', payload);
  const response = await axios(options);
  return response.data;
};
export const getLinkSection = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/list/link-section',
    'GET',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const createNewLinkSection = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/link-section/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};
export const viewLinkSectionById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/link-section/${id}/view`,
    'GET'
  );
  const response = await axios(options);

  return response.data;
};

export const deleteLinkSectionById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/link-section/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

export const updateLinkSectionById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/link-section/${id}/update`,
    'POST',
    payload
  );
  const response = await axios(options);
  return response.data;
};
export const createNewLinkSectionItem = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/link-section-item/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};
export const viewLinkSectionItemById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/link-section-item/${id}/view`,
    'GET'
  );
  const response = await axios(options);

  return response.data;
};

export const deleteLinkSectionItemById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/link-section-item/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

export const updateLinkSectionItemById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/link-section-item/${id}/update`,
    'POST',
    payload
  );
  const response = await axios(options);
  return response.data;
};
