/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Card, Stack, Button } from '@mui/material';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/article-content.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import {
  deleteArticleContentById,
  getDataList,
  toggleStatus,
} from '../../services/content.service';
import { ACTIONS, CONTENT_ALIAS, LANGUAGES } from '../../config/const.config';
import ConfirmPopup from '../common/confirm-popup.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ImagePreview from './image-preview.component';
import AddEditArticleContent from './add-edit-article-content.component';
import TranslateArticleContent from './translate-article-content.component';
import ViewArticleContent from './view-article-content.component';
import SearchList from '../table-elements/search-list.component';
import UploadPosters from './upload-posters.component';
import useFilteredActions from '../../hooks/filtered-action.hook';
import { AccessContext } from '../../context/access.context';
import useAccess from '../../hooks/access.hook';

const ListArticleContent = () => {
  const { accessData } = useContext(AccessContext);
  const { hasOperationAccess } = useAccess();
  const columns = LISTING_COLUMNS;
  const actions = useFilteredActions(
    accessData.role,
    'article_contents',
    LISTING_ACTIONS
  );

  const isArticleContent = true;
  const searchVideosRef = useRef(null);
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [langLabel, setLanguageLabel] = useState('');
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [imagePreview, setImagePreview] = useState({
    show: false,
    imageUrl: '',
    title: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    for (let i = 0; i < LANGUAGES.length; i += 1) {
      if (selectedAction === LANGUAGES[i].value) {
        setLanguageLabel(LANGUAGES[i].label);
      }
    }
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
      languageLable: langLabel,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });

    setImagePreview({
      ...imagePreview,
      show: false,
    });
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteArticleContentById(doAction.data.id)
      .then(() => {
        handleActionSuccess(
          `${CONTENT_ALIAS.ARTICLE_CONTENT} deleted successfully.`
        );
        handleActionCancel();
      })
      .catch((e) => {
        const message =
          e.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleImagePreview = (rowData) => {
    setImagePreview({
      ...imagePreview,
      show: true,
      imageUrl: rowData.banner,
      title: rowData.title,
    });
  };

  const handleToggleStatus = (r, value) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const payload = {
      id: r.id,
      status: value ? 0 : 1,
      type: 'article-content',
    };

    toggleStatus(payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Status updated.',
        });
      })
      .catch((e) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: e.response?.data?.message,
        });
      });
  };

  const handleSearchChange = (e) => {
    setOptions({
      ...options,
      searchValue: e.target.value || '',
    });
  };

  const handleSearchVideos = () => {
    if (options.loading) return;
    setOptions({
      ...options,
      q: searchVideosRef.current.value,
      reloadCounter: options.reloadCounter + 1,
      page: 0,
      loading: true,
    });
  };

  const handleClearSearch = () => {
    if (options.loading) return;
    setOptions({
      ...options,
      searchValue: '',
      q: null,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
    searchVideosRef.current.value = '';
  };

  const dataListAPICall = () => {
    let uri = 'article-content';
    uri += `?page=${options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      uri += `&q=${options.q}`;
    }

    setOptions({ ...options, loading: true, searchValue: '' });

    getDataList(uri)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  };

  useEffect(() => {
    dataListAPICall(options);
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>
          {hasOperationAccess(
            accessData.role,
            'article_contents',
            'create'
          ) && (
            <Button
              color="primary"
              variant="contained"
              startIcon={<Iconify icon="ic:round-add" />}
              onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
            >
              Create New
            </Button>
          )}

          <SearchList
            label={CONTENT_ALIAS.ARTICLE_CONTENT}
            handleSearch={handleSearchVideos}
            inputRef={searchVideosRef}
            onChange={handleSearchChange}
            options={options}
            clearSearch={handleClearSearch}
            loading={options.loading}
            width="230px"
          />
        </div>
        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>
      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
          handleImagePreview={handleImagePreview}
          handleToggleStatus={handleToggleStatus}
        />
      </Card>
      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditArticleContent
          title={`Add New ${CONTENT_ALIAS.ARTICLE_CONTENT}`}
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
        />
      )}
      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditArticleContent
          title={`Edit ${CONTENT_ALIAS.ARTICLE_CONTENT} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
        />
      )}
      {LANGUAGES.find((o) => o.value === doAction.action) && (
        <TranslateArticleContent
          title={`Translate ${CONTENT_ALIAS.ARTICLE_CONTENT} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          languageCode={doAction.action}
          languageLabel={langLabel}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}
      {doAction.action === ACTIONS.VIEW.value && (
        <ViewArticleContent
          title={`${CONTENT_ALIAS.ARTICLE_CONTENT} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}
      {doAction.action === ACTIONS.UPLOAD_POSTER.value && (
        <UploadPosters
          title={`${CONTENT_ALIAS.POPULAR_CONTENT_SINGULAR} - Upload Posters - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          squareImageUrl={doAction.data.banner}
          horizontalImageUrl={doAction.data.horizBanner}
          isArticleContent={isArticleContent}
        />
      )}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title={`Delete ${CONTENT_ALIAS.ARTICLE_CONTENT}`}
          message={`Do you want to delete '${doAction.data.title}' ${CONTENT_ALIAS.ARTICLE_CONTENT}? You can not undo this action!`}
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}
      {imagePreview.show && (
        <ImagePreview
          title={`Poster - ${imagePreview.title}`}
          onCancel={handleActionCancel}
          imageUrl={imagePreview.imageUrl}
        />
      )}
      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListArticleContent;
