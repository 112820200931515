import React from 'react';
import { Grid } from '@mui/material';
import AppLayout from '../../layout/app.layout';
import CourseRegistrationReportChart from '../../components/reports/course-registration-report.component';

import PAGE_TITLE_LIST from '../../config/page-title.config';

const CourseReportView = () => {
  const pageTitle = PAGE_TITLE_LIST.REPORTS_COURSE;

  return (
    <AppLayout pageTitle={pageTitle}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CourseRegistrationReportChart />
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default CourseReportView;
