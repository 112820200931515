import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ThemeProvider from '../theme/wrappers';
import AppRouter from '../routes/app.routes';
import {
  checkAdminUserLoggedIn,
  setAdminUserLoggedOut,
  setAdminUserLoggedIn,
} from '../utils/local-cache.util';
import { adminUserValidateSession } from '../services/auth.service';
import Loader from '../components/common/loader.component';
import { AccessContext } from '../context/access.context';

const AppView = () => {
  const [appLoaded, setAppLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessRole, setAccessRole] = useState('');
  const { handleSetAccessData } = useContext(AccessContext);

  useEffect(() => {
    const isAdminLoggedIn = checkAdminUserLoggedIn();

    if (isAdminLoggedIn) {
      adminUserValidateSession()
        .then((res) => {
          const loggedInUserPayload = {
            id: res.data?.id || 0,
            token: res.data?.auth?.token || '',
            name: res.data?.name || '',
            email: res.data?.email || '',
            role: res.data?.role || '',
          };
          setAdminUserLoggedIn(loggedInUserPayload);
          setAccessRole(loggedInUserPayload.role);
          setIsLoggedIn(isAdminLoggedIn);
          handleSetAccessData({
            role: loggedInUserPayload.role,
          });
          setAppLoaded(true);
        })
        .catch(() => {
          setAdminUserLoggedOut();
          setAppLoaded(true);
        });
    } else {
      setAppLoaded(true);
    }
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          {!appLoaded && <Loader />}
          {appLoaded && (
            <AppRouter isLoggedIn={isLoggedIn} accessRole={accessRole} />
          )}
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default AppView;
