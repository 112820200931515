import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  InputAdornment,
  Grid,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import SnackbarInfo from '../common/snackbar-info.component';
import { updatePasswordById } from '../../services/admin-users.service';
import ROUTE_PATH from '../../config/routes.config';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ChangePassword = ({ title, dataId, onCancel, onSuccess, isAdmin }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
  });
  const passwordFieldValues = watch('password');
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    password: false,
    confirmPassword: false,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      oldPassword: data.oldPassword,
      newPassword: data.password,
    };
    updatePasswordById(dataId, payload)
      .then((res) => {
        if (res?.data?.success && isAdmin) {
          navigate(ROUTE_PATH.LOGOUT);
        }
        onSuccess('Password changed successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  return (
    <Dialog
      open
      aria-labelledby="change-password-dialog-title"
      aria-describedby="change-password-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="change-password-dialog-description">
        <form id="change-password" onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Controller
                name="oldPassword"
                control={control}
                rules={{
                  ...validationRules.REQUIRED,
                  ...validationRules.PASSWORD,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Old Password"
                    type={showPassword.oldPassword ? 'text' : 'password'}
                    error={!!errors.oldPassword}
                    helperText={errors?.oldPassword?.message || null}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                oldPassword: !showPassword.oldPassword,
                              })
                            }
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showPassword.oldPassword
                                  ? 'eva:eye-fill'
                                  : 'eva:eye-off-fill'
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Controller
                name="password"
                control={control}
                rules={{
                  ...validationRules.REQUIRED,
                  ...validationRules.PASSWORD,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="New Password"
                    type={showPassword.password ? 'text' : 'password'}
                    error={!!errors.password}
                    helperText={errors?.password?.message || null}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                password: !showPassword.password,
                              })
                            }
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showPassword.password
                                  ? 'eva:eye-fill'
                                  : 'eva:eye-off-fill'
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  ...validationRules.REQUIRED,
                  ...validationRules.PASSWORD,
                  validate: (value) =>
                    value !== passwordFieldValues
                      ? 'Password do not match.'
                      : true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Re-enter New Password"
                    type={showPassword.confirmPassword ? 'text' : 'password'}
                    error={!!errors.confirmPassword}
                    helperText={errors?.confirmPassword?.message || null}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                confirmPassword: !showPassword.confirmPassword,
                              })
                            }
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showPassword.confirmPassword
                                  ? 'eva:eye-fill'
                                  : 'eva:eye-off-fill'
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="change-password"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Change Password
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

ChangePassword.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};
ChangePassword.defaultProps = {
  isAdmin: false,
};

export default ChangePassword;
