import React from 'react';
import PropTypes from 'prop-types';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListRecordedMessage from '../../components/content/list-recorded-message.component';

const RecordedMessageView = ({ type }) => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_RECORDED_MESSAGE} ${
    type === 'VIDEO' ? 'Videos' : 'Audios'
  }`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListRecordedMessage type={type} />
    </AppLayout>
  );
};

RecordedMessageView.propTypes = { type: PropTypes.string.isRequired };

export default RecordedMessageView;
