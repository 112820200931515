// Define application wide routes
const ROUTE_PATH = {
  LOGIN: '/login',
  DASHBOARD: '/',
  REALTIME_DASHBOARD: '/realtime-dashboard',
  CONTENT_SECTIONS: '/content/sections',
  CONTENT_CATEGORIES: '/content/categories',
  CONTENT_VIDEOS: '/content/videos',
  CONTENT_VIDEOS_EVENT_REGISTRATIONS:
    '/content/videos/:id/events-registrations',
  CONTENT_MANAGE_RECURRING_EVENTS:
    '/content/videos/:id/manage-recurring-events',
  CONTENT_POPULAR: '/content/popular-content',
  CONTENT_ADS: '/content/ads',
  CONTENT_SEQUENCE: '/content/sequence',
  CONTENT_SEQUENCE_VIDEOS: '/content/sequence/videos',
  CONTENT_SEQUENCE_LINK_SECTION: '/content/sequence/link-sections',
  CONTENT_SEQUENCE_COURSE: '/content/sequence/courses',
  CONTENT_SEQUENCE_PROGRAM: '/content/sequence/programs',
  CONTENT_SEQUENCE_TAO_SONG: '/content/sequence/tao-songs',
  CONTENT_SEQUENCE_BLESSING: '/content/sequence/blessings',
  CONTENT_SEQUENCE_MEDITATION: '/content/sequence/meditation',
  CONTENT_SEQUENCE_ARTICLE_CONTENT: '/content/sequence/article-contents',
  CONTENT_COMMUNITY_GATHERINGS: '/content/community-gatherings',
  CONTENT_SEQUENCE_TESTIMONIAL_TABS: '/content/sequence/testimonials/tabs',
  CONTENT_SEQUENCE_TESTIMONIAL_CATEGORIES:
    '/content/sequence/testimonials/categories',
  CONTENT_TABS: '/content/tabs',
  CONTENT_TESTIMONIALS: '/content/testimonials',
  CONTENT_TESTIMONIALS_TABS: '/content/testimonials/tabs',
  CONTENT_TESTIMONIALS_CATEGORIES: '/content/testimonials/categories',
  CONTENT_TESTIMONIALS_TEXT: '/content/testimonials/text-testimonials',
  CONTENT_USER_TESTIMONIALS_TEXT:
    '/content/testimonials/user-text-testimonials',
  CONTENT_USER_TESTIMONIALS_VIDEO:
    '/content/testimonials/user-video-testimonials',
  CONTENT_ARTICLE: '/content/article',
  CONTENT_ARTICLE_CATEGORIES: '/content/article/categories',
  CONTENT_ARTICLE_CONTENTS: '/content/article/content',
  CONTENT_VIDEO_COMMENTS: '/content/video-comments',
  SUBSCRIBERS: '/subscribers',
  SUBSCRIPTIONS: '/subscriptions',
  GROUPS: '/groups',
  GROUPS_MANAGE_MEMBERS: '/groups/:id/manage-members',
  PACKAGES: '/packages',
  ADMIN_USERS: '/users',
  NOTIFICATIONS: '/notifications',
  SALUTATION: '/salutation',
  REPORTS_OVERVIEW_VIDEOS: '/reports/overview/videos',
  REPORTS_LIVE_USERS: '/reports/live-users',
  REPORTS_SESSIONS: '/reports/sessions',
  REPORTS_SUBSCRIPTIONS: '/reports/subscriptions',
  REPORTS_LIVE_EVENT: '/reports/live-event',
  REPORTS_PROGRAMS: '/reports/purchase-report',
  LOGOUT: '/logout',
  CONTENT_TRANSMISSION_ORGANS: '/content/transmission-organs',
  CONTENT_TRANSMISSION_PROGRAM_REQUEST: '/content/transmission-program-request',
  CONTENT_RESOURCES: '/content/resources',
  CONTENT_RECORDED_MESSAGE: '/content/recorded',
  CONTENT_RECORDED_VIDEOS: '/content/recorded/videos',
  CONTENT_RECORDED_AUDIOS: '/content/recorded/audios',
  CONTENT_LINK_SECTIONS: '/content/recorded/link-sections',
  CONTENT_LINK_SECTION_ITEMS: '/content/recorded/link-section-items',
  REPORTS_COURSE: '/reports/course-report',
};

export default ROUTE_PATH;
