/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { Stack, Grid, Card, CardHeader, Box, IconButton } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { Controller, useForm } from 'react-hook-form';
import { getDate, formatDate } from '../../utils/datetime.util';
import { getProgramReportData } from '../../services/reports.service';
import Datepicker from '../form-elements/datepicker.component';
import Iconify from '../common/iconify.component';
import validationRules from '../../utils/validations.util';
import ProgramType from '../form-elements/program-type.component';
import OtherProgramType from '../form-elements/other-program-type.component';
import { CATEGORY_TYPE, VIDEO_TYPES } from '../../config/const.config';
import ListData from '../table-elements/list-data.component';
import LISTING_COLUMNS from '../../config/module-configs/program-report-data.config';

const ProgramReportChart = () => {
  const {
    control,
    formState: { errors },
  } = useForm({});

  const [filters, setFilters] = useState({
    startDate: getDate(-7),
    endDate: getDate(0),
    reloadCounter: 0,
  });
  const columns = LISTING_COLUMNS;
  const actions = [];
  const [selectedProgram, setSelectedProgram] = useState([0]);
  const [contentTypes, setContentType] = useState(0);
  const [otherContentTypes, setOtherContentTypes] = useState(0);
  const [selectedOtherProgram, setSelectedOtherProgram] = useState([0]);
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [detailsOptions, setDetailsOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    unfilterRows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });

  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
          tools: {
            zoom: false,
            zoomin: false,
            zoomout: false,
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: { position: 'bottom', offsetX: -10, offsetY: 0 },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          borderRadiusApplication: 'end',
          borderRadiusWhenStacked: 'last',
          dataLabels: {
            total: {
              enabled: true,
              style: { fontSize: '13px', fontWeight: 900 },
            },
          },
        },
      },
      xaxis: {
        type: 'category',
        categories: [],
      },
      legend: {
        position: 'right',
        offsetY: 40,
      },
      fill: {
        opacity: 0.8,
      },
      tooltip: {
        y: {
          formatter: (value, { dataPointIndex, w }) => {
            const event = w.config.chartData[dataPointIndex];
            if (!event) return value;
            const { name } = event;
            return `${value} - ${name}`;
          },
        },
      },
    },
  });

  const handleProgramChange = (program) => {
    setContentType(program);
    setSelectedProgram([program]);
    setFilters((prev) => ({
      ...prev,
      reloadCounter: prev.reloadCounter + 1,
    }));
  };
  const handleOtherProgramChange = (program) => {
    setOtherContentTypes(program);
    setSelectedOtherProgram([program]);
    setFilters((prev) => ({
      ...prev,
      reloadCounter: prev.reloadCounter + 1,
    }));
  };

  const handlePageChange = (v) => {
    setDetailsOptions({
      ...detailsOptions,
      page: v,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setDetailsOptions({
      ...detailsOptions,
      rowsPerPage: v,
      page: 0,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    if (!sortBy || !sortOrder) {
      setDetailsOptions((prev) => ({
        ...prev,
        rows: detailsOptions.unfilterRows,
        sortBy,
        sortOrder,
      }));
      return;
    }

    const order = sortOrder || 'asc';

    const { page, rowsPerPage, rows } = detailsOptions;
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const currentPageRows = rows.slice(startIndex, endIndex);

    const sortedPageRows = [...currentPageRows].sort((a, b) => {
      // eslint-disable-next-line prefer-const
      let aValue = a[sortBy];
      // eslint-disable-next-line prefer-const
      let bValue = b[sortBy];

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return order === 'asc' ? bValue - aValue : aValue - bValue;
      }

      if (!isNaN(Date.parse(aValue)) && !isNaN(Date.parse(bValue))) {
        return order === 'asc'
          ? new Date(aValue) - new Date(bValue)
          : new Date(bValue) - new Date(aValue);
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return order === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return 0;
    });

    const updatedRows = [...rows];

    updatedRows.splice(startIndex, sortedPageRows.length, ...sortedPageRows);

    setDetailsOptions((prev) => ({
      ...prev,
      rows: updatedRows,
      sortBy,
      sortOrder,
    }));
  };
  const handleStartDateChange = (v) => {
    setFilters({
      ...filters,
      startDate: v,
      reloadCounter: filters.reloadCounter + 1,
    });
  };
  const handleEndDateChange = (v) => {
    setFilters({
      ...filters,
      endDate: v,
      reloadCounter: filters.reloadCounter + 1,
    });
  };
  const handleRefreshData = () => {
    setFilters({
      ...filters,
      reloadCounter: filters.reloadCounter + 1,
    });
    setOptions((prevOptions) => ({
      ...prevOptions,
      loading: true,
    }));
    setDetailsOptions((prevOptions) => ({
      ...prevOptions,
      loading: true,
    }));
  };

  useEffect(() => {
    const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
    const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');
    const eventPayload = {
      startDate,
      endDate,
      page: options.page + 1,
      perPage: options.rowsPerPage,
    };

    if (options.sortBy && options.sortOrder) {
      eventPayload.sortBy = options.sortBy;
      eventPayload.sortOrder = options.sortOrder;
    }
    let contentType = '';
    if (contentTypes === 0) {
      contentType = CATEGORY_TYPE[0].id;
    } else if (contentTypes === 1) {
      contentType = CATEGORY_TYPE[1].id;
    } else if (contentTypes === 2) {
      contentType = CATEGORY_TYPE[2].id;
    } else if (contentTypes === 3) {
      if (otherContentTypes === 0) {
        contentType = VIDEO_TYPES.TAO_SONG.value;
      } else if (otherContentTypes === 1) {
        contentType = VIDEO_TYPES.MEDITATION.value;
      } else if (otherContentTypes === 2) {
        contentType = VIDEO_TYPES.BLESSINGS.value;
      } else if (otherContentTypes === 3) {
        contentType = VIDEO_TYPES.LIVE_EVENT.value;
      }
    }

    const programPayload = {
      startDate,
      endDate,
      contentType,
    };

    getProgramReportData(programPayload)
      .then((res) => {
        if (res) {
          setOptions((prev) => ({
            ...prev,
            loading: false,
            totalRows: res?.data.data?.totalRows || 0,
            rows: res?.data.data?.rows || [],
            error: false,
          }));
          setDetailsOptions((prev) => ({
            ...prev,
            loading: false,
            totalRows: res?.detailData?.totalRows || 0,
            rows: res?.detailData?.rows || [],
            unfilterRows: res?.detailData?.rows || [],
            error: false,
          }));
          const filteredRows = res.data.rows.filter(
            (row) => row.totalPurchased > 0 || row.totalActivePurchsed > 0
          );

          const categories = filteredRows.map((row) => row.name);

          const totalPurchased = filteredRows.map((row) => row.totalPurchased);
          const totalActivePurchsed = filteredRows.map(
            (row) => row.totalActivePurchsed
          );

          setChartData((prev) => ({
            ...prev,
            series: [
              { name: 'Total Purchased', data: totalPurchased },
              { name: 'Total Active Purchased', data: totalActivePurchsed },
            ],
            options: {
              ...prev.options,
              xaxis: { ...prev.options.xaxis, categories },
              chartData: filteredRows,
              tooltip: {
                x: {
                  formatter: (value, { dataPointIndex, w }) => {
                    const event = w.config.chartData[dataPointIndex];
                    if (!event) return value;
                    const tempSpan = document.createElement('span');
                    tempSpan.style.fontSize = '15px';
                    tempSpan.style.visibility = 'hidden';
                    tempSpan.style.whiteSpace = 'nowrap';
                    tempSpan.innerText = event.name;
                    document.body.appendChild(tempSpan);
                    const textWidth = tempSpan.offsetWidth;
                    document.body.removeChild(tempSpan);

                    const marqueeTag =
                      textWidth > 200
                        ? `<marquee behavior="scroll" direction="left" scrollamount="5" style="width: 200px; white-space: nowrap; overflow: hidden; display: block;">
                                                <strong style="font-size: 15px; text-align: center; display: inline-block;">${event.name}</strong>
                                             </marquee>`
                        : `<strong style="font-size: 15px; text-align: center; display: inline-block;">${event.name}</strong>`;

                    return `               
                     ${marqueeTag}`;
                  },
                },
                y: {
                  formatter: (value) => `${value}`,
                },
              },
            },
          }));
        }
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
        setDetailsOptions({
          ...detailsOptions,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [
    filters.reloadCounter,
    options.reloadCounter,
    detailsOptions.reloadCounter,
    selectedProgram,
    selectedOtherProgram,
    contentTypes,
  ]);

  const isDataEmpty = (series) =>
    series.every(
      (s) => s.data.length === 0 || s.data.every((val) => val === 0)
    );
  return (
    <Card>
      <CardHeader
        title="Purchased Content Analytics"
        subheader=""
        action={
          <IconButton title="Refresh" onClick={handleRefreshData}>
            <Iconify icon="ic:twotone-refresh" />
          </IconButton>
        }
      />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="programs"
              control={control}
              rules={{
                validate: {
                  required: () =>
                    (selectedProgram && selectedProgram.length > 0) ||
                    validationRules.REQUIRED.required.message,
                },
              }}
              // eslint-disable-next-line no-unused-vars
              render={({ field: { onChange, value } }) => (
                <ProgramType
                  id="programs"
                  name="programs"
                  label="Select Program Type"
                  value={value}
                  defaultValue={value}
                  onChange={handleProgramChange}
                  validOptions={[0, 1, 2, 3]}
                  selectedProgram={selectedProgram}
                  handleProgramChange={handleProgramChange}
                  sx={{ width: '100%' }}
                  error={errors?.programs?.message || ''}
                />
              )}
            />
          </Grid>
          {selectedProgram[0] === 3 && (
            <Grid item xs={12} sm={12} md={12}>
              <Controller
                name="otherPrograms"
                control={control}
                rules={{
                  validate: {
                    required: () =>
                      (selectedOtherProgram &&
                        selectedOtherProgram.length > 0) ||
                      validationRules.REQUIRED.required.message,
                  },
                }}
                // eslint-disable-next-line no-unused-vars
                render={({ field: { onChange, value } }) => (
                  <OtherProgramType
                    id="otherPrograms"
                    name="otherPrograms"
                    label="Select Other Types"
                    value={value}
                    defaultValue={value}
                    onChange={handleOtherProgramChange}
                    validOptions={[0, 1, 2, 3]}
                    selectedOtherProgram={selectedOtherProgram}
                    handleOtherProgramChange={handleOtherProgramChange}
                    sx={{ width: '100%' }}
                    error={errors?.otherPrograms?.message || ''}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="right" spacing={2}>
              <Datepicker
                id="dr_start_date"
                name="dr_start_date"
                label="Start Date"
                defaultValue={filters.startDate}
                onChange={handleStartDateChange}
                sx={{ width: 200 }}
              />
              <Datepicker
                id="dr_end_date"
                name="dr_end_date"
                label="End Date"
                defaultValue={filters.endDate}
                onChange={handleEndDateChange}
                sx={{ width: 200 }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            {options.loading && <>Loading...</>}

            {!options.loading &&
              (!chartData.series.length || isDataEmpty(chartData.series)) && (
                <>No data available.</>
              )}

            {!options.loading &&
              chartData.series.length &&
              !isDataEmpty(chartData.series) && (
                <ReactApexChart
                  options={chartData.options}
                  series={chartData.series}
                  type="bar"
                  height={350}
                />
              )}
          </Grid>
        </Grid>
      </Box>
      <Card sx={{ marginTop: 3 }}>
        {!detailsOptions.loading && (
          <ListData
            columns={columns}
            rows={
              detailsOptions.rows.slice(
                detailsOptions.page * detailsOptions.rowsPerPage,
                (detailsOptions.page + 1) * detailsOptions.rowsPerPage
              ) || []
            }
            page={detailsOptions.page}
            rowsPerPage={detailsOptions.rowsPerPage}
            totalRows={detailsOptions.totalRows}
            loading={detailsOptions.loading}
            actions={actions}
            error={detailsOptions.error}
            sortBy={detailsOptions.sortBy}
            sortOrder={detailsOptions.sortOrder}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSortChange={handleSortingChange}
          />
        )}
      </Card>
    </Card>
  );
};

export default ProgramReportChart;
