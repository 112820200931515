import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

export const LISTING_COLUMNS = [
  {
    id: 'thumbnail',
    label: 'Image Preview',
    width: '5%',
    align: 'center',
    dataKey: 'posterUrl',
    sortable: false,
    type: CELL_DATA_TYPES.IMAGE,
  },
  {
    id: 'recurringTimeStamp',
    label: 'Recurring Date',
    width: '15%',
    align: 'center',
    dataKey: 'recurringTimestamp',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'status',
    label: 'Status',
    width: '5%',
    align: 'center',
    dataKey: 'recurringTimestamp',
    sortable: true,
    type: CELL_DATA_TYPES.EVENT_TIMESTAMP,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'title',
    label: 'Event',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'isPaid',
    label: 'Is Paid',
    dataKey: 'isPaid',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'isRecurring',
    label: 'Is Recurring',
    dataKey: 'isRecurring',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'subscriptionsCount',
    label: 'Current Active Paid Subscriptions',
    dataKey: 'subscriptionsCount',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'totalRegistrations',
    label: 'Total Subscribers Registered',
    dataKey: 'totalRegistrations',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'totalJoined',
    label: 'Total Subscribers Joined',
    dataKey: 'totalJoined',
    type: CELL_DATA_TYPES.NUMBER,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.UPLOAD_ASSETS.value,
    label: ACTIONS.UPLOAD_ASSETS.label,
  },
  {
    action: ACTIONS.TRANSLATE.value,
    label: ACTIONS.TRANSLATE.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];
