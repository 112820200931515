import ROUTE_PATH from './routes.config';

export const ACCESS_RIGHTS_ADMIN = {
  defaultRoute: ROUTE_PATH.DASHBOARD,
  dashboard: {
    view: true,
  },
  subscribers: {
    create: true,
    edit: true,
    view: true,
    manage_subscriptions: true,
    manage_notes: true,
    change_password: true,
    login_as_subscriber: true,
    delete: true,
  },
  subscriptions: {
    create: true,
    grace_subscription: true,
    view: true,
    view_transaction: true,
    manage_notes: true,
  },
  community_groups: {
    create: true,
    edit: true,
    view: true,
    upload_poster: true,
    translate: true,
    delete: true,
  },
  manage_members: {
    view: true,
    create: true,
    edit: true,
    delete: true,
  },
  packages: {
    create: true,
    edit: true,
    view: true,
  },
  users: {
    create: true,
    edit: true,
    view: true,
    change_password: true,
    delete: true,
  },
  program_course_group: {
    create: true,
    edit: true,
    view: true,
    upload_poster: true,
    translate: true,
    delete: true,
  },
  program_courses: {
    create: true,
    edit: true,
    view: true,
    upload_poster: true,
    translate: true,
    delete: true,
    manage_teachers: true,
  },
  videos: {
    create: true,
    sync: true,
    edit: true,
    translate: true,
    view: true,
    upload_assets: true,
    view_comments: true,
    manage_recurring_events: true,
    view_registrations: true,
    delete: true,
  },
  transmission_organs: {
    create: true,
    edit: true,
    view: true,
    upload_poster: true,
    translate: true,
    manage_teachers: true,
  },
  tao_soul_program_requests: {
    view: true,
  },
  popular_content: {
    create: true,
    edit: true,
    view: true,
    upload_poster: true,
    translate: true,
    delete: true,
  },
  resources: {
    create: true,
    edit: true,
    view: true,
    upload_poster: true,
    translate: true,
    delete: true,
  },
  community_gatherings_zoom: {
    create: true,
    edit: true,
    view: true,
    upload_poster: true,
    translate: true,
    delete: true,
  },
  ms_voice_recorded_videos: {
    edit: true,
    view: true,
    delete: true,
    upload_poster: true,
  },
  ms_voice_recorded_audios: {
    edit: true,
    view: true,
    delete: true,
  },
  ms_voice_link_sections: {
    create: true,
    edit: true,
    view: true,
    delete: true,
  },
  ms_voice_link_section_items: {
    create: true,
    edit: true,
    view: true,
    delete: true,
  },
  sequence_courses: {
    edit: true,
    edit_video: true,
    view: true,
  },
  sequence_programs: {
    edit: true,
    edit_video: true,
    view: true,
  },
  sequence_tao_calligraphy_meditations: {
    view: true,
    edit: true,
  },
  sequence_blessings: {
    view: true,
    edit: true,
  },
  sequence_love_peace_harmonys: {
    view: true,
    edit: true,
  },
  sequence_article_contents: {
    view: true,
    edit: true,
  },
  sequence_link_section: {
    edit: true,
    edit_video: true,
    view: true,
  },
  testimonials_tabs: {
    create: true,
    edit: true,
    view: true,
    translate: true,
    delete: true,
  },
  testimonials_categories: {
    create: true,
    edit: true,
    view: true,
    translate: true,
    delete: true,
  },
  testimonials_text_testimonials: {
    create: true,
    edit: true,
    view: true,
    translate: true,
    delete: true,
  },
  testimonials_user_text_testimonials: {
    view: true,
    delete: true,
  },
  testimonials_user_video_testimonials: {
    sync: true,
    view: true,
    upload_video: true,
    delete: true,
    download: true,
  },
  article_categories: {
    create: true,
    edit: true,
    view: true,
    translate: true,
    delete: true,
  },
  article_contents: {
    create: true,
    edit: true,
    view: true,
    translate: true,
    upload_poster: true,
    delete: true,
  },
  comments: {
    view: true,
    delete: true,
  },
  notifications: {
    view: true,
    edit: true,
    translate: true,
  },
  video_watch_overview: {
    view: true,
    download: true,
  },
  video_watch_sessions: {
    view: true,
  },
  live_users: {
    view: true,
  },
  subscriptions_summary: {
    view: true,
    download: true,
  },
  live_event_report: {
    view: true,
  },
  purchase_report: {
    view: true,
  },
};

export const ACCESS_RIGHTS_TEACHER = {
  defaultRoute: ROUTE_PATH.CONTENT_SECTIONS,
  dashboard: {
    view: true,
  },
  program_course_group: {
    create: true,
    edit: true,
    view: true,
    upload_poster: true,
    translate: true,
    delete: true,
  },
  program_courses: {
    create: true,
    edit: true,
    view: true,
    upload_poster: true,
    translate: true,
    delete: true,
    manage_teachers: true,
  },
  videos: {
    create: true,
    sync: true,
    edit: true,
    translate: true,
    view: true,
    upload_assets: true,
    view_comments: true,
    manage_recurring_events: true,
    view_registrations: true,
    delete: true,
  },
};

export const ACCESS_RIGHTS_SALE = {
  defaultRoute: ROUTE_PATH.SUBSCRIBERS,
  dashboard: {
    view: true,
  },
  subscribers: {
    create: true,
    edit: true,
    view: true,
    manage_subscriptions: true,
    manage_notes: true,
    change_password: true,
    login_as_subscriber: true,
    delete: true,
  },
  subscriptions: {
    create: true,
    grace_subscription: true,
    view: true,
    view_transaction: true,
    manage_notes: true,
  },
  subscriptions_summary: {
    view: true,
    download: true,
  },
  purchase_report: {
    view: true,
  },
};
