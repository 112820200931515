import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Grid,
  Box,
  TextField,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewData,
  updateDataById,
  viewDataById,
} from '../../services/subscriptions.service';
import Status from '../form-elements/status.component';
import Subscribers from '../form-elements/subscribers.component';
import {
  CATEGORY_TYPE,
  CONTENT_ALIAS,
  POPULAR_CONTENT_TYPES,
} from '../../config/const.config';
import Content from '../form-elements/content.component';
import PopularContentTypes from '../form-elements/popular-types.component';
import { getPackageByContent } from '../../services/content.service';
import SVODPackages from '../form-elements/svod-packages.component';
import TransmissionOrganCategory from '../form-elements/transmission-organ-category.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditSubscription = ({ title, user, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    watch,
    unregister,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      email: '',
      status: null,
      contentType: null,
      content: null,
      svodPackage: null,
      package: '',
      subscriptionDays: '',
      maxSubscriptionDays: '',
      price: '',
      transmissionOrgan: null,
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const contentTypeWatch = watch('contentType');
  const contentWatch = watch('content');
  const transmissionOrganWatch = watch('transmissionOrgan');

  const saveNewData = (payload) => {
    createNewData(payload)
      .then(() => {
        onSuccess('New Subscription added successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateDataById(dataId, payload)
      .then(() => {
        onSuccess('Subscription details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      email: data.email.email || '',
      contentId: data.content || null,
      contentType: data.contentType,
      svodPackage: data.svodPackage,
      transmissionOrgan: data.transmissionOrgan,
      status: data.status,
      notes: data.notes,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    unregister('content');
    setValue('content', null);
  }, [contentTypeWatch]);

  useEffect(() => {
    if (contentTypeWatch && contentWatch) {
      getPackageByContent(
        contentTypeWatch,
        contentWatch,
        transmissionOrganWatch
      )
        .then((res) => {
          setValue('package', res.data?.name || '');
          setValue('subscriptionDays', res.data?.subscriptionDays || '');
          setValue('maxSubscriptionDays', res.data?.maxSubscriptionDays || '');
          setValue('price', res.data?.price || '');
        })
        .catch(() => {});
    }
  }, [contentWatch, transmissionOrganWatch]);

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      setValue('status', 1);
      return;
    }

    viewDataById(dataId)
      .then((res) => {
        // Fill form values
        const autofillEmail = {
          id: dataId,
          firstName: res.data.firstName || '',
          lastName: res.data.lastName || '',
          email: res.data.email || '',
          contact: res.data.contact || '',
          profilePicture: res.data.profilePic || '',
        };
        setValue('email', autofillEmail || {});
        setValue('status', res.data?.status || 0);

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle
        id="view-dialog-title"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {title}
        {!!dataId && user.profilePic && (
          <Box
            component="img"
            sx={{
              height: 30,
              width: 30,
              objectFit: 'cover',
              borderRadius: 15,
              display: 'inline',
              marginX: 1,
            }}
            src={user.profilePic}
          />
        )}
        {!!dataId && user.email}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="email"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Subscribers
                      id="email"
                      name="email"
                      label="Enter Subscriber Email"
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.email?.message || ''}
                      disabled={dataId}
                    />
                  )}
                />
              </Grid>

              {dataId === 0 && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="status"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Status
                        id="sub_status"
                        name="sub_status"
                        label="Status"
                        defaultValue={value}
                        onChange={onChange}
                        validOptions={[1]}
                        sx={{ width: '100%' }}
                        error={errors?.status?.message || ''}
                        disabled
                      />
                    )}
                  />
                </Grid>
              )}

              {dataId !== 0 && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="status"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Status
                        id="sub_status"
                        name="sub_status"
                        label="Status"
                        defaultValue={value}
                        onChange={onChange}
                        validOptions={[1, 0]}
                        sx={{ width: '100%' }}
                        error={errors?.status?.message || ''}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="contentType"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <PopularContentTypes
                      id="contentType"
                      name="contentType"
                      label="Content Type"
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.contentType?.message || ''}
                      disabled={!!dataId}
                    />
                  )}
                />
              </Grid>

              {(contentTypeWatch === POPULAR_CONTENT_TYPES.PROGRAM.value ||
                contentTypeWatch === CATEGORY_TYPE[2].id) && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="content"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Content
                        id="program"
                        name="program"
                        label={`Select ${CONTENT_ALIAS.CATEGORY_SINGULAR}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.content?.message || ''}
                        disabled={!!dataId}
                        type={
                          contentTypeWatch === CATEGORY_TYPE[2].id
                            ? 'TAO_SOUL_PROGRAM'
                            : 'PROGRAM'
                        }
                        isPaid={1}
                      />
                    )}
                  />
                </Grid>
              )}

              {contentTypeWatch === CATEGORY_TYPE[2].id && contentWatch && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="transmissionOrgan"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <TransmissionOrganCategory
                        id="transmissionOrgan"
                        name="transmissionOrgan"
                        label={`Select ${CONTENT_ALIAS.TRANSMISSION_ORGAN_PLURAL}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.content?.message || ''}
                        disabled={!!dataId}
                        categoryId={contentWatch}
                      />
                    )}
                  />
                </Grid>
              )}

              {contentTypeWatch === POPULAR_CONTENT_TYPES.COURSE.value && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="content"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Content
                        id="course"
                        name="course"
                        label={`Select ${CONTENT_ALIAS.COURSES_SINGULAR}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.content?.message || ''}
                        disabled={!!dataId}
                        type="COURSE"
                        isPaid={1}
                      />
                    )}
                  />
                </Grid>
              )}

              {(contentTypeWatch === POPULAR_CONTENT_TYPES.TAO_SONG.value ||
                contentTypeWatch ===
                  POPULAR_CONTENT_TYPES.MEDITATION.value) && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="svodPackage"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <SVODPackages
                        id="svodPackage"
                        name="svodPackage"
                        label="Select SVOD Package"
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.svodPackage?.message || ''}
                        contentType={contentTypeWatch}
                      />
                    )}
                  />
                </Grid>
              )}

              {contentTypeWatch === POPULAR_CONTENT_TYPES.LIVE_EVENT.value && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="content"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Content
                        id="liveevent"
                        name="liveevent"
                        label={`Select ${CONTENT_ALIAS.LIVE_EVENT_SINGULAR}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.content?.message || ''}
                        disabled={!!dataId}
                        type="LIVE_EVENT"
                        isPaid={1}
                      />
                    )}
                  />
                </Grid>
              )}

              {contentTypeWatch === POPULAR_CONTENT_TYPES.BLESSINGS.value && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="content"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Content
                        id="blessing"
                        name="blessing"
                        label={`Select ${CONTENT_ALIAS.BLESSING_SINGULAR}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.content?.message || ''}
                        disabled={!!dataId}
                        type="BLESSINGS"
                        isPaid={1}
                      />
                    )}
                  />
                </Grid>
              )}

              {contentTypeWatch &&
                contentWatch &&
                contentTypeWatch !== POPULAR_CONTENT_TYPES.TAO_SONG.value && (
                  <>
                    <Grid item xs={12} sm={6} md={6}>
                      <Controller
                        name="package"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={`${CONTENT_ALIAS.PACKAGE_SINGULAR} Name`}
                            type="text"
                            error={!!errors.package}
                            helperText={errors?.package?.message || null}
                            fullWidth
                            disabled
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={4} sm={2} md={2}>
                      <Controller
                        name="subscriptionDays"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Subscription Days"
                            type="number"
                            error={!!errors.subscriptionDays}
                            helperText={
                              errors?.subscriptionDays?.message || null
                            }
                            fullWidth
                            inputProps={{
                              min: 0,
                              max: 20000,
                            }}
                            disabled
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={4} sm={2} md={2}>
                      <Controller
                        name="maxSubscriptionDays"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Max Subscription Days"
                            type="number"
                            error={!!errors.maxSubscriptionDays}
                            helperText={
                              errors?.maxSubscriptionDays?.message || null
                            }
                            fullWidth
                            inputProps={{
                              min: 0,
                              max: 20000,
                            }}
                            disabled
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={4} sm={2} md={2}>
                      <Controller
                        name="price"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Price (CAD)"
                            type="float"
                            error={!!errors.price}
                            helperText={errors?.price?.message || null}
                            fullWidth
                            inputProps={{
                              min: 0,
                              max: 99999,
                            }}
                            disabled
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="notes"
                  control={control}
                  rules={{ ...maxLengthValidation(255) }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Notes"
                      type="text"
                      error={!!errors.notes}
                      helperText={errors?.notes?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditSubscription.propTypes = {
  title: PropTypes.string.isRequired,
  user: PropTypes.object,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
AddEditSubscription.defaultProps = {
  user: {},
};

export default AddEditSubscription;
