import React from 'react';

import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListSECTIONS from '../../components/content/list-link-sections.component';

const ListSectionsView = () => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_LINK_SECTIONS}`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListSECTIONS />
    </AppLayout>
  );
};

export default ListSectionsView;
