import React, { useEffect, useState, forwardRef, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  Divider,
  Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import JoditEditor from 'jodit-react';
import { maxLengthValidation } from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  updateArticleContentById,
  viewArticleContentById,
} from '../../services/content.service';
import { CONTENT_ALIAS } from '../../config/const.config';
import ImageUpload from './image-upload.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const TranslateArticleContent = ({
  title,
  dataId,
  languageCode,
  languageLabel,
  onCancel,
  onSuccess,
}) => {
  const defaultValues = {};
  defaultValues[`title_${languageCode}`] = '';
  defaultValues[`description_${languageCode}`] = '';
  defaultValues[`content_${languageCode}`] = '';
  defaultValues[`poster_${languageCode}`] = '';
  defaultValues[`horiz_banner_${languageCode}`] = '';
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [articleContentData, setArticleContentData] = useState({
    languageCode,
  });
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const options = [
    'bold',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'font',
    'fontsize',
    '|',
    'outdent',
    'indent',
    'align',
    '|',
    'hr',
    '|',
    'fullsize',
    'brush',
    '|',
    'table',
    'link',
    'image',
    '|',
    'undo',
    'redo',
    '|',
    'source',
  ];
  const config = useMemo(
    () => ({
      defaultMode: 1,
      readonly: false,
      tabIndex: -1,
      toolbar: true,
      placeholder: 'Start typing...',
      defaultActionOnPaste: 'insert_as_html',
      defaultLineHeight: 1,
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      minHeight: 350,
      useNativeTooltip: false,
    }),
    []
  );

  const setImageUrlCallBack = (v, type) => {
    if (type === 'square') {
      setArticleContentData(() => ({
        ...articleContentData,
        banner: v.imageUrl,
        languageCode,
      }));
      setValue(`poster_${languageCode}`, v.imageUrl || '');
    } else if (type === 'horizontal') {
      setArticleContentData(() => ({
        ...articleContentData,
        horizBanner: v.imageUrl,
        languageCode,
      }));
      setValue(`horiz_banner_${languageCode}`, v.imageUrl || '');
    }
  };

  const showToastMsg = (msgType, message) => {
    if (msgType === 'error') {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
    }

    if (msgType === 'success') {
      setSnackbarInfo({
        show: true,
        type: 'success',
        message,
      });
    }
  };

  const updateExistingData = (payload) => {
    updateArticleContentById(dataId, payload, 'lang')
      .then(() => {
        onSuccess(
          `${CONTENT_ALIAS.ARTICLE_CONTENT} details updated successfully.`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const payload = {
      title: data[`title_${languageCode}`],
      description: data[`description_${languageCode}`],
      content: content || data[`content_${languageCode}`],
      poster: data[`poster_${languageCode}`],
      horizBanner: data[`horiz_banner_${languageCode}`],
      languageCode,
    };
    if (dataId) {
      updateExistingData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }
    viewArticleContentById(dataId, languageCode, 'lang')
      .then((res) => {
        // Fill form values
        if (res.data && res.data[0]) {
          res.data[0][languageCode] = languageCode;
          setArticleContentData(res.data[0]);
          setValue(`title_${languageCode}`, res.data[0].title || '');
          setValue(
            `description_${languageCode}`,
            res.data[0].description || ''
          );
          setValue(`content_${languageCode}`, res.data[0].content || '');
          setValue(`poster_${languageCode}`, res.data[0].banner || '');
          setValue(
            `horiz_banner_${languageCode}`,
            res.data[0].horizBanner || ''
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      onClose={() => onCancel()}
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="upload-assets-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2} key={`title${languageCode}`}>
              <Grid item xs={12} sm={12} md={12}>
                <Divider
                  textAlign="center"
                  variant="fullWidth"
                  sx={{
                    fontWeight: 'bold',
                    mt: 0,
                  }}
                >
                  {languageLabel} Translation
                </Divider>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name={`title_${languageCode}`}
                  control={control}
                  rules={{
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={`${CONTENT_ALIAS.ARTICLE_CONTENT} Title (${languageCode})`}
                      type="text"
                      error={!!errors[`title_${languageCode}`]}
                      helperText={
                        errors[`title_${languageCode}`]?.message || null
                      }
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name={`description_${languageCode}`}
                  control={control}
                  rules={{
                    ...maxLengthValidation(1500),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={`Description (${languageCode})`}
                      type="text"
                      error={!!errors[`description_${languageCode}`]}
                      helperText={
                        errors[`description_${languageCode}`]?.message || null
                      }
                      fullWidth
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 1500 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name={`content_${languageCode}`}
                  control={control}
                  render={({ field }) => (
                    <JoditEditor
                      {...field}
                      label={`Content (${languageCode})`}
                      error={!!errors[`content_${languageCode}`]}
                      helperText={
                        errors[`content_${languageCode}`]?.message || null
                      }
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={15}
                      ref={editor}
                      config={config}
                      tabIndex={-1}
                      onBlur={(newContent) => setContent(newContent)}
                      onChange={(newContent) => setContent(newContent)}
                    />
                  )}
                />
              </Grid>

              <Box
                component="section"
                sx={{
                  p: 3,
                  ml: 2,
                  border: '1px solid lightgrey',
                  borderRadius: '5px',
                  width: '100%',
                  marginTop: 3,
                  padding: '10px',
                }}
              >
                <ImageUpload
                  title="Poster"
                  dataId={dataId}
                  showToastMsg={showToastMsg}
                  setSnackbarInfo={setSnackbarInfo}
                  videoData={
                    articleContentData || {
                      title: '',
                      description: '',
                      content: '',
                      banner: '',
                      languageCode,
                    }
                  }
                  loading={loading}
                  showUpdatedImage={() => {}}
                  setImageUrlCallBack={setImageUrlCallBack}
                  bannerType="square"
                  isTranslation
                  isArticleContent
                />
              </Box>
              <Box
                component="section"
                sx={{
                  p: 3,
                  ml: 2,
                  border: '1px solid lightgrey',
                  borderRadius: '5px',
                  width: '100%',
                  marginTop: 3,
                  padding: '10px',
                }}
              >
                <ImageUpload
                  title="Horizontal Poster"
                  dataId={dataId}
                  showToastMsg={showToastMsg}
                  setSnackbarInfo={setSnackbarInfo}
                  videoData={
                    articleContentData || {
                      title: '',
                      description: '',
                      content: '',
                      horizBanner: '',
                      languageCode,
                    }
                  }
                  loading={loading}
                  showUpdatedImage={() => {}}
                  setImageUrlCallBack={setImageUrlCallBack}
                  bannerType="horizontal"
                  isTranslation
                  isArticleContent
                />
              </Box>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="upload-assets-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

TranslateArticleContent.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  languageCode: PropTypes.string.isRequired,
  languageLabel: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default TranslateArticleContent;
