import React, { useState, useEffect, useContext } from 'react';
import { Card, Stack, Button } from '@mui/material';
import {
  LISTING_RESOURCES_COLUMNS,
  LISTING_RESOURCES_ACTIONS,
} from '../../config/module-configs/resources-content.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import { deleteDataById, getDataList } from '../../services/content.service';
import { ACTIONS, CONTENT_ALIAS } from '../../config/const.config';
import ConfirmPopup from '../common/confirm-popup.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ViewResources from './view-resources.component';
import ImagePreview from './image-preview.component';

import UploadResorceAssets from './upload-resources-banner.component';

import TranslateResourceContent from './translate-resource-content.component';
import AddEditResources from './add-edit-resources.component';
import useFilteredActions from '../../hooks/filtered-action.hook';
import { AccessContext } from '../../context/access.context';
import useAccess from '../../hooks/access.hook';

const ListResourcesContent = () => {
  const { hasOperationAccess } = useAccess();
  const { accessData } = useContext(AccessContext);
  const columns = LISTING_RESOURCES_COLUMNS;
  const actions = useFilteredActions(
    accessData.role,
    'resources',
    LISTING_RESOURCES_ACTIONS
  );

  const renderActions = [];
  actions.forEach((item) => {
    renderActions.push(item);
  });

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [imagePreview, setImagePreview] = useState({
    show: false,
    squareImageUrl: '',
    title: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });

    setImagePreview({
      ...imagePreview,
      show: false,
    });
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteDataById(doAction.data.id)
      .then(() => {
        handleActionSuccess(
          `${CONTENT_ALIAS.RESOURCES_SINGULAR} deleted successfully.`
        );
        handleActionCancel();
      })
      .catch((e) => {
        const message =
          e.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleImagePreview = (rowData) => {
    setImagePreview({
      ...imagePreview,
      show: true,
      squareImageUrl: rowData.squareBanner || null,
      horizontalImageUrl: rowData.horizBanner || null,
      title: rowData.title,
    });
  };

  const dataListAPICall = () => {
    let uri = 'resources';
    uri += `?page=${options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      uri += `&q=${options.q}`;
    }

    setOptions({ ...options, loading: true, searchValue: '' });

    getDataList(uri)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  };

  useEffect(() => {
    dataListAPICall(options);
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          {hasOperationAccess(accessData.role, 'resources', 'create') && (
            <div
              style={{
                whiteSpace: 'no-wrap',
                overflow: 'hidden',
                width: '100%',
              }}
            >
              <Button
                color="primary"
                variant="contained"
                startIcon={<Iconify icon="ic:round-add" />}
                onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
              >
                Create New
              </Button>
            </div>
          )}
        </div>

        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={renderActions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
          handleImagePreview={handleImagePreview}
        />
      </Card>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditResources
          title={`Add New ${CONTENT_ALIAS.RESOURCES_SINGULAR}`}
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditResources
          title={`Edit ${CONTENT_ALIAS.RESOURCES_SINGULAR} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewResources
          title={`${CONTENT_ALIAS.RESOURCES_SINGULAR} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.UPLOAD_POSTER.value && (
        <UploadResorceAssets
          title={`${CONTENT_ALIAS.RESOURCES_SINGULAR} - Upload Posters - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.TRANSLATE.value && (
        <TranslateResourceContent
          title={`Translate ${CONTENT_ALIAS.POPULAR_CONTENT_SINGULAR} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title={`Delete ${CONTENT_ALIAS.RESOURCES_SINGULAR}`}
          message={`Do you want to delete '${doAction.data.title}' ${CONTENT_ALIAS.RESOURCES_SINGULAR}? You can not undo this action!`}
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {imagePreview.show && (
        <ImagePreview
          title={`Thumbnail - ${imagePreview.title}`}
          onCancel={handleActionCancel}
          imageUrl={imagePreview.squareImageUrl}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListResourcesContent;
