import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  Stack,
  Chip,
} from '@mui/material';
import {
  getSearchListCategories,
  getSubscribedCategories,
} from '../../services/content.service';
import { MULTIPLE_CAT_PER_VIDEO } from '../../config/const.config';
import { formatTimestamp } from '../../utils/datetime.util';

const Categories = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  sx,
  error,
  disabled,
  subscriberId,
  type,
  categoryType,
  showAll,
}) => {
  const selectedValue = [];

  if (MULTIPLE_CAT_PER_VIDEO) {
    if (typeof defaultValue === 'object') {
      defaultValue.forEach((item) => {
        selectedValue.push(item);
      });
    }
  }

  const [booksData, setBooksData] = useState([]);

  useEffect(() => {
    if (subscriberId) {
      getSubscribedCategories(subscriberId)
        .then((res) => {
          setBooksData(res.data);
        })
        .catch(() => {
          // nothing
        });
    } else if (type === 'paid') {
      getSearchListCategories(type)
        .then((res) => {
          setBooksData(res.data);
        })
        .catch(() => {
          // nothing
        });
    } else {
      getSearchListCategories('', categoryType)
        .then((res) => {
          if (showAll) {
            setBooksData([{ id: 0, title: 'All' }, ...res.data]);
          } else {
            setBooksData(res.data);
          }
        })
        .catch(() => {
          // nothing
        });
    }
  }, [subscriberId]);

  return (
    <FormControl fullWidth error={error !== ''}>
      <InputLabel id={`field-${id}-select-label`}>{label}</InputLabel>

      {MULTIPLE_CAT_PER_VIDEO && (
        <Select
          multiple
          labelId="demo-simple-select-label"
          id={id}
          name={name}
          value={selectedValue}
          label={label}
          onChange={onChange}
          sx={sx}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((value) => (
                <Chip key={value} label={value.split('] - ')[1]} />
              ))}
            </Stack>
          )}
        >
          {booksData.map((k) => (
            <MenuItem
              key={`${id}-v-${k.id}`}
              value={`[${k.id}] - ${k.title} (${k.tabName})`}
            >
              {`${k.title} (${k.tabName})`}
            </MenuItem>
          ))}
        </Select>
      )}

      {!MULTIPLE_CAT_PER_VIDEO && (
        <Select
          labelId="demo-simple-select-label"
          id={id}
          name={name}
          value={defaultValue}
          label={label}
          onChange={onChange}
          sx={sx}
          disabled={disabled}
        >
          {booksData.map((k) => (
            <MenuItem key={`${id}-v-${k.id}`} value={`${k.id}`}>
              {subscriberId
                ? `${k.courseName} (${formatTimestamp(
                    k.subscriptionEndDate,
                    'YYYY-MM-DD'
                  )})`
                : `${k.title}`}
            </MenuItem>
          ))}
        </Select>
      )}

      {error !== '' && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

Categories.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  disabled: PropTypes.number,
  subscriberId: PropTypes.number,
  type: PropTypes.string,
  categoryType: PropTypes.string,
  showAll: PropTypes.bool,
};
Categories.defaultProps = {
  sx: {},
  error: '',
  disabled: null,
  subscriberId: 0,
  type: '',
  categoryType: '',
  showAll: false,
};

export default Categories;
