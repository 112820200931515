import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewPopularContent,
  updatePopularContentById,
  viewPopularContentById,
} from '../../services/content.service';
import Status from '../form-elements/status.component';
import {
  CONTENT_ALIAS,
  POPULAR_CONTENT_TYPES,
} from '../../config/const.config';
import PopularContentTypes from '../form-elements/popular-types.component';
import Content from '../form-elements/content.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
const AddEditPopularContent = ({
  title,
  dataId,
  onCancel,
  onSuccess,
  options,
}) => {
  const {
    control,
    formState: { errors },
    watch,
    unregister,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      status: null,
      squareBanner: '',
      horizBanner: '',
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const contentTypeWatch = watch('contentType');

  const saveNewData = (payload) => {
    createNewPopularContent(payload)
      .then(() => {
        onSuccess(
          `New ${CONTENT_ALIAS.POPULAR_CONTENT_SINGULAR} added successfully.`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updatePopularContentById(dataId, payload)
      .then(() => {
        onSuccess(
          `${CONTENT_ALIAS.POPULAR_CONTENT_SINGULAR} details updated successfully.`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const payload = {
      title: data.title || '',
      contentType: data.contentType || '',
      contentId: Number(data.content) || null,
      displaySequence: 1,
      status: data.status,
      squareBanner: data.squareBannerUrl || '',
      horizBanner: data.horizontalBannerUrl || '',
    };

    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (contentTypeWatch === POPULAR_CONTENT_TYPES.PROGRAM.value) {
      unregister('content');
    } else if (contentTypeWatch === POPULAR_CONTENT_TYPES.COURSE.value) {
      unregister('course');
    } else if (contentTypeWatch === POPULAR_CONTENT_TYPES.TAO_SONG.value) {
      unregister('taosong');
    } else if (contentTypeWatch === POPULAR_CONTENT_TYPES.LIVE_EVENT.value) {
      unregister('liveevent');
    } else if (contentTypeWatch === POPULAR_CONTENT_TYPES.BLESSINGS.value) {
      unregister('blessing');
    }
  }, [contentTypeWatch]);

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewPopularContentById(dataId)
      .then((res) => {
        // Fill form values

        setValue('title', res.data?.title || '');
        setValue('contentType', res.data?.contentType || null);
        setValue('content', res.data?.contentId || 0);
        setValue('status', res.data?.status || 0);
        setValue('squareBannerUrl', res.data?.squareBanner || '');
        setValue('horizontalBannerUrl', res.data?.horizBanner || '');

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={`${CONTENT_ALIAS.POPULAR_CONTENT_SINGULAR} Title`}
                      type="text"
                      error={!!errors.title}
                      helperText={errors?.title?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="contentType"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <PopularContentTypes
                      id="contentType"
                      name="contentType"
                      label="Content Type"
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.contentType?.message || ''}
                      disabled={!!dataId}
                    />
                  )}
                />
              </Grid>
              {contentTypeWatch === POPULAR_CONTENT_TYPES.PROGRAM.value && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="content"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Content
                        id="program"
                        name="program"
                        label={`Select ${CONTENT_ALIAS.PROGRAMS_SINGULAR}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.content?.message || ''}
                        options={options}
                        disabled={!!dataId}
                        type="PROGRAM"
                      />
                    )}
                  />
                </Grid>
              )}
              {contentTypeWatch ===
                POPULAR_CONTENT_TYPES.TAO_SOUL_PROGRAM.value && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="content"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Content
                        id="program"
                        name="program"
                        label={`Select ${CONTENT_ALIAS.TAO_SOUL_PROGRAM_SINGULAR}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.content?.message || ''}
                        options={options}
                        disabled={!!dataId}
                        type={POPULAR_CONTENT_TYPES.TAO_SOUL_PROGRAM.value}
                      />
                    )}
                  />
                </Grid>
              )}
              {contentTypeWatch === POPULAR_CONTENT_TYPES.COURSE.value && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="content"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Content
                        id="course"
                        name="course"
                        label={`Select ${CONTENT_ALIAS.COURSES_SINGULAR}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.content?.message || ''}
                        options={options}
                        disabled={!!dataId}
                        type="COURSE"
                      />
                    )}
                  />
                </Grid>
              )}
              {contentTypeWatch === POPULAR_CONTENT_TYPES.TAO_SONG.value && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="content"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Content
                        id="taosong"
                        name="taosong"
                        label={`Select ${CONTENT_ALIAS.TAOSONG_SINGULAR}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.content?.message || ''}
                        options={options}
                        disabled={!!dataId}
                        type="TAO_SONG"
                      />
                    )}
                  />
                </Grid>
              )}
              {contentTypeWatch === POPULAR_CONTENT_TYPES.MEDITATION.value && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="content"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Content
                        id="meditation"
                        name="meditation"
                        label={`Select ${CONTENT_ALIAS.MEDITATION_SINGULAR}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.content?.message || ''}
                        options={options}
                        disabled={!!dataId}
                        type="MEDITATION"
                      />
                    )}
                  />
                </Grid>
              )}

              {contentTypeWatch === POPULAR_CONTENT_TYPES.LIVE_EVENT.value && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="content"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Content
                        id="liveevent"
                        name="liveevent"
                        label={`Select ${CONTENT_ALIAS.LIVE_EVENT_SINGULAR}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.content?.message || ''}
                        options={options}
                        disabled={!!dataId}
                        type="LIVE_EVENT"
                      />
                    )}
                  />
                </Grid>
              )}

              {contentTypeWatch === POPULAR_CONTENT_TYPES.BLESSINGS.value && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="content"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Content
                        id="blessing"
                        name="blessing"
                        label={`Select ${CONTENT_ALIAS.BLESSING_SINGULAR}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.content?.message || ''}
                        options={options}
                        disabled={!!dataId}
                        type="BLESSINGS"
                      />
                    )}
                  />
                </Grid>
              )}

              {!contentTypeWatch && <Grid item xs={12} sm={6} md={6} />}

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="sub_status"
                      name="sub_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} />

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="squareBannerUrl"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(250) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Square Banner Url"
                      type="text"
                      error={!!errors.squareBannerUrl}
                      helperText={
                        errors?.squareBannerUrl?.message ||
                        "You can upload image from 'Upload Poster' option too from list."
                      }
                      fullWidth
                      inputProps={{ maxLength: 250 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="horizontalBannerUrl"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(250) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Horizontal Banner Url"
                      type="text"
                      error={!!errors.horizontalBannerUrl}
                      helperText={
                        errors?.horizontalBannerUrl?.message ||
                        "You can upload image from 'Upload Poster' option too from list."
                      }
                      fullWidth
                      inputProps={{ maxLength: 250 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditPopularContent.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
};

export default AddEditPopularContent;
