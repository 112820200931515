import CELL_DATA_TYPES from '../cell-types.config';

const LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Title',
    width: '20%',
    align: 'left',
    dataKey: 'categoryName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'Username',
    label: 'Subscribers Name',
    width: '20%',
    align: 'left',
    dataKey: 'User Name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'startDate',
    label: 'Registration Date',
    width: '15%',
    align: 'left',
    dataKey: 'registrationDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export default LISTING_COLUMNS;
