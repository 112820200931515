import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Slide,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import {
  ARTICLE_TYPE,
  CATEGORY_TYPE,
  CONTENT_ALIAS,
} from '../../config/const.config';
import { AccessContext } from '../../context/access.context';
import useAccess from '../../hooks/access.hook';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ListSequenceVideos = ({
  handleOnDragEndVideos,
  handleUpdateVideosSequence,
  action,
  selectedCategory,
  categoryVideosList,
  vidOptions,
  handleCloseDialog,
  type,
}) => {
  const dropDisabled = true;
  const { accessData } = useContext(AccessContext);
  const { hasOperationAccess } = useAccess();

  const handleDropDisable = () => {
    if (
      type === CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL &&
      !hasOperationAccess(accessData.role, 'sequence_article_contents', 'edit')
    ) {
      return dropDisabled;
    }
    if (
      type === CATEGORY_TYPE[0].id &&
      !hasOperationAccess(accessData.role, 'sequence_courses', 'edit_video')
    ) {
      return dropDisabled;
    }
    if (
      type === CATEGORY_TYPE[1].id &&
      !hasOperationAccess(accessData.role, 'sequence_programs', 'edit_video')
    ) {
      return dropDisabled;
    }
    if (
      type === CONTENT_ALIAS.LINK_SECTION &&
      !hasOperationAccess(
        accessData.role,
        'sequence_link_section',
        'edit_video'
      )
    ) {
      return dropDisabled;
    }

    return !dropDisabled;
  };

  return (
    <Dialog
      open={action.show}
      aria-describedby="category-videos"
      aria-labelledby="category-videos"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {type === 'Link Section'
          ? 'Reorder Link Section Items Sequence'
          : `Reorder ${
              selectedCategory.categoryName === ARTICLE_TYPE[0].label ||
              selectedCategory.categoryName === ARTICLE_TYPE[1].label
                ? CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL
                : CONTENT_ALIAS.VIDEO_PLURAL
            } Sequence - ${selectedCategory.categoryName}`}
      </DialogTitle>
      <DialogContent dividers>
        {vidOptions.loading && <Loading />}

        {!vidOptions.loading && (
          <DragDropContext onDragEnd={handleOnDragEndVideos}>
            <Droppable
              droppableId="videos"
              isDropDisabled={handleDropDisable()}
            >
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {!vidOptions.loading &&
                    categoryVideosList.length > 0 &&
                    categoryVideosList.map((video, index) =>
                      !handleDropDisable() ? (
                        <Draggable
                          key={`video-${video.id}`}
                          draggableId={video.id.toString()}
                          index={index}
                        >
                          {(provide) => (
                            <Card
                              sx={{
                                marginBottom: '5px',
                              }}
                              ref={provide.innerRef}
                              {...provide.draggableProps}
                              {...provide.dragHandleProps}
                            >
                              <CardContent
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  paddingY: '12px',
                                  '&:last-child': {
                                    paddingBottom: '12px',
                                  },
                                }}
                              >
                                <div>{video.title}</div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                >
                                  <div>
                                    {video.videoType}
                                    {video.transmissionOrgan
                                      ? ` (${video.transmissionOrgan})`
                                      : ''}
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      marginLeft: 20,
                                    }}
                                  >
                                    <Iconify icon="ic:round-table-rows" />
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          )}
                        </Draggable>
                      ) : (
                        <Card
                          key={`video-${video.id}`}
                          sx={{
                            marginBottom: '5px',
                          }}
                        >
                          <CardContent
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              paddingY: '12px',
                              '&:last-child': {
                                paddingBottom: '12px',
                              },
                            }}
                          >
                            <div style={{ width: '75%' }}>{video.title}</div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textAlign: 'center',
                              }}
                            >
                              <div>
                                {video.videoType}
                                {video.transmissionOrgan
                                  ? ` (${video.transmissionOrgan})`
                                  : ''}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 20,
                                }}
                              >
                                <Iconify icon="ic:round-table-rows" />
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      )
                    )}

                  {!vidOptions.loading && categoryVideosList.length === 0 && (
                    <Paper
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      No data available.
                    </Paper>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </DialogContent>

      {!vidOptions.loading && (
        <DialogActions>
          {categoryVideosList.length > 0 && !handleDropDisable() && (
            <Button
              onClick={handleUpdateVideosSequence}
              disabled={
                categoryVideosList.length === 0 ||
                categoryVideosList.length === 1
              }
            >
              Save
            </Button>
          )}
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

ListSequenceVideos.propTypes = {
  handleOnDragEndVideos: PropTypes.func.isRequired,
  handleUpdateVideosSequence: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
  selectedCategory: PropTypes.object.isRequired,
  categoryVideosList: PropTypes.array.isRequired,
  vidOptions: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default ListSequenceVideos;
