import React, { useState } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TablePagination,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Typography,
  Button,
  Collapse,
} from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../common/iconify.component';
import Sortable from './sortable.component';
import NoData from './no-data.component';
import Error from './error.component';
import Loading from './loading.component';
import CellValue from './cell-value.component';
import CELL_DATA_TYPES from '../../config/cell-types.config';
import ROUTE_PATH from '../../config/routes.config';

const ListData = ({
  columns,
  rows,
  page,
  rowsPerPage,
  totalRows,
  loading,
  actions,
  error,
  sortBy,
  sortOrder,
  onPageChange,
  onRowsPerPageChange,
  onSortChange,
  onAction,
  onCustomClick,
  handleImagePreview,
  handleToggleStatus,
  handleToggleFeatured,
  handleShowSubList,
}) => {
  const colSpan = columns.length + (actions.length ? 1 : 0);

  const [anchorActionEl, setAnchorActionEl] = useState(null);
  const [openActions, setOpenActions] = useState({
    show: false,
    data: null,
  });

  const handleActionOpen = (e, selectedRow) => {
    setAnchorActionEl(e.currentTarget);
    setOpenActions({
      ...openActions,
      show: true,
      data: selectedRow,
    });
  };
  const handleActionClose = () => {
    setAnchorActionEl(null);
    setOpenActions({
      ...openActions,
      show: false,
      data: null,
    });
  };
  const handleActionSelect = (selectedAction) => {
    onAction(selectedAction, openActions.data);
    handleActionClose();
  };
  const handleSingleActionSelect = (data) => {
    onAction(actions[0].action, data);
    handleActionClose();
  };

  let cntr = 1;

  const newCounter = () => {
    cntr += 1;
    return cntr;
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((c) => (
                <TableCell
                  key={`col-${c.id}`}
                  align={c.align}
                  width={c.width}
                  sx={{
                    cursor: c.sortable ? 'pointer' : 'inherit',
                  }}
                  onClick={() => {
                    if (c.sortable && !loading) {
                      if (c.dataKey === sortBy) {
                        if (sortOrder === '') {
                          onSortChange(c.dataKey, 'asc');
                        } else if (sortOrder === 'asc') {
                          onSortChange(c.dataKey, 'desc');
                        } else if (sortOrder === 'desc') {
                          onSortChange('', '');
                        }
                      } else {
                        onSortChange(c.dataKey, 'asc');
                      }
                    }
                  }}
                >
                  <Stack direction="row" justifyContent={c.align}>
                    {c.sortable && (
                      <Sortable
                        dataKey={c.dataKey}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                      />
                    )}
                    {c.label}
                  </Stack>
                </TableCell>
              ))}

              {actions.length > 0 && (
                <TableCell align="right" width="5%">
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {!loading && !error && (
              <>
                {rows.map((r) => (
                  <>
                    <TableRow key={`row-${newCounter()}`}>
                      {columns.map((c) => (
                        <TableCell
                          key={`data-col-${c.id}-${newCounter()}`}
                          align={c.align}
                          width={c.width}
                          sx={{
                            paddingY: '12px',
                            cursor:
                              (c.dataKey === 'preview' &&
                                r.videoUrl &&
                                r.cdnStatus === 'Finished') ||
                              (c.dataKey === 'recordedPreview' && r.Url) ||
                              (c.dataKey === 'totalRegistrations' &&
                                r.totalRegistrations !== 0 &&
                                window.location.pathname ===
                                  ROUTE_PATH.REPORTS_LIVE_EVENT) ||
                              (c.dataKey === 'totalJoined' &&
                                r.totalJoined !== 0 &&
                                window.location.pathname ===
                                  ROUTE_PATH.REPORTS_LIVE_EVENT) ||
                              (c.dataKey === 'thumbnail' && r.thumbnail) ||
                              (c.dataKey === 'posterUrl' && r.posterUrl) ||
                              (c.dataKey === 'bannerImageUrl' &&
                                r.bannerImageUrl) ||
                              (c.dataKey === 'poster' && r.poster)
                                ? 'pointer'
                                : 'default',
                          }}
                          onClick={() => {
                            if (
                              (c.dataKey === 'preview' &&
                                r.videoUrl &&
                                r.cdnStatus === 'Finished') ||
                              (c.dataKey === 'recordedPreview' && r.Url) ||
                              (c.dataKey === 'totalRegistrations' &&
                                r.totalRegistrations !== 0 &&
                                window.location.pathname ===
                                  ROUTE_PATH.REPORTS_LIVE_EVENT) ||
                              (c.dataKey === 'totalJoined' &&
                                r.totalJoined !== 0 &&
                                window.location.pathname ===
                                  ROUTE_PATH.REPORTS_LIVE_EVENT)
                            ) {
                              onCustomClick(r);
                            } else if (
                              (c.dataKey === 'thumbnail' && r.thumbnail) ||
                              (c.dataKey === 'posterUrl' && r.posterUrl) ||
                              (c.dataKey === 'bannerImageUrl' &&
                                r.bannerImageUrl) ||
                              (c.dataKey === 'poster' && r.poster)
                            ) {
                              handleImagePreview(r);
                            }
                          }}
                        >
                          <CellValue
                            type={c.type}
                            value={r[c.dataKey]}
                            isRecurring={r.isRecurring}
                            primaryProfileUser={r?.subscriberID || null}
                            requestedProfileUser={r?.requestedForId || null}
                            duration={
                              c.dataKey === 'recurringTimestamp'
                                ? r.duration
                                : 0
                            }
                            cdnStatus={r.cdnStatus}
                            fallbackValue={(e) => {
                              if (
                                c.dataKey === 'status' &&
                                c.type === CELL_DATA_TYPES.SWITCH
                              ) {
                                handleToggleStatus(r, e.target.checked);
                              } else if (
                                c.dataKey === 'isFeatured' &&
                                c.type === CELL_DATA_TYPES.SWITCH
                              ) {
                                handleToggleFeatured(r, e.target.checked);
                              }
                            }}
                            disabledToggle={
                              c.dataKey === 'status' &&
                              (r.paymentType === 'INITIAL' ||
                                r.paymentType === 'RECURRING')
                            }
                            hasSubRows={r?.subRows?.length > 0}
                            handleShowSubList={() => handleShowSubList(r)}
                          />
                        </TableCell>
                      ))}

                      {actions.length > 1 && (
                        <TableCell
                          align="right"
                          width="5%"
                          sx={{ paddingY: '12px' }}
                        >
                          <IconButton
                            size="small"
                            color="inherit"
                            onClick={(e) => handleActionOpen(e, r)}
                          >
                            <Iconify icon="eva:more-vertical-fill" />
                          </IconButton>
                        </TableCell>
                      )}

                      {actions.length === 1 && (
                        <TableCell
                          align="right"
                          width="5%"
                          sx={{ paddingY: '12px' }}
                        >
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => handleSingleActionSelect(r)}
                          >
                            {actions[0].label}
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                    {!r?.subRowsLoading &&
                      r?.subRows?.length > 0 &&
                      r.subRows.map((sr) => (
                        <TableRow
                          sx={{
                            backgroundColor: '#ebebeb',
                            boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.2)',
                          }}
                        >
                          {columns.map((c) => (
                            <TableCell
                              key={`data-col-${c.id}-${newCounter()}`}
                              align={c.align}
                              width={c.width}
                              sx={{ paddingY: '2px' }}
                            >
                              <Collapse in timeout="auto" unmountOnExit>
                                <CellValue
                                  type={
                                    c.type === CELL_DATA_TYPES.STATUS
                                      ? CELL_DATA_TYPES.TEXT
                                      : c.type
                                  }
                                  value={sr[c.dataKey]}
                                  cdnStatus={sr.cdnStatus}
                                />
                              </Collapse>
                            </TableCell>
                          ))}
                          <TableCell />
                        </TableRow>
                      ))}
                    {r?.subRowsLoading && (
                      <TableRow
                        sx={{
                          backgroundColor: '#ebebeb',
                          boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.2)',
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={colSpan}
                          sx={{ paddingY: '2px' }}
                        >
                          <Loading minHeight={100} />
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))}
              </>
            )}

            {!loading && !error && totalRows === 0 && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={colSpan}
                  sx={{ paddingY: '12px' }}
                >
                  <NoData />
                </TableCell>
              </TableRow>
            )}

            {!loading && error && totalRows === 0 && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={colSpan}
                  sx={{ paddingY: '12px' }}
                >
                  <Error />
                </TableCell>
              </TableRow>
            )}

            {loading && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={colSpan}
                  sx={{ paddingY: '12px' }}
                >
                  <Loading />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!loading && totalRows > 0 && (
        <TablePagination
          component="div"
          count={totalRows}
          page={page}
          onPageChange={(e, newPage) => onPageChange(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(e) => {
            onRowsPerPageChange(Number(e.target.value));
          }}
        />
      )}

      {openActions.show && (
        <Menu
          id="actions-menu"
          anchorEl={anchorActionEl}
          open
          onClose={() => handleActionClose()}
          size="small"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {actions.map((a, index) => {
            let render = true;
            if (a.conditional) {
              render = a.conditional(openActions.data);
            }

            if (render) {
              return (
                <MenuItem
                  key={`mia-${a.action}-${index}`}
                  onClick={() => handleActionSelect(a.action)}
                >
                  <Typography variant="body2">{a.label}</Typography>
                </MenuItem>
              );
            }

            return null;
          })}
        </Menu>
      )}
    </>
  );
};

ListData.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  error: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  onCustomClick: PropTypes.func,
  handleImagePreview: PropTypes.func,
  handleToggleStatus: PropTypes.func,
  handleToggleFeatured: PropTypes.func,
  handleShowSubList: PropTypes.func,
};
ListData.defaultProps = {
  onAction: () => {},
  onCustomClick: () => {},
  handleImagePreview: () => {},
  handleToggleStatus: () => {},
  handleToggleFeatured: () => {},
  handleShowSubList: () => {},
};

export default ListData;
