import React from 'react';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListResourcesContent from '../../components/content/list-resources-content.component';

const ResourcesContentView = () => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_RESOURCES_CONTENT}`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListResourcesContent />
    </AppLayout>
  );
};

ResourcesContentView.propTypes = {};

export default ResourcesContentView;
