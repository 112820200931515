import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { Card, Stack, Button } from '@mui/material';
import {
  LISTING_RECORDED_MESSAGE_COLUMNS,
  LISTING_RECORDED_MESSAGE_ACTIONS,
} from '../../config/module-configs/recorded-message.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import {
  deleteRecordedMessageById,
  getDataList,
} from '../../services/content.service';
import { ACTIONS, CONTENT_ALIAS } from '../../config/const.config';
import ConfirmPopup from '../common/confirm-popup.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ViewRecordedMessage from './view-recorded-message.component';
import AddEditRecordeMessage from './add-edit-recorded-message.component';
import ImagePreview from './image-preview.component';
import VideoPreview from './videos-preview.component';
import AudioPreview from './audio-preview.component';
import useFilteredActions from '../../hooks/filtered-action.hook';
import { AccessContext } from '../../context/access.context';
import PosterUpload from './poster-upload.component';

const ListRecordedMessage = ({ type }) => {
  const columns = LISTING_RECORDED_MESSAGE_COLUMNS;
  const { accessData } = useContext(AccessContext);
  const conditionalColumns = columns.filter((item) =>
    type === 'AUDIO' ? item.dataKey !== 'posterUrl' : item
  );
  const actions = useFilteredActions(
    accessData.role,
    type === 'AUDIO' ? 'ms_voice_recorded_audios' : 'ms_voice_recorded_videos',
    LISTING_RECORDED_MESSAGE_ACTIONS
  );

  const renderActions = [];
  actions.forEach((item) => {
    renderActions.push(item);
  });

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [imagePreview, setImagePreview] = useState({
    show: false,
    imageUrl: '',
    title: '',
  });
  const [videoPlayer, setVideoPlayer] = useState({
    show: false,
    videoUrl: '',
    title: '',
  });
  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleClickEvent = (video) => {
    setVideoPlayer({
      ...videoPlayer,
      show: true,
      title: video.title,
      videoUrl: video.Url,
    });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });

    setImagePreview({
      ...imagePreview,
      show: false,
    });
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteRecordedMessageById(doAction.data.id, type)
      .then(() => {
        handleActionSuccess(
          `${CONTENT_ALIAS.RESOURCES_SINGULAR} deleted successfully.`
        );
        handleActionCancel();
      })
      .catch((e) => {
        const message =
          e.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleImagePreview = (rowData) => {
    setImagePreview({
      ...imagePreview,
      show: true,
      imageUrl: rowData.posterUrl,
      title: rowData.title,
    });
  };

  const dataListAPICall = () => {
    let uri = 'record-message';
    uri += `/${type}`;
    uri += `?page=${options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      uri += `&q=${options.q}`;
    }

    setOptions({ ...options, loading: true, searchValue: '' });

    getDataList(uri)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  };

  useEffect(() => {
    dataListAPICall(options);
  }, [options.reloadCounter, type]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <div />
        </div>

        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={conditionalColumns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={renderActions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onCustomClick={handleClickEvent}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
          handleImagePreview={handleImagePreview}
        />
      </Card>

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditRecordeMessage
          title={`Edit ${CONTENT_ALIAS.RECORDED_MESSAGE} ${
            type === 'VIDEO' ? 'Video' : 'Audio'
          } Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          options={options}
          type={type}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewRecordedMessage
          title={`${CONTENT_ALIAS.RECORDED_MESSAGE} ${
            type === 'VIDEO' ? 'Video' : 'Audio'
          } Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          type={type}
        />
      )}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title={`Delete ${CONTENT_ALIAS.RECORDED_MESSAGE}`}
          message={`Do you want to delete '${doAction.data.title}' ${CONTENT_ALIAS.RECORDED_MESSAGE} Message? You can not undo this action!`}
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {videoPlayer.show && type === 'AUDIO' && (
        <AudioPreview
          audioPlayer={videoPlayer}
          setAudioPlayer={setVideoPlayer}
        />
      )}

      {videoPlayer.show && type === 'VIDEO' && (
        <VideoPreview
          videoPlayer={videoPlayer}
          setVideoPlayer={setVideoPlayer}
        />
      )}

      {imagePreview.show && (
        <ImagePreview
          title={`Thumbnail - ${imagePreview.title}`}
          onCancel={handleActionCancel}
          imageUrl={imagePreview.imageUrl}
        />
      )}

      {doAction.action === ACTIONS.UPLOAD_POSTER.value && type === 'VIDEO' && (
        <PosterUpload
          title={`Upload Poster - ${doAction.data.name}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          imageUrl={doAction.data.posterUrl}
          isMSVoice
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ListRecordedMessage.propTypes = { type: PropTypes.string.isRequired };

export default ListRecordedMessage;
