import React from 'react';
import { Grid } from '@mui/material';
import AppLayout from '../../layout/app.layout';
import LiveEventReportChart from '../../components/reports/live-event-report-chart.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ReportsLiveEventView = () => {
  const pageTitle = PAGE_TITLE_LIST.REPORTS_LIVE_EVENT;

  return (
    <AppLayout pageTitle={pageTitle}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <LiveEventReportChart />
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default ReportsLiveEventView;
