import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useRoutes, Navigate } from 'react-router-dom';
import ROUTE_PATH from '../config/routes.config';
import LoginView from '../views/auth/login.view';
import DashboardView from '../views/dashboard/dashboard.view';
import RealtimeDashboardView from '../views/dashboard/realtime-dashboard.view';
import ContentVideosView from '../views/content/videos.view';
import PopularContentView from '../views/content/popular-content.view';
import ContentSequenceView from '../views/content/content-sequence.view';
import SubscribersView from '../views/subscribers/subscribers.view';
import SubscriptionsView from '../views/subscribers/subscriptions.view';
import AdminUsersView from '../views/admin-users/admin-users.view';
import ReportsOverviewView from '../views/reports/overview.view';
import ReportsLiveUsersView from '../views/reports/live-users.view';
import ReportsSessionsView from '../views/reports/sessions.view';
import ReportsSubscriptionsView from '../views/reports/subscriptions-summary.view';
import PageNotFoundView from '../views/errors/not-found.view';
import LogoutView from '../views/auth/logout.view';
import NotificationsView from '../views/settings/notification.view';
import SalutationView from '../views/settings/salutation.view';
import ContentCategoriesView from '../views/content/categories.view';
import ContentTestimonialTabsView from '../views/content/testimonial-tabs.view';
import ContentTestimonialCategoriesView from '../views/content/testimonial-categories.view';
import ContentTestimonialTextView from '../views/content/testimonial-text.view';
import ContentUserTestimonialTextView from '../views/content/user-testimonial-text.view';
import ContentUserTestimonialVideoView from '../views/content/user-testimonial-video.view';
import ContentVideoCommentsView from '../views/content/video-comments.view';
import ContentTransmissionOrgansView from '../views/content/transmission-organs.view';
import RecordedMessageView from '../views/content/recorded-message.view';
import LinkSectionsView from '../views/content/link-sections.view';
import LinkSectionItemsView from '../views/content/link-section-items.view';
import {
  SHOW_ADMIN_USERS,
  SHOW_CONTENT_SECTION,
  SHOW_CONTENT_CATEGORIES,
  SHOW_CONTENT_SEQUENCE,
  SHOW_CONTENT_VIDEOS,
  SHOW_NOTIFICATIONS,
  SHOW_OVERVIEW_DASHBOARD,
  SHOW_REALTIME_DASHBOARD,
  SHOW_REPORTS_LIVE_USERS,
  SHOW_REPORTS_OVERVIEW_VIDEOS,
  SHOW_REPORTS_SUBSCRIPTIONS,
  SHOW_REPORTS_WATCH_SESSIONS,
  SHOW_SALUTATION,
  SHOW_SUBSCRIBERS,
  SHOW_SUBSCRIPTIONS,
  SHOW_TESTIMONIALS,
  SHOW_VIDEO_COMMENTS,
  SHOW_GROUPS,
  SHOW_CONTENT_COMMUNITY_GATHERINGS,
  SHOW_PACKAGES,
  SHOW_CONTENT_POPULAR,
  SHOW_ARTICLE,
  VIDEO_TYPES,
  CATEGORY_TYPE,
  CONTENT_ALIAS,
  SHOW_CONTENT_TRANSMISSION_ORGANS,
  SHOW_CONTENT_TRANSMISSION_PROGRAM_REQUEST,
  SHOW_REPORTS_LIVE_EVENT,
  SHOW_REPORTS_PROGRAMS,
  SHOW_CONTENT_RESOURCES,
  SHOW_RECORD_MESSAGE,
  SHOW_SECTION_LINKS,
  SHOW_COURSE_REPORT,
} from '../config/const.config';
import GroupsView from '../views/groups/groups.view';
import PackagesView from '../views/packages/packages.view';
import ContentSectionsView from '../views/content/sections.view';
import ContentCommunityGatheringView from '../views/content/community-gathering.view';
import ArticleContent from '../views/content/article-content.view';
import ArticleCategories from '../views/content/article-categories.view';
import VideoSequenceView from '../views/content/video-sequence.view';
import ManageMembersView from '../views/groups/manage-members.view';
import EventRegistrationsView from '../views/content/event-registrations.view';
import ContentTransmissionProgramRequestView from '../views/content/transmission-program-request.view';
import ManageRecuringEventView from '../views/content/manage-recuring-event.view';
import ReportsLiveEventView from '../views/reports/live-event.view';
import ReportsProgramView from '../views/reports/program-report.view';
import ResourcesContentView from '../views/content/resources-content.view';
import useAccess from '../hooks/access.hook';
import { AccessContext } from '../context/access.context';
import CourseReportView from '../views/reports/course-report.view';

const AppRouter = ({ isLoggedIn }) => {
  const { accessData } = useContext(AccessContext);
  const { hasOperationAccess } = useAccess();
  const routeMappings = [];

  if (isLoggedIn) {
    routeMappings.push({
      path: ROUTE_PATH.LOGIN,
      element: <Navigate to={ROUTE_PATH.DASHBOARD} />,
      exact: true,
    });

    if (
      SHOW_OVERVIEW_DASHBOARD &&
      hasOperationAccess(accessData.role, 'dashboard', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.DASHBOARD,
        element: <DashboardView />,
        exact: true,
      });
    }

    if (SHOW_REALTIME_DASHBOARD) {
      routeMappings.push({
        path: ROUTE_PATH.REALTIME_DASHBOARD,
        element: <RealtimeDashboardView />,
        exact: true,
      });
    }

    if (
      SHOW_SUBSCRIBERS &&
      hasOperationAccess(accessData.role, 'subscribers', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.SUBSCRIBERS,
        element: <SubscribersView />,
        exact: true,
      });
    }

    if (
      SHOW_SUBSCRIPTIONS &&
      hasOperationAccess(accessData.role, 'subscriptions', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.SUBSCRIPTIONS,
        element: <SubscriptionsView />,
        exact: true,
      });
    }

    if (
      SHOW_PACKAGES &&
      hasOperationAccess(accessData.role, 'packages', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.PACKAGES,
        element: <PackagesView />,
        exact: true,
      });
    }

    if (
      SHOW_GROUPS &&
      hasOperationAccess(accessData.role, 'community_groups', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.GROUPS,
        element: <GroupsView />,
        exact: true,
      });
      if (hasOperationAccess(accessData.role, 'manage_members', 'view')) {
        routeMappings.push({
          path: ROUTE_PATH.GROUPS_MANAGE_MEMBERS,
          element: <ManageMembersView />,
          exact: true,
        });
      }
    }

    if (
      SHOW_ADMIN_USERS &&
      hasOperationAccess(accessData.role, 'users', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.ADMIN_USERS,
        element: <AdminUsersView />,
        exact: true,
      });
    }

    if (
      SHOW_CONTENT_SECTION &&
      hasOperationAccess(accessData.role, 'program_course_group', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_SECTIONS,
        element: <ContentSectionsView />,
        exact: true,
      });
    }

    if (
      SHOW_CONTENT_CATEGORIES &&
      hasOperationAccess(accessData.role, 'program_courses', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_CATEGORIES,
        element: <ContentCategoriesView />,
        exact: true,
      });
    }

    if (
      SHOW_CONTENT_VIDEOS &&
      hasOperationAccess(accessData.role, 'videos', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS,
        element: <ContentVideosView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS_EVENT_REGISTRATIONS,
        element: <EventRegistrationsView />,
        exact: true,
      });
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_MANAGE_RECURRING_EVENTS,
        element: <ManageRecuringEventView />,
        exact: true,
      });
    }
    if (
      SHOW_CONTENT_TRANSMISSION_ORGANS &&
      hasOperationAccess(accessData.role, 'transmission_organs', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_TRANSMISSION_ORGANS,
        element: <ContentTransmissionOrgansView />,
        exact: true,
      });
    }
    if (
      SHOW_CONTENT_TRANSMISSION_PROGRAM_REQUEST &&
      hasOperationAccess(accessData.role, 'tao_soul_program_requests', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_TRANSMISSION_PROGRAM_REQUEST,
        element: <ContentTransmissionProgramRequestView />,
        exact: true,
      });
    }

    if (
      SHOW_CONTENT_POPULAR &&
      hasOperationAccess(accessData.role, 'popular_content', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_POPULAR,
        element: <PopularContentView />,
        exact: true,
      });
    }
    if (
      SHOW_CONTENT_RESOURCES &&
      hasOperationAccess(accessData.role, 'resources', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_RESOURCES,
        element: <ResourcesContentView />,
        exact: true,
      });
    }
    if (
      SHOW_CONTENT_COMMUNITY_GATHERINGS &&
      hasOperationAccess(accessData.role, 'community_gatherings_zoom', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_COMMUNITY_GATHERINGS,
        element: <ContentCommunityGatheringView />,
        exact: true,
      });
    }
    if (SHOW_RECORD_MESSAGE) {
      if (
        hasOperationAccess(accessData.role, 'ms_voice_recorded_videos', 'view')
      ) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_RECORDED_VIDEOS,
          element: <RecordedMessageView type="VIDEO" />,
          exact: true,
        });
      }
      if (
        hasOperationAccess(accessData.role, 'ms_voice_recorded_audios', 'view')
      ) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_RECORDED_AUDIOS,
          element: <RecordedMessageView type="AUDIO" />,
          exact: true,
        });
      }
    }
    if (SHOW_SECTION_LINKS) {
      if (
        hasOperationAccess(accessData.role, 'ms_voice_link_sections', 'view')
      ) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_LINK_SECTIONS,
          element: <LinkSectionsView />,
          exact: true,
        });
      }
      if (
        hasOperationAccess(
          accessData.role,
          'ms_voice_link_section_items',
          'view'
        )
      ) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_LINK_SECTION_ITEMS,
          element: <LinkSectionItemsView />,
          exact: true,
        });
      }
    }
    if (SHOW_CONTENT_SEQUENCE) {
      if (hasOperationAccess(accessData.role, 'sequence_courses', 'view')) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_SEQUENCE_COURSE,
          element: <ContentSequenceView type={CATEGORY_TYPE[0].id} />,
          exact: true,
        });
      }
      if (hasOperationAccess(accessData.role, 'sequence_programs', 'view')) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_SEQUENCE_PROGRAM,
          element: <ContentSequenceView type={CATEGORY_TYPE[1].id} />,
          exact: true,
        });
      }
      if (
        hasOperationAccess(
          accessData.role,
          'sequence_tao_calligraphy_meditations',
          'view'
        )
      ) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_SEQUENCE_TAO_SONG,
          element: <VideoSequenceView type={VIDEO_TYPES.TAO_SONG.value} />,
          exact: true,
        });
      }
      if (hasOperationAccess(accessData.role, 'sequence_blessings', 'view')) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_SEQUENCE_BLESSING,
          element: <VideoSequenceView type={VIDEO_TYPES.BLESSINGS.value} />,
          exact: true,
        });
      }
      if (
        hasOperationAccess(
          accessData.role,
          'sequence_love_peace_harmonys',
          'view'
        )
      ) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_SEQUENCE_MEDITATION,
          element: <VideoSequenceView type={VIDEO_TYPES.MEDITATION.value} />,
          exact: true,
        });
      }
      if (
        hasOperationAccess(accessData.role, 'sequence_article_contents', 'view')
      ) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_SEQUENCE_ARTICLE_CONTENT,
          element: (
            <ContentSequenceView type={CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL} />
          ),
          exact: true,
        });
      }
      if (
        hasOperationAccess(accessData.role, 'sequence_link_section', 'view')
      ) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_SEQUENCE_LINK_SECTION,
          element: <ContentSequenceView type={CONTENT_ALIAS.LINK_SECTION} />,
          exact: true,
        });
      }
    }

    if (SHOW_ARTICLE) {
      if (hasOperationAccess(accessData.role, 'article_contents', 'view')) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_ARTICLE_CONTENTS,
          element: <ArticleContent />,
          exact: true,
        });
      }
      if (hasOperationAccess(accessData.role, 'article_categories', 'view')) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_ARTICLE_CATEGORIES,
          element: <ArticleCategories />,
          exact: true,
        });
      }
    }

    if (SHOW_TESTIMONIALS) {
      if (hasOperationAccess(accessData.role, 'testimonials_tabs', 'view')) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_TESTIMONIALS_TABS,
          element: <ContentTestimonialTabsView />,
          exact: true,
        });
      }
      if (
        hasOperationAccess(accessData.role, 'testimonials_categories', 'view')
      ) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_TESTIMONIALS_CATEGORIES,
          element: <ContentTestimonialCategoriesView />,
          exact: true,
        });
      }
      if (
        hasOperationAccess(
          accessData.role,
          'testimonials_text_testimonials',
          'view'
        )
      ) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_TESTIMONIALS_TEXT,
          element: <ContentTestimonialTextView />,
          exact: true,
        });
      }
      if (
        hasOperationAccess(
          accessData.role,
          'testimonials_user_text_testimonials',
          'view'
        )
      ) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_USER_TESTIMONIALS_TEXT,
          element: <ContentUserTestimonialTextView />,
          exact: true,
        });
      }
      if (
        hasOperationAccess(
          accessData.role,
          'testimonials_user_video_testimonials',
          'view'
        )
      ) {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_USER_TESTIMONIALS_VIDEO,
          element: <ContentUserTestimonialVideoView />,
          exact: true,
        });
      }
    }

    if (
      SHOW_VIDEO_COMMENTS &&
      hasOperationAccess(accessData.role, 'comments', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEO_COMMENTS,
        element: <ContentVideoCommentsView />,
        exact: true,
      });

      routeMappings.push({
        path: `${ROUTE_PATH.CONTENT_VIDEO_COMMENTS}/:videoID`,
        element: <ContentVideoCommentsView />,
        exact: true,
      });
    }

    if (
      SHOW_NOTIFICATIONS &&
      hasOperationAccess(accessData.role, 'notifications', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.NOTIFICATIONS,
        element: <NotificationsView />,
        exact: true,
      });
    }

    if (SHOW_SALUTATION) {
      routeMappings.push({
        path: ROUTE_PATH.SALUTATION,
        element: <SalutationView />,
        exact: true,
      });
    }

    if (
      SHOW_REPORTS_OVERVIEW_VIDEOS &&
      hasOperationAccess(accessData.role, 'video_watch_overview', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_OVERVIEW_VIDEOS,
        element: <ReportsOverviewView />,
        exact: true,
      });
    }

    if (
      SHOW_REPORTS_LIVE_USERS &&
      hasOperationAccess(accessData.role, 'live_users', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_LIVE_USERS,
        element: <ReportsLiveUsersView />,
        exact: true,
      });
    }

    if (
      SHOW_REPORTS_WATCH_SESSIONS &&
      hasOperationAccess(accessData.role, 'video_watch_sessions', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_SESSIONS,
        element: <ReportsSessionsView />,
        exact: true,
      });
    }

    if (
      SHOW_REPORTS_SUBSCRIPTIONS &&
      hasOperationAccess(accessData.role, 'subscriptions_summary', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_SUBSCRIPTIONS,
        element: <ReportsSubscriptionsView />,
        exact: true,
      });
    }
    if (
      SHOW_REPORTS_LIVE_EVENT &&
      hasOperationAccess(accessData.role, 'live_event_report', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_LIVE_EVENT,
        element: <ReportsLiveEventView />,
        exact: true,
      });
    }
    if (
      SHOW_REPORTS_PROGRAMS &&
      hasOperationAccess(accessData.role, 'purchase_report', 'view')
    ) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_PROGRAMS,
        element: <ReportsProgramView />,
        exact: true,
      });
    }
    if (SHOW_COURSE_REPORT) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_COURSE,
        element: <CourseReportView />,
        exact: true,
      });
    }
    routeMappings.push({
      path: ROUTE_PATH.LOGOUT,
      element: <LogoutView />,
      exact: true,
    });
  }

  routeMappings.push({
    path: '*',
    element: isLoggedIn ? <PageNotFoundView /> : <LoginView />,
  });

  const routes = useRoutes(routeMappings);

  return routes;
};

AppRouter.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  accessRole: PropTypes.string.isRequired,
};

export default AppRouter;
