import CELL_DATA_TYPES from '../cell-types.config';

const LISTING_COLUMNS = [
  {
    id: 'thumbnail',
    label: 'Image Preview',
    width: '10%',
    align: 'center',
    dataKey: 'thumbnail',
    sortable: false,
    type: CELL_DATA_TYPES.IMAGE,
  },
  {
    id: 'eventName',
    label: 'Event Name',
    width: '30%',
    align: 'left',
    dataKey: 'eventName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'isRecurring',
    label: 'Is Recurring',
    width: '15%',
    sortable: true,
    dataKey: 'isRecurring',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'totalRegistrations',
    label: 'Total Registrations',
    width: '15%',
    align: 'left',
    dataKey: 'totalRegistrations',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'totalJoined',
    label: 'Total Joined',
    width: '15%',
    align: 'left',
    dataKey: 'totalJoined',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'eventDate',
    label: 'Event Date',
    width: '15%',
    align: 'left',
    dataKey: 'eventDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export default LISTING_COLUMNS;
