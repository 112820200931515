import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import AudioPlayer from './audio-player.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AudioPreview = ({ audioPlayer, setAudioPlayer }) => (
  <Dialog
    open={audioPlayer.show}
    onClose={() => setAudioPlayer({ ...audioPlayer, show: false })}
    TransitionComponent={Transition}
    maxWidth="xs"
    fullWidth
    sx={{
      '& .MuiDialog-paper': {
        borderRadius: 3,
        padding: 2,
      },
    }}
  >
    <DialogTitle
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="h6">{`Listening - ${audioPlayer.title}`}</Typography>
      <IconButton
        onClick={() => setAudioPlayer({ ...audioPlayer, show: false })}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>
    </DialogTitle>

    <DialogContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
      }}
    >
      <AudioPlayer audioUrl={audioPlayer.videoUrl} autoplay />
    </DialogContent>
  </Dialog>
);

AudioPreview.propTypes = {
  audioPlayer: PropTypes.object.isRequired,
  setAudioPlayer: PropTypes.func.isRequired,
};

export default AudioPreview;
