import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, STATUS_LABELS } from '../const.config';

// Transmission Program Request module configurations
export const LISTING_COLUMNS = [
  {
    id: 'firstName',
    label: 'First Name',
    width: '15%',
    align: 'center',
    dataKey: 'firstName',
    sortable: true,
    type: CELL_DATA_TYPES.REQUEST,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    width: '15%',
    align: 'center',
    dataKey: 'lastName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    width: '20%',
    align: 'center',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'contact',
    label: 'Phone Number',
    width: '20%',
    align: 'center',
    dataKey: 'contact',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'element',
    label: 'Element',
    width: '20%',
    align: 'center',
    dataKey: 'element',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'transmissionOrgan',
    label: 'Transmission Organ',
    width: '15%',
    align: 'center',
    dataKey: 'transmissionOrgan',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'center',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'status',
    label: 'Status',
    width: '15%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
    conditional: (val) =>
      STATUS_LABELS[val.status] === STATUS_LABELS[15] ||
      STATUS_LABELS[val.status] === STATUS_LABELS[16] ||
      STATUS_LABELS[val.status] === STATUS_LABELS[17],
  },
  {
    action: ACTIONS.APPROVAL.value,
    label: ACTIONS.VIEW.label,
    conditional: (val) => STATUS_LABELS[val.status] === STATUS_LABELS[14],
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'primaryUser',
    label: 'Primary User',
    dataKey: 'Primary User',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'requestedUser',
    label: 'Requested User',
    dataKey: 'Requested User',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    conditional: (val) => val.requestedForId !== 0,
  },
  {
    id: 'email',
    label: 'Email',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'contact',
    label: 'Phone Number',
    dataKey: 'contact',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'element',
    label: 'Element',
    dataKey: 'element',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'transmissionOrgan',
    label: 'Transmission Organ',
    dataKey: 'transmissionOrgan',
    type: CELL_DATA_TYPES.TEXT,
  },

  {
    id: 'referralSource',
    label: 'Referral Source',
    dataKey: 'referralSource',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'newUserFlag',
    label: 'New to Master Sha Tao Technologies',
    dataKey: 'newUserFlag',
    sortable: true,
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
];
