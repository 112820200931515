import React, { useState, useEffect, useRef, useContext } from 'react';
import { Card, Stack, Button } from '@mui/material';
import { ACTIONS } from '../../config/const.config';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/subscribers.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import ConfirmPopup from '../common/confirm-popup.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ViewSubscriber from './view-subscriber.component';
import AddEditSubscriber from './add-edit-subscriber.component';
import ManageSubscriptions from './manage-subscriptions.component';
import ChangePassword from './change-password.component';
import {
  getListData,
  deleteDataById,
  autoLoginSubscriber,
} from '../../services/subscribers.service';
import SearchList from '../table-elements/search-list.component';
import { getDeviceModel, getDeviceOs } from '../../utils/device-info.util';
import ManageNotes from './manage-notes.component';
import useFilteredActions from '../../hooks/filtered-action.hook';
import { AccessContext } from '../../context/access.context';
import useAccess from '../../hooks/access.hook';

const ListSubscribers = () => {
  const { accessData } = useContext(AccessContext);
  const { hasOperationAccess } = useAccess();
  const columns = LISTING_COLUMNS;
  const actions = useFilteredActions(
    accessData.role,
    'subscribers',
    LISTING_ACTIONS
  );

  const searchSubscribersRef = useRef(null);
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
    subRowsLoading: false,
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };
  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };
  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleShowSubProfileList = (rowData) => {
    if (!rowData?.subRows || (rowData?.subRows && !rowData.subRows.length)) {
      setOptions({
        ...options,
        rows: options.rows.map((item) =>
          item.id === rowData.id
            ? { ...item, subRowsLoading: true, subRows: [] }
            : item
        ),
      });

      const params = `?primaryProfileId=${rowData.id}`;
      getListData(params)
        .then((res) => {
          setOptions({
            ...options,
            rows: options.rows.map((item) =>
              item.id === rowData.id
                ? { ...item, subRowsLoading: false, subRows: res?.data?.rows }
                : item
            ),
          });
        })
        .catch(() => {
          // Skipped
        });
    } else {
      setOptions({
        ...options,
        rows: options.rows.map((item) =>
          item.id === rowData.id
            ? { ...item, subRowsLoading: false, subRows: [] }
            : item
        ),
      });
    }
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteDataById(doAction.data.id)
      .then(() => {
        handleActionSuccess('Subscriber deleted successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleLoginAsSubscriber = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const params = {
      os: getDeviceOs(),
      model: getDeviceModel(),
    };
    autoLoginSubscriber(doAction.data.id, params)
      .then((res) => {
        if (res?.data) {
          const url = `${
            process.env.REACT_APP_WEBSITE_ENDPOINT
          }/auto-login?auth=${res?.data.token || ''}`;
          window.open(url, '_blank');
        } else {
          const message =
            res?.message || 'Something went wrong, please try again.';

          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
        }
        handleActionCancel();
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleSearchChange = (e) => {
    setOptions({
      ...options,
      searchValue: e.target.value || '',
    });
  };

  const handleSearchSubscribers = () => {
    if (options.loading) return;
    setOptions({
      ...options,
      q: searchSubscribersRef.current.value,
      reloadCounter: options.reloadCounter + 1,
      page: 0,
      loading: true,
    });
  };

  const handleClearSearch = () => {
    if (options.loading) return;
    setOptions({
      ...options,
      searchValue: '',
      q: null,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
    searchSubscribersRef.current.value = '';
  };

  useEffect(() => {
    let params = `?page=${options.page + 1}`;
    params += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      params += `&sortBy=${options.sortBy}`;
      params += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      params += `&q=${options.q}`;
    }

    getListData(params)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>
          {hasOperationAccess(accessData.role, 'subscribers', 'create') && (
            <Button
              color="primary"
              variant="contained"
              startIcon={<Iconify icon="ic:round-add" />}
              onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
            >
              Create New
            </Button>
          )}

          <SearchList
            label="Subscribers"
            handleSearch={handleSearchSubscribers}
            inputRef={searchSubscribersRef}
            onChange={handleSearchChange}
            options={options}
            clearSearch={handleClearSearch}
            loading={options.loading}
          />
        </div>
        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
          subRowsLoading={options.subRowsLoading}
          handleShowSubList={handleShowSubProfileList}
        />
      </Card>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditSubscriber
          title="Create New Subscriber"
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditSubscriber
          title="Edit Subscriber Details - "
          user={doAction.data}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewSubscriber
          title="Subscriber Details - "
          user={doAction.data}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title="Delete Subscriber"
          message={`Do you want to delete '${doAction.data.email}' subcriber? You can not undo this action!`}
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {doAction.action === ACTIONS.MANAGE_SUBSCRIPTIONS.value && (
        <ManageSubscriptions
          title="Manage Subscription - "
          user={doAction.data}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.MANAGE_NOTES.value && (
        <ManageNotes
          title={`Manage Subscriber Notes - ${doAction.data.email}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          isSubscription={false}
        />
      )}

      {doAction.action === ACTIONS.CHANGE_PASSWORD.value && (
        <ChangePassword
          title="Change Password For Subscriber - "
          user={doAction.data}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.LOGIN_AS_SUBSCRIBER.value && (
        <ConfirmPopup
          title={`Login As Subscriber - ${doAction.data.email}`}
          message="Are you sure you want to login as subscriber? You will be redirect to the website."
          onCancel={handleActionCancel}
          onSuccess={handleLoginAsSubscriber}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListSubscribers;
