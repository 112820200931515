import { ACTIONS, LANGUAGES } from '../config/const.config';
import useAccess from './access.hook';

const useFilteredActions = (role, module, actions) => {
  const { hasOperationAccess } = useAccess();

  return actions.filter((action) => {
    if (action.action === ACTIONS.VIEW.value) {
      return hasOperationAccess(role, module, 'view');
    }
    if (action.action === ACTIONS.CREATE.value) {
      hasOperationAccess(role, module, 'create');
    }
    if (action.action === ACTIONS.EDIT.value) {
      return hasOperationAccess(role, module, 'edit');
    }
    if (action.action === ACTIONS.TRANSLATE.value) {
      return hasOperationAccess(role, module, 'translate');
    }
    if (action.action === ACTIONS.DELETE.value) {
      return hasOperationAccess(role, module, 'delete');
    }
    if (action.action === ACTIONS.CHANGE_PASSWORD.value) {
      return hasOperationAccess(role, module, 'change_password');
    }
    if (action.action === ACTIONS.UPLOAD_ASSETS.value) {
      return hasOperationAccess(role, module, 'upload_assets');
    }
    if (action.action === ACTIONS.MANAGE_SUBSCRIPTIONS.value) {
      return hasOperationAccess(role, module, 'manage_subscriptions');
    }
    if (action.action === ACTIONS.GRACE_SUBSCRIPTION.value) {
      return hasOperationAccess(role, module, 'grace_subscription');
    }
    if (action.action === ACTIONS.VIEW_VIDEO_COMMENTS.value) {
      return hasOperationAccess(role, module, 'view_comments');
    }
    if (action.action === ACTIONS.UPLOAD_POSTER.value) {
      return hasOperationAccess(role, module, 'upload_poster');
    }
    if (action.action === ACTIONS.UPLOAD_VIDEO.value) {
      return hasOperationAccess(role, module, 'upload_video');
    }
    if (action.action === ACTIONS.APPROVAL.value) {
      return hasOperationAccess(role, module, 'view');
    }
    if (action.action === ACTIONS.MANAGE_MEMBERS.value) {
      return hasOperationAccess(role, 'manage_members', 'view');
    }
    if (action.action === ACTIONS.DOWNLOAD.value) {
      return hasOperationAccess(role, module, 'download');
    }
    if (action.action === ACTIONS.VIEW_TRANSACTION.value) {
      return hasOperationAccess(role, module, 'view_transaction');
    }
    if (action.action === ACTIONS.LOGIN_AS_SUBSCRIBER.value) {
      return hasOperationAccess(role, module, 'login_as_subscriber');
    }
    if (action.action === ACTIONS.VIEW_REGISTRATIONS.value) {
      return hasOperationAccess(role, module, 'view_registrations');
    }
    if (action.action === ACTIONS.MANAGE_RECURRING_EVENTS.value) {
      return hasOperationAccess(role, module, 'manage_recurring_events');
    }
    if (action.action === ACTIONS.MANAGE_NOTES.value) {
      return hasOperationAccess(role, module, 'manage_notes');
    }
    if (action.action === ACTIONS.MANAGE_TEACHERS.value) {
      return hasOperationAccess(role, module, 'manage_teachers');
    }
    if (action.action === LANGUAGES[0].value) {
      return hasOperationAccess(role, module, 'translate');
    }
    if (action.action === LANGUAGES[1].value) {
      return hasOperationAccess(role, module, 'translate');
    }
    if (action.action === LANGUAGES[2].value) {
      return hasOperationAccess(role, module, 'translate');
    }
    if (action.action === LANGUAGES[3].value) {
      return hasOperationAccess(role, module, 'translate');
    }
    if (action.action === LANGUAGES[4].value) {
      return hasOperationAccess(role, module, 'translate');
    }
    if (action.action === LANGUAGES[5].value) {
      return hasOperationAccess(role, module, 'translate');
    }
    if (action.action === LANGUAGES[6].value) {
      return hasOperationAccess(role, module, 'translate');
    }
    if (action.action === LANGUAGES[7].value) {
      return hasOperationAccess(role, module, 'translate');
    }

    return true;
  });
};

export default useFilteredActions;
