import React, { useEffect, useState, useRef, forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import JoditEditor from 'jodit-react';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewArticleContent,
  updateArticleContentById,
  viewArticleContentById,
} from '../../services/content.service';
import Status from '../form-elements/status.component';
import { CONTENT_ALIAS } from '../../config/const.config';
import ArticleType from '../form-elements/article-type.component';
import ChildArticleCategory from '../form-elements/child-article-category.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditArticleContent = ({ title, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      banner: '',
      status: null,
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const options = [
    'bold',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'font',
    'fontsize',
    '|',
    'outdent',
    'indent',
    'align',
    '|',
    'hr',
    '|',
    'fullsize',
    'brush',
    '|',
    'table',
    'link',
    'image',
    '|',
    'undo',
    'redo',
    '|',
    'source',
  ];
  const config = useMemo(
    () => ({
      defaultMode: 1,
      readonly: false,
      tabIndex: -1,
      toolbar: true,
      placeholder: 'Start typing...',
      defaultActionOnPaste: 'insert_as_html',
      defaultLineHeight: 1,
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      minHeight: 350,
      useNativeTooltip: false,
    }),
    []
  );

  const saveNewData = (payload) => {
    createNewArticleContent(payload)
      .then(() => {
        onSuccess(`New ${CONTENT_ALIAS.ARTICLE} added successfully.`);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateArticleContentById(dataId, payload)
      .then(() => {
        onSuccess(`${CONTENT_ALIAS.ARTICLE} details updated successfully.`);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      title: data.title,
      articleType: data.articleType,
      articleCategoryId: data.articleCategoryId,
      description: data?.description || '',
      content: content || '',
      banner: data?.banner || '',
      status: data.status,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }
    viewArticleContentById(dataId)
      .then((res) => {
        // Fill form values
        setValue('title', res.data?.title || '');
        setValue('articleType', res.data?.articleType || '');
        setValue('articleCategoryId', res.data?.articleCategoryId || '');
        setValue('description', res.data?.description || '');
        setValue('content', res.data?.content || '');
        setContent(res.data?.content || '');
        setValue('banner', res.data?.banner || '');
        setValue('status', res.data?.status || 0);

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      onClose={() => onCancel()}
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={`${CONTENT_ALIAS.ARTICLE_CONTENT} title`}
                      type="text"
                      error={!!errors.title}
                      helperText={errors?.title?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="articleType"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <ArticleType
                      id="articleType"
                      name="articleType"
                      label="Article Type"
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.articleType?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="articleCategoryId"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <ChildArticleCategory
                      id="articleCategoryId"
                      name="articleCategoryId"
                      label="Article Category"
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.articleCategoryId?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    ...{ ...maxLengthValidation(1500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      type="text"
                      error={!!errors.description}
                      helperText={errors?.description?.message || null}
                      fullWidth
                      multiline
                      rows={2}
                      inputProps={{ maxLength: 1500 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="content"
                  control={control}
                  render={({ field }) => (
                    <JoditEditor
                      {...field}
                      label="Content"
                      ref={editor}
                      config={config}
                      tabIndex={-1}
                      onBlur={(newContent) => setContent(newContent)}
                      onChange={(newContent) => setContent(newContent)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="banner"
                  control={control}
                  rules={{
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Banner URL"
                      type="text"
                      error={!!errors.banner}
                      helperText={
                        errors?.banner?.message ||
                        "You can upload image from 'Upload Poster' option too from list."
                      }
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="sub_status"
                      name="sub_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditArticleContent.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  options: PropTypes.objectOf.isRequired,
};

export default AddEditArticleContent;
