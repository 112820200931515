import { CONTENT_ALIAS } from './const.config';

export const SEPERATOR = '›';

// Define page-title configurations
const PAGE_TITLE_LIST = {
  OVERVIEW_DASHBOARD: 'Overview Dashboard',
  REALTIME_DASHBOARD: 'Live Users (Per Platform)',
  SUBSCRIBERS: 'Subscribers',
  SUBSCRIPTIONS: 'Subscriptions',
  GROUPS: 'Community Groups',
  MANAGE_MEMBERS: 'Manage Members',
  PACKAGES: 'Packages',
  ADMIN_USERS: 'Users',
  CONTENT_SECTION: `Content ${SEPERATOR} ${CONTENT_ALIAS.SECTION_PLURAL}`,
  CONTENT_CATEGORY: `Content ${SEPERATOR} ${CONTENT_ALIAS.CATEGORY_PLURAL}`,
  CONTENT_VIDEOS: `Content ${SEPERATOR} ${CONTENT_ALIAS.VIDEO_PLURAL}`,
  CONTENT_ADS: `Content ${SEPERATOR} Ads`,
  CONTENT_SEQUENCE: `Content ${SEPERATOR} Sequence`,
  CONTENT_SEQUENCE_VIDEOS: `Content ${SEPERATOR} Sequence ${CONTENT_ALIAS.VIDEO_PLURAL}`,
  CONTENT_COMMUNITY_GATHERINGS: `Content ${SEPERATOR} Community Gatherings (Zoom meetings)`,
  CONTENT_SEQUENCE_TESTIMONIAL_TABS: `Content ${SEPERATOR} Sequence Testimonial Tabs`,
  CONTENT_SEQUENCE_TESTIMONIAL_CATEGORIES: `Content ${SEPERATOR} Sequence Testimonial Categories`,
  CONTENT_TABS: `Content ${SEPERATOR} Tabs`,
  CONTENT_TESTIMONIALS: `Content ${SEPERATOR} Testimonials`,
  CONTENT_TESTIMONIALS_TABS: `Content ${SEPERATOR} Testimonial Tabs`,
  CONTENT_TESTIMONIALS_CATEGORIES: `Content ${SEPERATOR} Testimonial Categories`,
  CONTENT_TESTIMONIALS_TEXT: `Content ${SEPERATOR} Text Testimonials`,
  CONTENT_USER_TESTIMONIALS_TEXT: `Content ${SEPERATOR} User Text Testimonials`,
  CONTENT_USER_TESTIMONIALS_VIDEO: `Content ${SEPERATOR} User Video Testimonials`,
  CONTENT_VIDEO_COMMENTS: `Content ${SEPERATOR} ${CONTENT_ALIAS.VIDEO_SINGULAR} Comments`,
  CONTENT_ARTICLE: `Content ${SEPERATOR} Article`,
  CONTENT_ARTICLE_CATEGORIES: `Content ${SEPERATOR} Article Categories`,
  CONTENT_ARTICLE_CONTENT: `Content ${SEPERATOR} Article Content`,
  CONTENT_SEQUENCE_COURSE: `Content ${SEPERATOR} Sequence ${CONTENT_ALIAS.COURSES}`,
  CONTENT_SEQUENCE_PROGRAM: `Content ${SEPERATOR} Sequence ${CONTENT_ALIAS.PROGRAMS_PLURAL}`,
  CONTENT_SEQUENCE_TAOSONG: `Content ${SEPERATOR} Sequence ${CONTENT_ALIAS.TAOSONG_PLURAL}`,
  CONTENT_SEQUENCE_MEDITATION: `Content ${SEPERATOR} Sequence ${CONTENT_ALIAS.MEDITATION_PLURAL}`,
  CONTENT_SEQUENCE_BLESSING: `Content ${SEPERATOR} Sequence ${CONTENT_ALIAS.BLESSING_PLURAL}`,
  CONTENT_SEQUENCE_ARTICLE_CONTENT: `Content ${SEPERATOR} Sequence ${CONTENT_ALIAS.ARTICLE_CONTENT_PLURAL}`,
  CONTENT_SEQUENCE_LINK_SECTION_CONTENT: `Content ${SEPERATOR} Sequence ${CONTENT_ALIAS.LINK_SECTION}`,
  NOTIFICATIONS: 'Notifications',
  SALUTATION: 'Salutation',
  REPORTS_OVERVIEW_VIDEOS: `Analytics Reports ${SEPERATOR} Video Watch Overview`,
  REPORTS_LIVE_USERS: `Analytics Reports ${SEPERATOR} Live Users`,
  REPORTS_SESSIONS: `Analytics Reports ${SEPERATOR} Watch Sessions`,
  REPORTS_SUBSCRIPTIONS: `Analytics Reports ${SEPERATOR} Subscriptions Summary`,
  REPORTS_LIVE_EVENT: `Analytics Reports ${SEPERATOR} Live Event`,
  REPORTS_PROGRAM: `Analytics Reports ${SEPERATOR} Purchase Report`,
  REPORTS_COURSE: `Analytics Reports ${SEPERATOR} Course Registration Report`,
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  ERROR_NOT_FOUND: '404 Not Found',
  POPULAR_CONTENT: `Content ${SEPERATOR} ${CONTENT_ALIAS.POPULAR_CONTENT_SINGULAR}`,
  EVENT_REGISTRATIONS: 'Event Registrations',
  MANAGE_RECURRING_EVENTS: `Manage Recurring Events`,
  CONTENT_TRANSMISSION_ORGANS: `Content ${SEPERATOR} Transmission Organs`,
  SHOW_CONTENT_TRANSMISSION_PROGRAM_REQUEST: `Content ${SEPERATOR} Tao Soul Program Requests`,
  CONTENT_RESOURCES_CONTENT: `Content ${SEPERATOR} Resources Content`,
  CONTENT_RECORDED_MESSAGE: `Content ${SEPERATOR} Recorded`,
  CONTENT_LINK_SECTIONS: `Content ${SEPERATOR} Link Sections`,
  CONTENT_LINK_SECTION_ITEMS: `Content ${SEPERATOR} Link Section Items`,
};

export default PAGE_TITLE_LIST;
