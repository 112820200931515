/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { Stack, Card, CardHeader, Box, IconButton } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { Controller, useForm } from 'react-hook-form';
import { getDate, formatDate } from '../../utils/datetime.util';
import { getCourseRegisterReportData } from '../../services/reports.service';
import Datepicker from '../form-elements/datepicker.component';
import Iconify from '../common/iconify.component';
import NoData from '../table-elements/no-data.component';
import ListData from '../table-elements/list-data.component';
import LISTING_COLUMNS from '../../config/module-configs/course-report-data.config';
import Categories from '../form-elements/categories.component';
import { CATEGORY_TYPE, CONTENT_ALIAS } from '../../config/const.config';

const CourseRegistrationReportChart = () => {
  const getInitialDates = () => ({
    startDate: getDate(-7),
    endDate: getDate(0),
  });
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    unfilterRows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });

  const [filters, setFilters] = useState({
    ...getInitialDates(),
    reloadCounter: 0,
  });
  const columns = LISTING_COLUMNS;
  const actions = [];

  const {
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      categoryId: null,
    },
  });
  const courseWatch = watch('categoryId');

  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 5,
          dataLabels: {
            total: {
              enabled: true,
              style: { fontSize: '13px', fontWeight: 900 },
            },
          },
        },
      },
      xaxis: { type: 'category', categories: [] },
      legend: { position: 'right', offsetY: 40 },
      fill: { opacity: 0.8 },
      tooltip: { y: { formatter: (val) => `${val} Registrations` } },
    },
  });
  const handleRefreshData = () => {
    setFilters({
      ...filters,
      reloadCounter: filters.reloadCounter + 1,
    });
    setOptions((prevOptions) => ({
      ...prevOptions,
      loading: true,
    }));
  };
  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    if (!sortBy || !sortOrder) {
      setOptions((prev) => ({
        ...prev,
        rows: options.unfilterRows,
        sortBy,
        sortOrder,
      }));
      return;
    }

    const order = sortOrder || 'asc';

    const { page, rowsPerPage, rows } = options;
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const currentPageRows = rows.slice(startIndex, endIndex);

    const sortedPageRows = [...currentPageRows].sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return order === 'asc' ? bValue - aValue : aValue - bValue;
      }

      if (!isNaN(Date.parse(aValue)) && !isNaN(Date.parse(bValue))) {
        return order === 'asc'
          ? new Date(aValue) - new Date(bValue)
          : new Date(bValue) - new Date(aValue);
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return order === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return 0;
    });

    const updatedRows = [...rows];

    updatedRows.splice(startIndex, sortedPageRows.length, ...sortedPageRows);

    setOptions((prev) => ({
      ...prev,
      rows: updatedRows,
      sortBy,
      sortOrder,
    }));
  };

  useEffect(() => {
    setFilters({
      ...filters,
      reloadCounter: filters.reloadCounter + 1,
    });
  }, [courseWatch]);

  useEffect(() => {
    const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
    const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');

    getCourseRegisterReportData({ startDate, endDate, categoryId: courseWatch })
      .then((res) => {
        if (res) {
          setOptions((prev) => ({
            ...prev,
            loading: false,
            totalRows: res?.detailData?.totalRows || 0,
            rows: res?.detailData?.rows || [],
            unfilterRows: res?.detailData?.rows || [],
            error: false,
          }));
        }

        let uniqueDates = [];
        let uniqueCourses = [];
        let seriesData = [];
        if (res.data.rows.length > 0) {
          uniqueDates = [
            ...new Set(
              res.data.rows.map((row) =>
                new Date(row.registrationDate).toLocaleDateString('en-GB')
              )
            ),
          ];

          uniqueCourses = [
            ...new Set(res.data.rows.map((row) => row.categoryName)),
          ];

          seriesData = uniqueCourses.map((course) => ({
            name: course,
            data: uniqueDates.map((date) => {
              const record = res.data.rows.find(
                (row) =>
                  new Date(row.registrationDate).toLocaleDateString('en-GB') ===
                    date && row.categoryName === course
              );
              return record ? record.totalRegistrations : 0;
            }),
          }));
        }

        const categories = uniqueDates;

        setChartData((prev) => ({
          ...prev,
          series: seriesData,
          options: { ...prev.options, xaxis: { categories } },
        }));
      })
      .catch(() => setChartData((prev) => ({ ...prev, series: [] })));
  }, [filters.reloadCounter]);

  return (
    <Card>
      <CardHeader
        title="Course Registration Report"
        action={
          <IconButton title="Refresh" onClick={handleRefreshData}>
            <Iconify icon="ic:twotone-refresh" />
          </IconButton>
        }
      />

      <Box sx={{ p: 3, pb: 1 }}>
        <Stack direction="row" justifyContent="right" spacing={2}>
          <Datepicker
            id="start_date"
            label="Start Date"
            defaultValue={filters.startDate}
            onChange={(v) =>
              setFilters({
                ...filters,
                startDate: v,
                reloadCounter: filters.reloadCounter + 1,
              })
            }
            sx={{ width: 200 }}
          />
          <Datepicker
            id="end_date"
            label="End Date"
            defaultValue={filters.endDate}
            onChange={(v) =>
              setFilters({
                ...filters,
                endDate: v,
                reloadCounter: filters.reloadCounter + 1,
              })
            }
            sx={{ width: 200 }}
          />
        </Stack>

        <Stack direction="row" justifyContent="right" spacing={2} mt={2}>
          <Box sx={{ width: 300 }}>
            <Controller
              name="categoryId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Categories
                  id="categoryId"
                  name="categoryId"
                  label={`Select ${CONTENT_ALIAS.COURSES_SINGULAR}`}
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.categoryId?.message || ''}
                  options={options}
                  categoryType={CATEGORY_TYPE[0].id}
                  showAll
                />
              )}
            />
          </Box>
        </Stack>

        {chartData.series.length ? (
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={350}
          />
        ) : (
          <NoData />
        )}
      </Box>

      <Card sx={{ marginTop: 3 }}>
        {!options.loading && (
          <ListData
            columns={columns}
            rows={
              options.rows.slice(
                options.page * options.rowsPerPage,
                (options.page + 1) * options.rowsPerPage
              ) || []
            }
            page={options.page}
            rowsPerPage={options.rowsPerPage}
            totalRows={options.totalRows}
            loading={options.loading}
            actions={actions}
            error={options.error}
            sortBy={options.sortBy}
            sortOrder={options.sortOrder}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSortChange={handleSortingChange}
          />
        )}
      </Card>
    </Card>
  );
};

export default CourseRegistrationReportChart;
