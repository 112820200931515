import React, { useContext, useState } from 'react';
import { Button, Stack } from '@mui/material';
import TranslateNotifications from './translate-notification.component';
import AddEditNotification from '../content/add-edit-notification.component';
import SnackbarInfo from '../common/snackbar-info.component';
import { ACTIONS } from '../../config/const.config';
import useAccess from '../../hooks/access.hook';
import { AccessContext } from '../../context/access.context';

const Notifications = () => {
  const { hasOperationAccess } = useAccess();
  const { accessData } = useContext(AccessContext);
  const [options, setOptions] = useState({
    reloadCounter: 0,
    error: false,
    loading: true,
  });
  const [dataId, setDataId] = useState(0);
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };

  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });

    setTimeout(() => {
      setSnackbarInfo({
        ...snackbarInfo,
        show: false,
        type: '',
        message: '',
      });
    }, 5000);

    handleActionCancel();
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleRefreshData();
    handleActionCancel();
  };

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" sx={{ px: 1, py: 2 }}>
        {hasOperationAccess(accessData.role, 'notifications', 'translate') && (
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleActionSelect(ACTIONS.TRANSLATE.value, null)}
            >
              Translate Notifications
            </Button>
          </div>
        )}
      </Stack>

      <AddEditNotification
        dataId={dataId}
        setDataId={setDataId}
        onSuccess={handleActionSuccess}
      />

      {doAction.action === ACTIONS.TRANSLATE.value && (
        <TranslateNotifications
          title="Translate Notification"
          dataId={dataId}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default Notifications;
